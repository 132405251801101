import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlatformPageContainer, PlatformContainer, PlatformCell } from './PlatformPageStyle';
import { BoxTableHeader } from '../../../../components/table/TableStyle';
import { CommonLabel } from '../../../../components/label/CommonLabel';
import { CommonButton } from '../../../../components/button/CommonButton';
import { CommonCheckboxStyle } from '../../../../components/EtcStyle';
import {
  getPlatformVersions,
  deletePlatformVersion,
  deletePlatformVersions,
} from '../../../../api/NoitApi';
import { Version } from '../../../../api/models/ServiceModels';
import {
  SizeEnum,
  ColorEnum,
  PlatformEnum,
  PlatformDisplayName,
} from '../../../../components/common/enums';
import { convertDateFormat } from '../../../../components/common/utils';
import { CheckboxProps } from 'semantic-ui-react';
import { MoreMenu } from '../../../../components/menu/MoreMenu';
import * as ROUTES from '../../../../routes/Routes';
import { VersionPopup } from './VersionPopup';
import { useDialog } from '../../../../contexts/DialogContext';
import { useService } from '../../../../contexts/ServiceContext';
import { useAuthorization } from '../../../../hooks/useAuthorization';

export const PlatformPage: React.FC = () => {
  let { service } = useService();
  const addAuth = useAuthorization('VERSION_ADD', true);
  const deleteAuth = useAuthorization('VERSION_DELETE', true);
  const { openDialog, closeDialog, openAuthDialog } = useDialog();
  const [platformVersions, setPlatformVersions] = useState<
    { platformName: string; versions: Version[] }[]
  >([]);
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const cellRef = useRef(null);
  const [smallWidth, setSmallWidth] = useState(false);

  const checkWidth = () => {
    if (cellRef.current) {
      setSmallWidth((cellRef.current as HTMLElement).offsetWidth < 600);
    }
  };

  useEffect(() => {
    if (!service || !service.platforms) {
      return;
    }

    const fetchPlatformVersions = async () => {
      try {
        if (!service || !service.platforms) {
          return;
        }
        const sortedPlatforms = [...service.platforms].sort();

        const tempPlatformVersions = [];

        for (const platform of sortedPlatforms) {
          const platformVersionsData = await getPlatformVersions(service.id, platform);
          tempPlatformVersions.push({
            platformName: platform,
            versions: platformVersionsData.items,
          });
        }

        setPlatformVersions(tempPlatformVersions);
      } catch (error) {
        console.error('Failed to fetch platform versions', error);
      }
    };

    fetchPlatformVersions();
  }, [service]);

  useEffect(() => {
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  }, [platformVersions]);

  // 플랫폼별로 각 버전의 체크 상태를 관리하기 위한 상태
  const [checkedVersions, setCheckedVersions] = useState<{
    [platform: string]: { [versionId: number]: boolean };
  }>({});

  // 플랫폼별 전체 선택/해제 처리
  const handleSelectAllPlatform = (platform: string, checked: boolean) => {
    setCheckedVersions((prev) => ({
      ...prev,
      [platform]:
        platformVersions
          .find((p) => p.platformName === platform)
          ?.versions?.reduce(
            (acc, version) => {
              acc[version.id] = checked;
              return acc;
            },
            {} as { [id: number]: boolean }
          ) ?? {},
    }));
  };

  // 플랫폼별 개별 선택 처리
  const handleSelectVersion = (platform: string, versionId: number, checked: boolean) => {
    setCheckedVersions((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [versionId]: checked,
      },
    }));
  };

  const handleAddVersionClick = (platformName: string) => {
    if (addAuth) {
      openAuthDialog('버전 추가', addAuth);
      return;
    }
    setSelectedPlatform(platformName);
    setIsPopupOpen(true);
  };

  const navigate = useNavigate();
  const goToDetailPage = (versionId: number, title: string) => {
    navigate(ROUTES.PLATFORM_DETAIL(versionId, title));
  };

  const handleDeleteVersion = (platformName: string, versionId: number) => {
    if (!service) {
      return;
    }
    deletePlatformVersion(service.id, versionId)
      .then(() => {
        setPlatformVersions((prev) =>
          prev.map((pv) =>
            pv.platformName === platformName
              ? { ...pv, versions: pv.versions.filter((v) => v.id !== versionId) }
              : pv
          )
        );
        setCheckedVersions((prev) => ({
          ...prev,
          [platformName]: prev[platformName]
            ? {
                ...prev[platformName],
                [versionId]: false,
              }
            : {},
        }));
      })
      .catch((error) => {
        console.error('Error deleting platform version', error);
      });
  };

  const handleDeleteSelectedVersions = (platformName: string) => {
    const selectedVersionIds = Object.entries(checkedVersions[platformName] || {})
      .filter(([_, checked]) => checked)
      .map(([id, _]) => parseInt(id));

    if (selectedVersionIds.length > 0) {
      if (!service) {
        return;
      }
      deletePlatformVersions(service.id, selectedVersionIds)
        .then(() => {
          setPlatformVersions((prev) =>
            prev.map((pv) =>
              pv.platformName === platformName
                ? { ...pv, versions: pv.versions.filter((v) => !selectedVersionIds.includes(v.id)) }
                : pv
            )
          );

          setCheckedVersions((prev) => ({
            ...prev,
            [platformName]: selectedVersionIds.reduce(
              (acc, id) => {
                acc[id] = false;
                return acc;
              },
              { ...prev[platformName] }
            ),
          }));
        })
        .catch((error) => {
          console.error('Error deleting platform versions', error);
        });
    }
  };

  return (
    <>
      <PlatformPageContainer>
        {platformVersions.map((platformVersion) => {
          const platformChecked = checkedVersions[platformVersion.platformName] || {};
          const isAllSelected =
            (platformVersion.versions &&
              platformVersion.versions.length > 0 &&
              platformVersion.versions?.every((version) => platformChecked[version.id])) ??
            false;
          const selectedCount = Object.values(platformChecked).filter(Boolean).length;

          return (
            <PlatformContainer ref={cellRef} key={platformVersion.platformName}>
              <BoxTableHeader>
                <div className='header'>
                  <CommonCheckboxStyle
                    checked={isAllSelected}
                    onChange={(_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
                      handleSelectAllPlatform(platformVersion.platformName, data.checked || false)
                    }
                  />
                  {PlatformDisplayName(platformVersion.platformName)}
                  {selectedCount > 0 && <div className='count'>({selectedCount}개 선택됨)</div>}
                  {selectedCount > 0 && (
                    <CommonButton
                      className='delete'
                      sizeinfo={SizeEnum.XS}
                      colorinfo={ColorEnum.GRAY}
                      content='삭제'
                      onClick={() => {
                        if (deleteAuth) {
                          openAuthDialog('버전 삭제', deleteAuth);
                          return;
                        }
                        openDialog({
                          title: `${PlatformDisplayName(platformVersion.platformName)} 버전 삭제`,
                          content: `선택된 ${selectedCount}개의 버전을 삭제하시겠습니까?`,
                          onConfirm: () => {
                            handleDeleteSelectedVersions(platformVersion.platformName);
                            closeDialog();
                          },
                        });
                      }}
                    />
                  )}
                </div>
                <CommonButton
                  className='add'
                  sizeinfo={SizeEnum.XS}
                  colorinfo={ColorEnum.BORDERGRAY}
                  content='버전 추가'
                  onClick={() => handleAddVersionClick(platformVersion.platformName)}
                />
              </BoxTableHeader>

              {platformVersion.versions && platformVersion.versions.length > 0 ? (
                <div className='box'>
                  {platformVersion.versions.map((version) => (
                    <PlatformCell
                      onClick={() => goToDetailPage(version.id, platformVersion.platformName)}
                      $smallWidth={smallWidth}
                      key={version.id}
                    >
                      <div className='type'>
                        <CommonCheckboxStyle
                          checked={platformChecked?.[version.id] || false}
                          onChange={(
                            event: React.FormEvent<HTMLInputElement>,
                            data: CheckboxProps
                          ) => {
                            event.stopPropagation();
                            handleSelectVersion(
                              platformVersion.platformName,
                              version.id,
                              data.checked || false
                            );
                          }}
                        />
                        {PlatformDisplayName(platformVersion.platformName)}
                        <span className='version'>{version.name}</span>
                      </div>
                      <div className='note'>{version.note}</div>
                      {platformVersion.platformName !== PlatformEnum.WEB && (
                        <CommonLabel
                          sizeinfo={SizeEnum.S}
                          colorinfo={version.forceUpdateFlag ? ColorEnum.ORANGE : ColorEnum.BLUE}
                          children={version.forceUpdateFlag ? '강제 업데이트' : '선택 업데이트'}
                        />
                      )}
                      <div className='more'>
                        <span className='content'>
                          {version.createdAt === version.updatedAt
                            ? convertDateFormat(version.createdAt) + ' 등록'
                            : convertDateFormat(version.updatedAt) + ' 업데이트'}
                        </span>
                        <MoreMenu
                          menuItems={[
                            {
                              label: '삭제',
                              onClick: () => {
                                if (deleteAuth) {
                                  openAuthDialog('버전 삭제', deleteAuth);
                                  return;
                                }
                                openDialog({
                                  title: `${PlatformDisplayName(platformVersion.platformName)} 버전 삭제`,
                                  content: `${version.name} 버전을 삭제하시겠습니까?`,
                                  onConfirm: () => {
                                    handleDeleteVersion(platformVersion.platformName, version.id);
                                    closeDialog();
                                  },
                                });
                              },
                            },
                          ]}
                        />
                      </div>
                    </PlatformCell>
                  ))}{' '}
                </div>
              ) : (
                <div className='empty'>버전을 등록해 주세요.</div>
              )}
            </PlatformContainer>
          );
        })}
      </PlatformPageContainer>
      {selectedPlatform && (
        <VersionPopup
          platform={selectedPlatform}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          onConfirm={(version) => {
            setPlatformVersions(
              platformVersions.map((p) => {
                if (p.platformName === selectedPlatform) {
                  const newVersions = [version, ...p.versions];
                  return { ...p, versions: newVersions };
                }
                return p;
              })
            );
          }}
        />
      )}
    </>
  );
};

export default PlatformPage;
