import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';

export const GnbDropdownMenuStyle = styled(Dropdown)`
  &&& {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-bottom: 2px solid ${(props) => (props.selected ? colors.brand_dark_05 : 'transparent')} !important;
    border: none;
    border-radius: 0px !important;
    box-shadow: none;
    margin-top: 0px;
    padding: 0px 4px;
    height: 100%;
    ${font.body_xs_xs_strong(true)};
    color: ${(props) =>
      props.selected ? colors.text_primary_dark : colors.text_active_light} !important;

    > .text {
      padding-top: 2px !important;
    }

    .righticon {
      margin-top: 2px;
      margin-left: 4px !important;
    }

    .visible.transition {
      ${(props) => (props.route_info ? 'visibility: hidden !important;' : '')}
    }

    .primarymenu {
      .ui.menu .active.item {
        color: ${colors.text_primary_dark} !important;
      }
    }
  }
`;
