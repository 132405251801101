import React from 'react';
import styled from 'styled-components';
import { PopupLayout } from './PopupLayout';
import { PopupLayoutProps } from './PopupLayout';
import { CommonButton } from '../../button/CommonButton';
import { SizeEnum, ColorEnum } from '../../common/enums';

export interface AddEditPopupLayoutProps extends PopupLayoutProps {
  data?: any;
  onCancel?: () => void;
  onConfirm?: (data?: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;

  .ui.input {
    width: 480px !important;
  }

  textarea {
    width: 100% !important;
  }

  .ui.dropdown {
    width: 480px !important;
  }
`;

export const AddEditPopupLayout: React.FC<AddEditPopupLayoutProps> = ({
  title,
  children,
  isPopupOpen,
  setIsPopupOpen,
  data,
  onCancel,
  onConfirm,
}) => {
  return (
    <PopupLayout
      title={`${title} ${data ? '편집' : '추가'}`}
      buttons={
        <>
          <CommonButton
            sizeinfo={SizeEnum.M}
            colorinfo={ColorEnum.DEFAULT}
            content='취소'
            onClick={() => {
              setIsPopupOpen(false);
              if (onCancel) {
                onCancel();
              }
            }}
          />
          <CommonButton
            sizeinfo={SizeEnum.M}
            colorinfo={ColorEnum.PRIMARY}
            content={`${data ? '변경사항 저장' : '추가'}`}
            onClick={onConfirm}
          />
        </>
      }
      isPopupOpen={isPopupOpen}
      setIsPopupOpen={setIsPopupOpen}
    >
      <Container>{children}</Container>
    </PopupLayout>
  );
};
