import styled from 'styled-components';
import { IconStyle } from './CommonInputStyle';

export const CustomIconStyle = styled(IconStyle)`
  &&& {
    color: ${(props) => props.icon_color} !important;
    &:active {
      background: none !important;
    }
    &:hover {
      background: none !important;
    }
  }
`;
