import React from 'react';
import { Container, TitleDescLayoutStyle } from './TitleDescLayoutStyle';

interface TitleDescLayoutProps {
  title: string;
  desc: string;
  children: React.ReactNode;
}

const TitleDescLayout: React.FC<TitleDescLayoutProps> = ({ title, desc, children }) => {
  return (
    <Container>
      <TitleDescLayoutStyle>
        <div className='title'>{title}</div>
        <div className='desc'>{desc}</div>
      </TitleDescLayoutStyle>
      {children}
    </Container>
  );
};

export default TitleDescLayout;
