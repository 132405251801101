export const enum StatusEnum {
  DEFAULT,
  FOCUS,
  ERROR,
  DISABLED,
  SUCCESS,
}

export const enum AlertTypeEnum {
  SUCCESS,
  ERROR,
}

export enum SizeEnum {
  XS,
  S,
  M,
  L,
  XL,
}

export enum ColorEnum {
  DEFAULT,
  PRIMARY,
  GRAY,
  BORDERGRAY,
  BORDERPRIMARY,
  RED,
  PINK,
  YELLOW,
  ORANGE,
  GREEN,
  TEAL,
  BLUE,
  PURPLE,
}

export const enum InputTypes {
  CommonInput,
  LabelInput,
  SearchInput,
}

export const enum PlatformEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export const PlatformDisplayName = (platform: string) => {
  switch (platform) {
    case PlatformEnum.ANDROID:
      return 'Android';
    case PlatformEnum.IOS:
      return 'iOS';
    case PlatformEnum.WEB:
      return 'Web';
    default:
      return '';
  }
};

export const enum JoinTypeEnum {
  EMAIL = 'EMAIL',
  KAKAO = 'KAKAO',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  NAVER = 'NAVER',
}

export const JoinTypeDisplayName = (joinType: string) => {
  switch (joinType) {
    case JoinTypeEnum.EMAIL:
      return '자사 가입';
    case JoinTypeEnum.KAKAO:
      return '카카오';
    case JoinTypeEnum.APPLE:
      return '애플';
    case JoinTypeEnum.GOOGLE:
      return '구글';
    case JoinTypeEnum.NAVER:
      return '네이버';
    default:
      return '';
  }
};

export const enum UserStatusEnum {
  DISCIPLINED = 'DISCIPLINED',
  ACTIVE = 'ACTIVE',
}

export const UserStatusDisplayName = (status: string) => {
  switch (status) {
    case UserStatusEnum.DISCIPLINED:
      return '징계';
    case UserStatusEnum.ACTIVE:
      return '정상';
    default:
      return '';
  }
};

export const enum HistoryType {
  UPDATE = 'UPDATE',
  INITIAL_PROFILE_IMAGE = 'INITIAL_PROFILE_IMAGE',
  DISCIPLINE = 'DISCIPLINE',
  RELEASE_DISCIPLINE = 'RELEASE_DISCIPLINE',
}

export const HistoryTypeDisplayName = (type: string, member: string) => {
  switch (type) {
    case HistoryType.UPDATE:
      return `업데이트(${member})`;
    case HistoryType.INITIAL_PROFILE_IMAGE:
      return `프로필 이미지 초기화(${member})`;
    case HistoryType.DISCIPLINE:
      return `징계 추가(${member})`;
    case HistoryType.RELEASE_DISCIPLINE:
      return `징계 해제(${member})`;
    default:
      return '';
  }
};

export interface Permisson {
  key: string;
  displayName: string;
}

export type PermissionGroup = {
  menuName: string;
  permissions: Permisson[];
}[];

export const permissionGroups: PermissionGroup = [
  {
    menuName: '멤버 메뉴',
    permissions: [
      { key: 'ADMIN_VIEW', displayName: '보기' },
      { key: 'ADMIN_EDIT', displayName: '편집' },
      { key: 'ADMIN_ADD', displayName: '추가' },
      { key: 'ADMIN_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '약관목록',
    permissions: [
      { key: 'TERMS_VIEW', displayName: '보기' },
      { key: 'TERMS_EDIT', displayName: '편집' },
    ],
  },
  {
    menuName: '회원 목록',
    permissions: [
      { key: 'MEMBER_VIEW', displayName: '보기' },
      { key: 'MEMBER_EDIT', displayName: '편집' },
      { key: 'MEMBER_ADD', displayName: '추가' },
      { key: 'MEMBER_WITHDRAW', displayName: '탈퇴' },
      { key: 'MEMBER_DISCIPLINE', displayName: '징계' },
    ],
  },
  {
    menuName: '탈퇴 회원 목록',
    permissions: [
      { key: 'MEMBER_WITHDRAW_VIEW', displayName: '보기' },
      { key: 'MEMBER_WITHDRAW_RECOVERY', displayName: '복구' },
    ],
  },
  {
    menuName: '탈퇴 사유 관리',
    permissions: [
      { key: 'WITHDRAW_REASON_VIEW', displayName: '보기' },
      { key: 'WITHDRAW_REASON_EDIT', displayName: '편집' },
      { key: 'WITHDRAW_REASON_ADD', displayName: '추가' },
      { key: 'WITHDRAW_REASON_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '징계 회원 목록',
    permissions: [
      { key: 'MEMBER_DISCIPLINE_VIEW', displayName: '보기' },
      { key: 'MEMBER_DISCIPLINE_RELEASE', displayName: '징계해지' },
    ],
  },
  {
    menuName: '징계 정책/ 사유 관리',
    permissions: [
      { key: 'DISCIPLINE_REASON_VIEW', displayName: '보기' },
      { key: 'DISCIPLINE_REASON_EDIT', displayName: '편집' },
      { key: 'DISCIPLINE_REASON_ADD', displayName: '추가' },
      { key: 'DISCIPLINE_REASON_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '게시글 신고',
    permissions: [
      { key: 'REPORT_BOARD_VIEW', displayName: '보기' },
      { key: 'REPORT_BOARD_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '회원 신고',
    permissions: [{ key: 'REPORT_MEMBER_VIEW', displayName: '보기' }],
  },
  {
    menuName: '게시글',
    permissions: [
      { key: 'POSTING_VIEW', displayName: '보기' },
      { key: 'POSTING_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '배너 목록',
    permissions: [
      { key: 'BANNER_VIEW', displayName: '보기' },
      { key: 'BANNER_EDIT', displayName: '편집' },
      { key: 'BANNER_ADD', displayName: '추가' },
      { key: 'BANNER_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '팝업 목록',
    permissions: [
      { key: 'POPUP_VIEW', displayName: '보기' },
      { key: 'POPUP_EDIT', displayName: '편집' },
      { key: 'POPUP_ADD', displayName: '추가' },
      { key: 'POPUP_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '이메일 발송',
    permissions: [
      { key: 'MESSAGE_EMAIL_VIEW', displayName: '보기' },
      { key: 'MESSAGE_EMAIL_EDIT', displayName: '편집' },
      { key: 'MESSAGE_EMAIL_ADD', displayName: '추가' },
      { key: 'MESSAGE_EMAIL_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '푸시 발송',
    permissions: [
      { key: 'MESSAGE_PUSH_VIEW', displayName: '보기' },
      { key: 'MESSAGE_PUSH_EDIT', displayName: '편집' },
      { key: 'MESSAGE_PUSH_ADD', displayName: '추가' },
      { key: 'MESSAGE_PUSH_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '공지사항',
    permissions: [
      { key: 'BOARD_NOTICE_VIEW', displayName: '보기' },
      { key: 'BOARD_NOTICE_EDIT', displayName: '편집' },
      { key: 'BOARD_NOTICE_ADD', displayName: '추가' },
      { key: 'BOARD_NOTICE_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '이벤트',
    permissions: [
      { key: 'BOARD_EVENT_VIEW', displayName: '보기' },
      { key: 'BOARD_EVENT_EDIT', displayName: '편집' },
      { key: 'BOARD_EVENT_ADD', displayName: '추가' },
      { key: 'BOARD_EVENT_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: 'FAQ',
    permissions: [
      { key: 'BOARD_FAQ_VIEW', displayName: '보기' },
      { key: 'BOARD_FAQ_EDIT', displayName: '편집' },
      { key: 'BOARD_FAQ_ADD', displayName: '추가' },
      { key: 'BOARD_FAQ_DELETE', displayName: '삭제' },
    ],
  },
  {
    menuName: '1:1 문의',
    permissions: [
      { key: 'CS_CENTER_CONTACT_VIEW', displayName: '보기' },
      { key: 'CS_CENTER_CONTACT_ANSWER', displayName: '답변하기' },
      { key: 'CS_CENTER_CONTACT_EDIT', displayName: '답변편집' },
    ],
  },
  {
    menuName: ' 1:1 문의 타입',
    permissions: [
      { key: 'CS_CENTER_CONTENT_TYPE_VIEW', displayName: '보기' },
      { key: 'CS_CENTER_CONTENT_TYPE_EDIT', displayName: '편집' },
      { key: 'CS_CENTER_CONTENT_TYPE_ADD', displayName: '추가' },
      { key: 'CS_CENTER_CONTENT_TYPE_DELETE', displayName: '삭제' },
    ],
  },
];
