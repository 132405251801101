import React, { useState } from 'react';
import SnbFormMenu from '../../form/SnbFormMenu';
import { SubMenuItem } from '../../form/SnbFormMenu';
import * as ROUTES from '../../../routes/Routes';
import {
  SidebarContainer,
  AccordionStyle,
  MenuContainer,
  ServiceDropdownStyle,
} from './SideNavBarStyle';
import { Images } from '../../../assets/images/images';
import { useAuthorization } from '../../../hooks/useAuthorization';

interface SideNavBarProps {
  isopen: boolean;
}

interface MenuItem {
  label: string;
  route?: string;
  image: any;
  disabled?: string | null;
  subMenuItems?: SubMenuItem[];
}

const SideNavBar: React.FC<SideNavBarProps> = ({ isopen }) => {
  const [openMenuRoute, setOpenMenuRoute] = useState<string | null>(null);

  const handleMenuToggle = (route: string) => {
    if (openMenuRoute === route) {
      setOpenMenuRoute(null);
    } else {
      setOpenMenuRoute(route);
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      image: Images.icon_snb_dashboard,
      route: ROUTES.DASHBOARD,
    },
    {
      label: '약관',
      route: ROUTES.TERMS,
      image: Images.icon_snb_terms,
      subMenuItems: [
        { label: '약관 목록', route: ROUTES.TERMS, disabled: useAuthorization('TERMS_VIEW') },
        {
          label: '약관 히스토리',
          route: ROUTES.TERMS_HISTORY,
          disabled: useAuthorization('TERMS_HISTORY_VIEW', true),
        },
      ],
    },
    {
      label: '회원',
      image: Images.icon_snb_user,
      route: ROUTES.USER,
      subMenuItems: [
        { label: '회원 목록', route: ROUTES.USER, disabled: useAuthorization('MEMBER_VIEW') },
        {
          label: '탈퇴 회원 목록',
          route: ROUTES.WITHDRAW_USER,
          disabled: useAuthorization('MEMBER_WITHDRAW_VIEW'),
        },
        {
          label: '탈퇴 사유 관리',
          route: ROUTES.WITHDRAW_REASON,
          disabled: useAuthorization('WITHDRAW_REASON_VIEW'),
        },
      ],
    },
  ];

  return (
    <SidebarContainer $isopen={isopen}>
      <MenuContainer>
        <ServiceDropdownStyle
          className={`primarymenu`}
          icon={<i className={`fa-solid fa-caret-down`} />}
          text='Service Name'
          item
        ></ServiceDropdownStyle>
        서비스 메뉴
        <AccordionStyle>
          {menuItems.map(({ label, image, route, disabled, subMenuItems }) => (
            <SnbFormMenu
              key={label}
              menuTitleItem={{ image, title: label, route: route, disabled: disabled }}
              subMenuItems={subMenuItems || []}
              isOpen={
                openMenuRoute
                  ? (subMenuItems?.some((item) => item.route.startsWith(openMenuRoute)) ?? false)
                  : false
              }
              onMenuToggle={(path) => handleMenuToggle(path)}
            />
          ))}
        </AccordionStyle>
      </MenuContainer>
    </SidebarContainer>
  );
};

export default SideNavBar;
