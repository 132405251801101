import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .search-input {
    width: 200px !important;
    height: 32px !important;
    > input {
      width: 150px !important;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  height: 100%;

  .box {
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .user-list-header {
    height: 32px !important;
  }

  .user-list {
    cursor: pointer;
    min-height: 44px !important;
  }

  .cell-check {
    min-width: 32px !important;
    width: 32px !important;
  }

  .cell-key {
    min-width: 110px !important;
    width: 110px !important;
  }

  .cell-label {
    padding: 8px;
    min-width: 110px !important;
    width: 110px !important;
  }

  .cell-name {
    min-width: 140px !important;
    width: 140px !important;
  }

  .cell-email {
    min-width: 160px;
  }

  .cell-date {
    min-width: 160px !important;
    width: 160px !important;
  }

  .cell-state {
    min-width: 80px !important;
    width: 80px !important;
  }
`;
