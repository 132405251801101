import styled from 'styled-components';
import colors from '../../../components/common/colors';
import { BoxTableCell } from '../../../components/table/TableStyle';

export const TermsListCell = styled(BoxTableCell)`
  height: 60px;
  .more {
    .etc {
      width: 160px;
      color: ${colors.text_active};
    }
  }
  .platform {
    display: flex;
    width: 152px;
    align-items: center;
    gap: 4px;
  }

  .info {
    display: flex;
    min-width: 120px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    .version {
      width: 80px;
    }
  }

  .type {
    flex: 1 0 0;
  }

  .agree {
    .label {
      width: 61px;
    }
  }
`;
