import styled from 'styled-components';
import colors from '../components/common/colors';
import { font } from '../components/common/font';

export const SubMenuLayoutStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }
`;

interface SunMenuProps {
  $isActivated: boolean;
}

export const SubMenu = styled.div`
  display: flex;
  width: 169px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;

  @media (max-width: 768px) {
    display: flex;
    padding-bottom: 1px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    width: auto;
    flex-direction: row;
  }
  .sub-menu-title {
    @media (max-width: 768px) {
      display: none;
    }
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    ${font.body_xs_xs_regular()};
    color: ${colors.text_active_light};
  }
`;

export const SubMenuList = styled.div<SunMenuProps>`
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
  color: ${colors.text_active};
  ${(props) => (props.$isActivated ? font.body_s_s_strong() : font.body_s_s_regular())};
  border-radius: 4px;
  background: ${(props) => (props.$isActivated ? colors.brand_light_02 : 'transparent')};
`;

export const SubMenuContent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  color: ${colors.text_active};
  ${font.body_l_l_strong()};
  border-radius: 4px;
  background: ${colors.background_light_100};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);

  .input {
    width: 425px !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
`;
