import styled from 'styled-components';
import { Accordion, Dropdown } from 'semantic-ui-react';
import colors from '../../common/colors';
import { font } from '../../common/font';

interface SideNavBarContainerProps {
  $isopen: boolean;
}

export const SidebarContainer = styled.div<SideNavBarContainerProps>`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-left: ${(props) => (props.$isopen ? '0px' : '-200px')};
  opacity: ${(props) => (props.$isopen ? '1' : '0')};
  area-hidden: ${(props) => (props.$isopen ? 'false' : 'true')};
  width: 200px;
  height: 100%;
  position: fixed;
  top: 48px;
  transition:
    margin-left 0.3s ease-in-out,
    opacity 0.15s ease-in-out;
  overflow: hidden;
  border-right: 1px solid ${colors.line_black_opacity_10};
  background: ${colors.background_light_95};
  color: ${colors.text_active_light};
  ${font.label_label_xxs_strong()};
`;

export const MenuContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  color: ${colors.text_active_light};
  ${font.label_label_xxs_strong()};
`;

export const ServiceDropdownStyle = styled(Dropdown)`
  &&& {
    display: none;
    align-items: center;
    gap: 12px !important;
    align-self: stretch;
    border: 1px solid ${colors.line_black_90};
    border-radius: 4px !important;
    box-shadow: none;
    margin-top: 0px;
    padding: 8px;
    height: 40px;
    ${font.body_m_m_regular(true)};
    color: ${colors.text_active} !important;

    .visible.transition {
      visibility: hidden !important;
    }

    .primarymenu {
      .ui.menu .active.item {
        color: ${colors.text_primary_dark} !important;
      }
    }
  }
`;

export const AccordionStyle = styled(Accordion)`
  &&& {
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border: none;
    border-radius: 0px !important;
    box-shadow: none;
  }
`;
