import { css } from 'styled-components';

export const ellipsisLineStyle = (lineCount: number = 1) => css`
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: ${lineCount} !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
`;

export const ellipsisStyle = css`
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
`;

export const wrapTextStyle = css`
  width: auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: hidden;
  flex-shrink: 0;
`;
