import styled from 'styled-components';
import colors from '../../common/colors';
import { font } from '../../common/font';

export interface MenuProps {
  $showGNB: boolean;
}

export const MenuStyle = styled.div<MenuProps>`
  &&& {
    display: ${(props) => (props.$showGNB ? 'flex' : 'none')};
    padding: 0px;
    z-index: 1000;
    position: fixed;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 20px;
    height: 60px !important;
    background-color: ${colors.background_light_100};
    border: none;
    border-bottom: 1px solid ${colors.line_black_opacity_10};
    border-radius: 0px !important;
    box-shadow: none;
    margin-bottom: 0px;
    width: 100% !important;
    @media (max-width: 768px) {
      display: flex;
      height: 48px !important;
    }
    .item {
      background: none !important;
    }
    .item:before {
      display: none;
    }
    .content {
      width: 100%;
      display: flex;
      max-width: 1140px;
      padding: 0px 24px;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      .ui.image {
        width: 66px;
        height: 24px;
      }

      .left {
        @media (max-width: 768px) {
          display: none;
        }
        display: flex;
        align-items: center;
        gap: 12px;

        .menu_item {
          cursor: pointer;
          display: inline-flex;
          padding: 8px;
          align-items: center;
          gap: 4px;
          border-radius: 4px;

          ${font.body_s_s_strong(true)};
          color: ${colors.text_active} !important;

          &:hover {
            background: ${colors.bl_opacity_5} !important;
          }

          &.active {
            color: ${colors.text_primary_dark} !important;

            &:hover {
              background: ${colors.primary_opacity_10} !important;
            }
          }
        }
      }

      .right {
        margin-left: auto;
        align-items: center;
        display: flex;
        gap: 12px;
        @media (max-width: 768px) {
          display: none;
        }
        .lnb-container {
          position: relative;
          .lnb {
            top: 55px;
            position: fixed;
          }

          .profile {
            cursor: pointer;
            display: flex;
            padding: 4px;
            align-items: center;
            gap: 8px;

            ${font.body_s_s_regular()};
            color: ${colors.text_active} !important;

            > .ui.image {
              border-radius: 28px;
              width: 28px;
              height: 28px;
            }
          }
        }
      }

      .icon {
        margin-left: auto;
        @media (max-width: 768px) {
          display: flex;
        }
        cursor: pointer;
        display: none;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        color: ${colors.icon_default};
      }
    }
  }
`;
