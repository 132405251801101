import React from 'react';
import { Container, InfoContainer, HeaderStyle } from './ServicePageStyle';
import { useService } from '../../../contexts/ServiceContext';
import { convertDateFormat } from '../../../components/common/utils';
import { ImageUploadContainer } from '../../../components/image/ImageUploadContainer';
import { patchServiceImageUrl, deleteServiceImageUrl } from '../../../api/NoitApi';

export const ServicePage: React.FC = () => {
  let { service, setService } = useService();

  return (
    <Container>
      <ImageUploadContainer
        dirPath='service'
        buttonText='서비스 이미지 추가'
        imageUrl={service?.imageUrl}
        onImageUploadSuccess={async (uploadedImageUrl) => {
          try {
            if (service) {
              const updatedService = await patchServiceImageUrl(service.id, uploadedImageUrl);
              setService(updatedService);
            }
          } catch (error) {
            console.error('Failed to update service image URL', error);
          }
        }}
        onImageDelete={async () => {
          try {
            if (service) {
              const updatedService = await deleteServiceImageUrl(service.id);
              setService(updatedService);
            }
          } catch (error) {
            console.error('Failed to delete service image URL', error);
          }
        }}
      />
      <InfoContainer>
        서비스 정보
        <div className='info'>
          <HeaderStyle header='서비스 명' children={service?.name.korean ?? ''} />
          <HeaderStyle header='서비스 타입' children={service?.serviceTypeDescription ?? ''} />
          <HeaderStyle
            header='등록일시'
            children={service ? convertDateFormat(service?.createdAt) : ''}
          />
        </div>
      </InfoContainer>
    </Container>
  );
};

export default ServicePage;
