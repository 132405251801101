import React from 'react';
import { CommonDropDownStyle } from './CommonDropDownStyle';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { StatusEnum } from '../common/enums';
import { useInputStatusManagerProps } from '../../hooks/useInputStatusManager';

interface CommonDropDownProps extends DropdownProps, useInputStatusManagerProps {
  limit?: number;
}

export const CommonDropDown: React.FC<CommonDropDownProps> = ({
  name,
  setStatus,
  status = StatusEnum.DEFAULT,
  onDataChange,
  limit = 1,
  ...props
}) => {
  const handleChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const value = data.value as string[];
    if (limit === 1 && value.length > 0) {
      if (onDataChange != null) {
        onDataChange([value[value.length - 1]]);
      }
      if (props.onChange) {
        props.onChange(e, { ...data, value: value[value.length - 1] });
      }
    } else if (value.length <= limit) {
      if (onDataChange != null) {
        onDataChange(value);
      }

      if (props.onChange) {
        props.onChange(e, { ...data, value });
      }
    }
  };

  return (
    <CommonDropDownStyle
      status={status}
      clearable
      fluid
      multiple
      search={props.value && (props.value as string[]).length >= limit ? false : true}
      selection
      closeOnChange={limit <= 1 ? true : false}
      onChange={handleChange}
      className={limit <= 1 ? 'single-label' : ''}
      noResultsMessage='결과가 없습니다.'
      renderLabel={(item: DropdownItemProps) => ({
        className: item['labelname'] ? item['labelname'] : 'label',
        content: item.text,
      })}
      {...props}
    />
  );
};
