import styled from 'styled-components';
import colors from '../common/colors';
import { font } from '../common/font';
import { Label } from 'semantic-ui-react';
import { ellipsisStyle } from '../common/text';

export const LabelInputContainer = styled.div`
  display: inline-flex;
  height: 40px !important;
`;

export const LabelStyle = styled(Label)`
  margin: 0px !important;
  padding: 0px 12px !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  border-top: 1px solid ${colors.line_black_90} !important;
  border-bottom: 1px solid ${colors.line_black_90} !important;
  border-left: ${(props) =>
    props.position === 'right' ? 'none' : '1px solid ' + colors.line_black_90} !important;
  border-right: ${(props) =>
    props.position === 'left' ? 'none' : '1px solid ' + colors.line_black_90} !important;
  border-top-left-radius: ${(props) => (props.position === 'right' ? '0px' : '4px')} !important;
  border-bottom-left-radius: ${(props) => (props.position === 'right' ? '0px' : '4px')} !important;
  border-top-right-radius: ${(props) => (props.position === 'left' ? '0px' : '4px')} !important;
  border-bottom-right-radius: ${(props) => (props.position === 'left' ? '0px' : '4px')} !important;
  ${font.body_s_s_strong(true)};
  color: ${colors.text_active_light} !important;
  ${ellipsisStyle};
`;
