import React from 'react';
import { CommonLabelStyle } from './CommonLabelStyle';
import { LabelProps } from 'semantic-ui-react';
import { font } from '../common/font';
import colors from '../common/colors';
import { SizeEnum, ColorEnum } from '../common/enums';

interface CommonLabelProps extends LabelProps {
  sizeinfo?: SizeEnum;
  colorinfo?: ColorEnum;
  deleteicon?: boolean;
  righticon?: string;
  lefticon?: string;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
}

export const CommonLabel: React.FC<CommonLabelProps> = ({
  sizeinfo = SizeEnum.M,
  colorinfo = ColorEnum.GRAY,
  deleteicon,
  righticon,
  lefticon,
  onLeftIconClick,
  onRightIconClick,
  ...props
}) => {
  if (deleteicon) {
    righticon = 'fa-regular fa-circle-xmark';
  }

  const getSize = () => {
    switch (sizeinfo) {
      case SizeEnum.S:
        return {
          height: 24,
          padding: 8,
          font: font.label_label_xs_strong,
        };
      case SizeEnum.L:
        return {
          height: 32,
          padding: 12,
          font: font.label_label_m_strong,
        };
      case SizeEnum.M:
      default:
        return {
          height: 28,
          padding: 12,
          font: font.label_label_s_strong,
        };
    }
  };

  const getColor = () => {
    switch (colorinfo) {
      case ColorEnum.RED:
        return {
          color: colors.red_50,
          deleteColor: colors.red_70,
          background: colors.red_90,
        };
      case ColorEnum.PINK:
        return {
          color: colors.pink_50,
          deleteColor: colors.pink_70,
          background: colors.pink_90,
        };
      case ColorEnum.YELLOW:
        return {
          color: colors.yellow_50,
          deleteColor: colors.yellow_70,
          background: colors.yellow_90,
        };
      case ColorEnum.ORANGE:
        return {
          color: colors.orange_50,
          deleteColor: colors.orange_70,
          background: colors.orange_90,
        };
      case ColorEnum.GREEN:
        return {
          color: colors.green_50,
          deleteColor: colors.green_70,
          background: colors.green_90,
        };
      case ColorEnum.TEAL:
        return {
          color: colors.teal_50,
          deleteColor: colors.teal_70,
          background: colors.teal_90,
        };
      case ColorEnum.BLUE:
        return {
          color: colors.blue_50,
          deleteColor: colors.blue_70,
          background: colors.blue_90,
        };
      case ColorEnum.PURPLE:
        return {
          color: colors.purple_50,
          deleteColor: colors.purple_70,
          background: colors.purple_90,
        };
      case ColorEnum.GRAY:
      default:
        return {
          color: colors.text_label_default,
          deleteColor: colors.bl_opacity_30,
          background: colors.neutral_90,
        };
    }
  };

  return (
    <CommonLabelStyle sizeinfo={getSize()} colorinfo={getColor()} {...props}>
      {lefticon && <i className={`lefticon ${lefticon}`} onClick={onLeftIconClick} />}
      {props.children}
      {righticon && <i className={`righticon ${righticon}`} onClick={onRightIconClick} />}
    </CommonLabelStyle>
  );
};
