import React, { useEffect, useMemo, useState } from 'react';
import {
  AddEditPopupLayout,
  AddEditPopupLayoutProps,
} from '../../../components/popup/layout/AddEditPopupLayout';
import { MemberForm, ServiceMember } from '../../../api/models/MemberModels';
import { useService } from '../../../contexts/ServiceContext';
import { InputStatuses, useInputStatusManager } from '../../../hooks/useInputStatusManager';
import {
  deleteServiceAdminImageUrl,
  patchServiceAdminImageUrl,
  putAdmin,
} from '../../../api/NoitApi';
import { TitleWithComponent } from '../../../components/form/TitleWithComponent';
import { CommonInput } from '../../../components/input/CommonInput';
import { AuthTableStyle, MemberDetailPageStyle, MemberPopupStyle } from './MemberDetailPageStyle';
import { ImageUploadContainer } from '../../../components/image/ImageUploadContainer';
import { groupedPermissions } from '../../../hooks/useAuthorization';
import { CommonCheckboxStyle } from '../../../components/EtcStyle';
import { Permisson } from '../../../components/common/enums';
import { CheckboxProps } from 'semantic-ui-react';
import { useAuth } from '../../../contexts/AuthContext';

interface MemberPopupProps extends AddEditPopupLayoutProps {
  data?: ServiceMember | null;
  onlyInfo?: boolean;
}

const MemberPopup: React.FC<MemberPopupProps> = ({
  data,
  onlyInfo = false,
  isPopupOpen,
  setIsPopupOpen,
  onConfirm,
}) => {
  const { service } = useService();
  const { refreshAuth, myInfo } = useAuth();
  const [admin, setAdmin] = useState<ServiceMember | null>(data ?? null);
  const [authArray, setAuthArray] = useState<string[]>(admin?.authorities ?? []);

  const initioalData = useMemo((): InputStatuses => {
    return {
      '표시 이름': {
        required: true,
        value: admin?.name ?? '',
        hint: '*표시 이름은 실제 이름이거나 해당 백오피스 에서 불리고 싶은 별명일 수 있습니다.',
      },
      역할명: { required: true, value: admin?.roleName ?? '', disabled: admin?.managerFlag },
    };
  }, [admin]);

  const { inputStatuses, updateInputStatus, setStatus, setInputStatuses, checkAllValid } =
    useInputStatusManager(initioalData);

  useEffect(() => {
    if (isPopupOpen) {
      setInputStatuses(initioalData);
    }
  }, [isPopupOpen, setInputStatuses, initioalData]);

  const handleSubmit = () => {
    if (!service || !admin) {
      return;
    }

    if (!checkAllValid()) {
      return;
    }

    const form: MemberForm = {
      name: inputStatuses['표시 이름'].value as string,
      useFlag: true,
      imageUrl: admin?.imageUrl ?? null,
      roleName: inputStatuses['역할명'].value as string,
      emailId: admin?.emailId ?? '',
      authorities: authArray,
    };

    setIsPopupOpen(false);
    putAdmin(service.id, admin?.id, form).then((member) => {
      setAdmin(member);
      if (myInfo && member.account.id === myInfo.id) {
        refreshAuth();
      }
      onConfirm && onConfirm(member);
    });
  };

  const handleSelectAllPermisson = (permissions: Permisson[], checked: boolean) => {
    const permissionKeys = permissions.map((permission) => permission.key);

    if (checked) {
      const newAuthArray = Array.from(new Set([...authArray, ...permissionKeys]));
      setAuthArray(newAuthArray);
    } else {
      const newAuthArray = authArray.filter((key) => !permissionKeys.includes(key));
      setAuthArray(newAuthArray);
    }
  };

  const handleSelectPermisson = (permission: string, checked: boolean) => {
    if (checked) {
      if (!authArray.includes(permission)) {
        setAuthArray((prev) => [...prev, permission]);
      }
    } else {
      setAuthArray((prev) => prev.filter((key) => key !== permission));
    }
  };

  return (
    <MemberPopupStyle>
      <AddEditPopupLayout
        title='멤버 편집'
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        data={admin}
        onConfirm={handleSubmit}
      >
        {inputStatuses && (
          <MemberDetailPageStyle>
            <ImageUploadContainer
              dirPath='admin'
              buttonText='이미지 추가'
              imageUrl={admin?.imageUrl}
              onImageUploadSuccess={async (uploadedImageUrl) => {
                try {
                  if (admin && service) {
                    const updated = await patchServiceAdminImageUrl(
                      service.id,
                      admin.id,
                      uploadedImageUrl
                    );
                    if (myInfo && admin.account.id === myInfo.id) {
                      refreshAuth();
                    }
                    setAdmin(updated);
                    onConfirm && onConfirm(updated);
                  }
                } catch (error) {
                  console.error('Failed to update service image URL', error);
                }
              }}
              onImageDelete={async () => {
                try {
                  if (admin && service) {
                    const updated = await deleteServiceAdminImageUrl(service.id, admin.id);
                    if (myInfo && admin.account.id === myInfo.id) {
                      refreshAuth();
                    }
                    setAdmin(updated);
                    onConfirm && onConfirm(updated);
                  }
                } catch (error) {
                  console.error('Failed to delete service image URL', error);
                }
              }}
            />
            <div className='member-info'>
              <TitleWithComponent title='이름' isImport>
                <CommonInput name='이름' value={admin?.account.name} disabled />
              </TitleWithComponent>
              {inputStatuses &&
                Object.keys(inputStatuses).map((key) => {
                  const config = inputStatuses[key];
                  return (
                    <TitleWithComponent
                      status={config.status}
                      errorStr={config.errorMessage}
                      hintStr={config.hint}
                      title={key}
                      isImport={config.required}
                    >
                      <CommonInput
                        name={key}
                        value={config.value}
                        status={config.status}
                        setStatus={setStatus}
                        onDataChange={(value) => {
                          updateInputStatus({ [key]: { value: value } });
                        }}
                      />
                    </TitleWithComponent>
                  );
                })}
              <TitleWithComponent title='이메일' isImport>
                <CommonInput name='이메일' value={admin?.emailId} disabled />
              </TitleWithComponent>
              {!onlyInfo && (
                <div className='profile-info'>
                  권한
                  {groupedPermissions.map((group, index) => (
                    <div key={index} className='auth-info'>
                      {group.map((permission, index) => {
                        const isAllSelected = permission.permissions.every((permission) =>
                          authArray.includes(permission.key)
                        );
                        return (
                          <AuthTableStyle key={index}>
                            <div className='auth-title'>
                              <CommonCheckboxStyle
                                disabled={admin?.managerFlag}
                                checked={isAllSelected}
                                onChange={(
                                  event: React.FormEvent<HTMLInputElement>,
                                  data: CheckboxProps
                                ) => {
                                  event.stopPropagation();
                                  handleSelectAllPermisson(
                                    permission.permissions,
                                    data.checked || false
                                  );
                                }}
                              />
                              <div className='auth-text'>{permission.menuName}</div>
                            </div>
                            <div key={index} className='auth-type'>
                              {permission.permissions.map((item, index) => (
                                <div key={index} className='auth'>
                                  <CommonCheckboxStyle
                                    disabled={admin?.managerFlag}
                                    checked={authArray.includes(item.key)}
                                    onChange={(
                                      event: React.FormEvent<HTMLInputElement>,
                                      data: CheckboxProps
                                    ) => {
                                      event.stopPropagation();
                                      handleSelectPermisson(item.key, data.checked || false);
                                    }}
                                  />
                                  <div className='auth-text'>{item.displayName}</div>
                                </div>
                              ))}
                            </div>
                          </AuthTableStyle>
                        );
                      })}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </MemberDetailPageStyle>
        )}
      </AddEditPopupLayout>
    </MemberPopupStyle>
  );
};

export default MemberPopup;
