import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CMSDetailLayoutStyle } from './CMSDetailLayoutStyle';
import { CommonButton } from '../../components/button/CommonButton';
import { ColorEnum, SizeEnum } from '../../components/common/enums';

interface CMSDetailLayoutProps {
  title: string;
  actions?: React.ReactNode;
}

const CMSDetailLayout: React.FC<CMSDetailLayoutProps> = ({ title, actions }) => {
  const navigate = useNavigate();

  return (
    <CMSDetailLayoutStyle>
      <CommonButton
        sizeinfo={SizeEnum.XS}
        colorinfo={ColorEnum.BORDERGRAY}
        content='뒤로'
        onClick={() => navigate(-1)}
      />
      <div className='title'>{title}</div>
      {actions}
    </CMSDetailLayoutStyle>
  );
};

export default CMSDetailLayout;
