import styled from 'styled-components';
import colors from '../components/common/colors';
import { font } from '../components/common/font';

export const Container = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const TitleDescLayoutStyle = styled.div`
  display: flex;
  min-height: 60px;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  > .title {
    ${font.heading_heading_s()};
    color: ${colors.text_active};
  }
  > .desc {
    ${font.body_s_s_regular()};
    color: ${colors.text_active};
  }
`;
