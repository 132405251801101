import React, { useCallback, useEffect, useState } from 'react';
import { FilterContainer, ListContainer } from './UserPageStyle';
import { SearchInput } from '../../../components/input/SearchInput';
import { FilterDropDown } from '../../../components/dropDown/FilterDropDown';
import {
  BoxTableHeader,
  CommonTableCell,
  CommonTableHeaderCell,
} from '../../../components/table/TableStyle';
import { CommonCheckboxStyle } from '../../../components/EtcStyle';
import { useService } from '../../../contexts/ServiceContext';
import { User, UserSearch } from '../../../api/models/UserModels';
import { getExcel, getMembersSimpleItems, getUsers } from '../../../api/NoitApi';
import { CheckboxProps, DropdownItemProps, InputOnChangeData } from 'semantic-ui-react';
import { CommonButton } from '../../../components/button/CommonButton';
import {
  ColorEnum,
  JoinTypeDisplayName,
  JoinTypeEnum,
  PlatformDisplayName,
  PlatformEnum,
  SizeEnum,
  UserStatusDisplayName,
  UserStatusEnum,
} from '../../../components/common/enums';
import { CommonLabel } from '../../../components/label/CommonLabel';
import { convertDateFormat, convertToISOSting } from '../../../components/common/utils';
import { debounce } from 'lodash';
import { Page } from '../../../api/models/CommonModels';
import { FilterDropDownDatePicker } from '../../../components/dropDown/FilterDropDownDatePicker';
import { FilterRange } from '../../../components/dropDown/FilterRange';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';

const debouncedGetUsers = debounce(
  (
    serviceId,
    callback,
    search?,
    joinTypes?,
    statuses?,
    joinedStartAt?,
    joinedEndAt?,
    reportCounts?,
    ids?
  ) => {
    getUsers(
      serviceId,
      search,
      joinTypes,
      statuses,
      joinedStartAt,
      joinedEndAt,
      reportCounts,
      ids
    ).then(callback);
  },
  500
);

const UserListPage: React.FC = () => {
  let { service } = useService();
  const [users, setUsers] = useState<User[]>([]);
  const [nameOptions, setNameOptions] = useState<DropdownItemProps[] | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [joinTypes, setJoinTypes] = useState<string[] | null>(null);
  const [statuses, setStatuses] = useState<string[] | null>(null);
  const [joinedStartAt, setJoinedStartAt] = useState<string | null>(null);
  const [joinedEndAt, setJoinedEndAt] = useState<string | null>(null);
  const [reportCounts, setReportCounts] = useState<number | null>(null);
  const [ids, setIds] = useState<number[] | null>(null);

  const refresh = useCallback(() => {
    if (!service) return;
    if (service) {
      debouncedGetUsers(
        service.id,
        (res: Page<User>) => {
          setUsers(res.items);
        },
        search,
        joinTypes,
        statuses,
        joinedStartAt,
        joinedEndAt,
        reportCounts,
        ids
      );
    }
  }, [service, search, joinTypes, statuses, joinedStartAt, joinedEndAt, reportCounts, ids]);

  const getSimple = useCallback(() => {
    if (!service) return;
    getMembersSimpleItems(service.id).then((res: Page<UserSearch>) => {
      let items: DropdownItemProps[] = res.items.map((item) => ({
        value: item.id,
        content: item.name,
        text: `${item.name}(${item.emailId})`,
      }));
      setNameOptions(items);
    });
  }, [service]);

  useEffect(() => {
    refresh();
    getSimple();
  }, [refresh, getSimple]);

  const [checkedList, setCheckedList] = useState<{ [id: number]: boolean }>({});
  const isAllSelected = users.length > 0 && users.every((user) => checkedList[user.id]);
  const selectedCount = Object.values(checkedList).filter((checked) => checked).length;

  const handleSelectAll = (checked: boolean) => {
    setCheckedList((prev) => ({
      ...prev,
      ...users.reduce((acc, cur) => ({ ...acc, [cur.id]: checked }), {}),
    }));
  };

  const handleSelect = (id: number, checked: boolean) => {
    setCheckedList((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const getSelected = () => {
    return Object.entries(checkedList)
      .filter(([, checked]) => checked)
      .map(([id]) => Number(id));
  };

  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);

  const navigate = useNavigate();
  const goToDetailPage = (user: User) => {
    navigate(ROUTES.USER_DETAIL(user.id));
  };

  return nameOptions ? (
    <>
      <FilterContainer>
        <SearchInput
          onChange={(_event, data: InputOnChangeData) => {
            setSearch(data.value);
          }}
        />
        <FilterDropDown
          options={nameOptions}
          name='이름'
          limit={1}
          onSelectedChange={(selected) => {
            if (selected.length === 0) {
              setIds(null);
              return;
            }

            let ids: number[] = selected.map((item) => Number(item.value));
            setIds(ids);
          }}
        />
        <FilterDropDown
          limit={1}
          options={[
            { value: JoinTypeEnum.EMAIL, text: JoinTypeDisplayName(JoinTypeEnum.EMAIL) },
            { value: JoinTypeEnum.KAKAO, text: JoinTypeDisplayName(JoinTypeEnum.KAKAO) },
            { value: JoinTypeEnum.APPLE, text: JoinTypeDisplayName(JoinTypeEnum.APPLE) },
            { value: JoinTypeEnum.GOOGLE, text: JoinTypeDisplayName(JoinTypeEnum.GOOGLE) },
            { value: JoinTypeEnum.NAVER, text: JoinTypeDisplayName(JoinTypeEnum.NAVER) },
          ]}
          name='가입 수단'
          onSelectedChange={(selected) => {
            if (selected.length === 0) {
              setJoinTypes(null);
              return;
            }

            let result: string[] = selected.map((item) => item.value as string);
            setJoinTypes(result);
          }}
        />
        <FilterDropDownDatePicker
          name='가입일'
          onDateChange={(dates) => {
            if (dates[0] === null) {
              setJoinedStartAt(null);
              setJoinedEndAt(null);
              return;
            }

            setJoinedStartAt(convertToISOSting(dates[0]));
            setJoinedEndAt(convertToISOSting(dates[1] ?? new Date(), true));
          }}
        />
        <FilterDropDown
          limit={1}
          options={[
            { value: UserStatusEnum.ACTIVE, text: UserStatusDisplayName(UserStatusEnum.ACTIVE) },
            {
              value: UserStatusEnum.DISCIPLINED,
              text: UserStatusDisplayName(UserStatusEnum.DISCIPLINED),
            },
          ]}
          name='상태'
          onSelectedChange={(selected) => {
            if (selected.length === 0) {
              setStatuses(null);
              return;
            }

            let result: string[] = selected.map((item) => item.value as string);
            setStatuses(result);
          }}
        />
        <FilterRange
          name='신고 수'
          onChange={(max) => {
            setReportCounts(max);
          }}
        />
      </FilterContainer>
      <ListContainer>
        <BoxTableHeader>
          <div className='header'>
            <CommonCheckboxStyle
              checked={isAllSelected}
              onChange={(_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
                handleSelectAll(data.checked || false)
              }
            />
            회원 수 :<div className='count_total'>{users.length}</div>
            {selectedCount > 0 && (
              <>
                <div className='count'>({selectedCount}개 선택됨)</div>
                <CommonButton
                  className='delete'
                  sizeinfo={SizeEnum.XS}
                  colorinfo={ColorEnum.GRAY}
                  content='Excel 다운로드'
                  onClick={() => {
                    if (!service) {
                      return;
                    }
                    getExcel(
                      service.id,
                      'members',
                      `${service.name.korean}_회원목록`,
                      getSelected()
                    );
                  }}
                />
              </>
            )}
          </div>
        </BoxTableHeader>
        <div className='box'>
          <table>
            <thead>
              <tr className='user-list-header'>
                <CommonTableHeaderCell className='cell-check' />
                <CommonTableHeaderCell className='cell-key'>KEY</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-name'>이름</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-key'>가입 플랫폼</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-key'>가입 수단</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-email'>이메일</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-date'>가입 일시</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-state'>상태</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-state'>신고 수</CommonTableHeaderCell>
                <CommonTableHeaderCell className='cell-date'>
                  마지막 로그인 일시
                </CommonTableHeaderCell>
              </tr>
            </thead>

            <tbody>
              {users.map((user) => (
                <tr
                  key={user.id}
                  className='user-list'
                  style={{ backgroundColor: highlightedRow === user.id ? '#0000000D' : '#ffffff' }}
                  onClick={() => goToDetailPage(user)}
                  onMouseEnter={() => setHighlightedRow(user.id)}
                  onMouseLeave={() => setHighlightedRow(null)}
                >
                  <CommonTableCell className='cell-check'>
                    <CommonCheckboxStyle
                      checked={checkedList[user.id]}
                      onChange={(_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
                        handleSelect(user.id, data.checked || false)
                      }
                    />
                  </CommonTableCell>
                  <CommonTableCell className='cell-key'>{user.id}</CommonTableCell>
                  <CommonTableCell className='cell-name'>{user.name}</CommonTableCell>
                  <CommonTableCell className='cell-label'>
                    {user.joinPlatform === PlatformEnum.ANDROID ? (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GREEN}
                        children={`${PlatformDisplayName(PlatformEnum.ANDROID)}`}
                      />
                    ) : user.joinPlatform === PlatformEnum.IOS ? (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GRAY}
                        children={`${PlatformDisplayName(PlatformEnum.IOS)}`}
                      />
                    ) : (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.YELLOW}
                        children={`${PlatformDisplayName(PlatformEnum.WEB)}`}
                      />
                    )}
                  </CommonTableCell>
                  <CommonTableCell className='cell-key'>
                    {JoinTypeDisplayName(user.joinType)}
                  </CommonTableCell>
                  <CommonTableCell className='cell-email'>{user.emailId}</CommonTableCell>
                  <CommonTableCell className='cell-date'>
                    {convertDateFormat(user.joinedAt)}
                  </CommonTableCell>
                  <CommonTableCell className='cell-state'>
                    {UserStatusDisplayName(user.status)}
                  </CommonTableCell>
                  <CommonTableCell className='cell-state'>
                    {user.report.reportedCnt === 0 ? '-' : user.report.reportedCnt}
                  </CommonTableCell>
                  <CommonTableCell className='cell-date'>
                    {convertDateFormat(user.latestActiveAt)}
                  </CommonTableCell>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ListContainer>
    </>
  ) : null;
};

export default UserListPage;
