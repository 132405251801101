import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './app/store';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { AuthProvider } from './contexts/AuthContext';
import { setCredentials } from './features/auth/authSlice';
import logger from './components/common/logger';

if (process.env.REACT_APP_ENABLE_SENTRY !== 'false') {
  Sentry.init({
    dsn: 'https://030ca69437f5a951c66dfc9998abefa7@o4506036768342016.ingest.us.sentry.io/4506897917280256',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

function loadCredentialsFromStorage() {
  const storedAccessToken = localStorage.getItem('accessToken');
  const storedRefreshToken = localStorage.getItem('refreshToken');
  logger.log('Stored credentials:', storedAccessToken, storedRefreshToken);

  if (storedAccessToken && storedRefreshToken) {
    store.dispatch(
      setCredentials({ accessToken: storedAccessToken, refreshToken: storedRefreshToken })
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ReduxProvider>
  </React.StrictMode>
);

loadCredentialsFromStorage();
