import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isRefreshingToken?: boolean;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isRefreshingToken: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    logout: (state) => {
      localStorage.clear();
      state.accessToken = null;
      state.refreshToken = null;
    },
    startTokenRefresh: (state) => {
      state.isRefreshingToken = true; // 토큰 갱신 시작
    },
    finishTokenRefresh: (state) => {
      state.isRefreshingToken = false; // 토큰 갱신 완료
    },
  },
});

export const { setCredentials, logout, startTokenRefresh, finishTokenRefresh } = authSlice.actions;

export default authSlice.reducer;
