import { PermissionGroup, permissionGroups } from '../components/common/enums';
import { useAuth } from '../contexts/AuthContext';
import { useMember } from '../contexts/MemberContext';

export function useAuthorization(permission: string, onlyNoit: boolean = false) {
  const { serviceMember } = useMember();
  const { isNoitManager } = useAuth();

  const hasRequiredPermission =
    isNoitManager || (serviceMember?.authorities ?? []).includes(permission);

  return hasRequiredPermission
    ? null
    : onlyNoit
      ? '메뉴 접근 권한이 없습니다.\n기능 추가가 필요할 경우 고객센터로 문의주세요.'
      : '해당 메뉴 및 기능 접근 권한이 없습니다.\n대표자(Master)에게 문의하세요.';
}

export const groupedPermissions = permissionGroups.reduce<PermissionGroup[]>((acc, item, index) => {
  if (index % 2 === 0) acc.push([item]);
  else acc[acc.length - 1].push(item);
  return acc;
}, []);
