import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';
import { ellipsisStyle } from '../common/text';

export const TabStyle = styled(Tab)`
  &&& {
    width: 100%;
    .ui.menu {
      display: flex;
      align-items: flex-start;
      gap: 0px;
      align-self: stretch;
      margin-bottom: 24px;
      border-bottom: 1px solid ${colors.line_black_opacity_10};
      .item {
        ${ellipsisStyle}
        ${font.body_s_s_regular()};
        border-radius: 4px 4px 0px 0px;
        color: ${colors.text_active};
        display: inline-block;
        max-width: 200px;
        padding: 12px;
        align-items: flex-start;
        gap: 0px;
        margin: 0px;

        &:hover {
          border-bottom: 1px solid ${colors.line_black_opacity_10};
          background: ${colors.background_light_95};
          border-color: transparent;
        }
      }
      .active.item {
        ${font.body_s_s_strong()};
        color: ${colors.text_primary_dark};
        border-bottom: 1px solid ${colors.text_primary_dark} !important;
      }
    }
  }
`;
