import React, { useState } from 'react';
import { CustomIconStyle } from './SearchInputStyle';
import { CommonInput, CommonInputProps } from './CommonInput';
import colors from '../common/colors';
import { InputOnChangeData } from 'semantic-ui-react';

export const SearchInput: React.FC<CommonInputProps> = ({ ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const [iconColor, setIconColor] = useState(colors.icon_light);

  return (
    <CommonInput
      className='search-input'
      {...props}
      icon={
        <CustomIconStyle icon_color={iconColor}>
          <i className='fa-solid fa-magnifying-glass' />
        </CustomIconStyle>
      }
      customFocus={() => setIconColor(colors.icon_default)}
      value={inputValue}
      customBlur={() => {
        if (inputValue.length > 0) {
          setIconColor(colors.icon_default);
        } else {
          setIconColor(colors.icon_light);
        }
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setInputValue(data.value);
        if (props.onChange) {
          props.onChange(event, data);
        }
      }}
    />
  );
};
