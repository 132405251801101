import React, { useEffect, useState } from 'react';
import {
  Container,
  PopupLayoutContainer,
  PopupBodyContainer,
  PopupButtonContainer,
} from './PopupLayoutStyle';
import { PopupTitle } from '../CommonDialogPopupStyle';

export interface PopupLayoutProps {
  title?: string;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPopupOpen?: boolean;
}

export const PopupLayout: React.FC<PopupLayoutProps> = ({
  title,
  children,
  buttons,
  isPopupOpen = false,
}) => {
  const [delayedPopupOpen, setDelayedPopupOpen] = useState(isPopupOpen);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isPopupOpen) {
      setDelayedPopupOpen(true);
    } else {
      timer = setTimeout(() => {
        setDelayedPopupOpen(false);
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [isPopupOpen]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (delayedPopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalStyle;
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [delayedPopupOpen]);

  return (
    <>
      {delayedPopupOpen && (
        <Container $isPopupOpen={isPopupOpen}>
          <PopupLayoutContainer className='popup-layout-container'>
            <PopupTitle>{title}</PopupTitle>
            <PopupBodyContainer>{children}</PopupBodyContainer>
            <PopupButtonContainer>{buttons}</PopupButtonContainer>
          </PopupLayoutContainer>
        </Container>
      )}
    </>
  );
};
