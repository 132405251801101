import { createContext, useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { ServiceMember } from '../api/models/MemberModels';
import { useService } from './ServiceContext';
import { useAuth } from './AuthContext';

interface MemberContextType {
  serviceMember: ServiceMember | null;
  setServiceMember: React.Dispatch<React.SetStateAction<ServiceMember | null>>;
}

const MemberContext = createContext<MemberContextType | undefined>(undefined);

export const MemberProvider = ({ children }: { children: ReactNode }) => {
  const [serviceMember, setServiceMember] = useState<ServiceMember | null>(null);
  const { serviceId } = useService();
  const { myAccount, myInfo, refreshAuth } = useAuth();

  const refreshAccount = useCallback(() => {
    if (!serviceId || !myAccount || !myInfo) return;

    const serviceMember = myAccount.profiles.find((profile) => profile.service.id === serviceId);
    setServiceMember(serviceMember ?? null);
    const serviceAuthority = myInfo.serviceAuthorities.find(
      (authority) => authority.serviceId === serviceId
    );
    const equal =
      serviceMember?.authorities.length === serviceAuthority?.authorities.length &&
      serviceMember?.authorities
        .sort()
        .every((value, index) => value === serviceAuthority?.authorities.sort()[index]);
    if (serviceAuthority && serviceMember && !equal) {
      refreshAuth();
    }
  }, [serviceId, myAccount, myInfo, refreshAuth]);

  useEffect(() => {
    refreshAccount();
  }, [refreshAccount]);

  const value = {
    serviceMember,
    setServiceMember,
    refreshAccount,
  };

  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
};

export const useMember = () => {
  const context = useContext(MemberContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
