import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuStyle, MenuIconStyle, RightContentStyle } from './GlobalNavBarStyle';
import { ClickableImage } from '../../EtcStyle';
import GnbDropdownMenu from '../../dropDown/GnbDropdownMenu';
import { Images } from '../../../assets/images/images';
import * as ROUTES from '../../../routes/Routes';
import { Menu } from 'semantic-ui-react';
import { LNB } from '../../dropDown/LNB';
import colors from '../../common/colors';
import { useMember } from '../../../contexts/MemberContext';
import { store } from '../../../app/store';
import { logout } from '../../../features/auth/authSlice';
import { useAuth } from '../../../contexts/AuthContext';
import { useAuthorization } from '../../../hooks/useAuthorization';

interface GlobalNavBarProps {
  setSnbOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalNavBar: React.FC<GlobalNavBarProps> = ({ setSnbOpen }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { serviceMember } = useMember();
  const { isNoitManager } = useAuth();

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <MenuStyle>
      <MenuIconStyle
        icon='bars'
        custom_padding='0px 5px 0px 21px'
        icon_color={colors.icon_default}
        onClick={() => setSnbOpen((prevState) => !prevState)}
      />
      <ClickableImage
        src={Images.intro_logo_black}
        onClick={() => navigate(ROUTES.DASHBOARD)}
        styles={{ width: '100px' }}
      />
      {(isNoitManager || serviceMember?.managerFlag) && (
        <GnbDropdownMenu
          menuTitle='설정'
          menuItems={[
            { label: '서비스 관리', route: ROUTES.SERVICE },
            { label: '플랫폼 타입 및 버전', route: ROUTES.PLATFORM },
          ]}
        />
      )}
      {!useAuthorization('ADMIN_VIEW') && (
        <GnbDropdownMenu menuTitle='멤버' route_info={ROUTES.MEMBERS} />
      )}
      <Menu.Menu position='right'>
        <RightContentStyle>
          <ClickableImage
            src={serviceMember?.imageUrl ? serviceMember?.imageUrl : Images.icon_profile_default}
            onClick={handleOpen}
          />

          <LNB
            open={open}
            setOpen={setOpen}
            menuItems={[
              {
                label: '프로필 설정',
                onClick: () => {
                  navigate(ROUTES.PROFILE);
                },
              },
              {
                label: '나의 백 오피스',
                onClick: () => {
                  navigate(ROUTES.MY_BACK_OFFICE);
                },
              },
              {
                label: '로그아웃',
                onClick: () => {
                  store.dispatch(logout());
                  navigate(ROUTES.HOME);
                },
              },
            ]}
          />
        </RightContentStyle>
      </Menu.Menu>
    </MenuStyle>
  );
};

export default GlobalNavBar;
