import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { NoitError, Page } from './models/CommonModels';
import { store } from '../app/store';
import {
  setCredentials,
  logout,
  startTokenRefresh,
  finishTokenRefresh,
} from '../features/auth/authSlice';
import {
  Service,
  Terms,
  TermsDetail,
  TermsForm,
  TermsName,
  Version,
  VersionForm,
} from './models/ServiceModels';
import { AdminJoinRequest, Member, MemberForm, ServiceMember } from './models/MemberModels';
import logger from '../components/common/logger';
import { apiCallBegan, apiCallFailed, apiCallSuccess } from '../features/api/apiSlice';
import { triggerGlobalAlert } from '../global';
import { AlertTypeEnum } from '../components/common/enums';
import { User, UserDetail, UserSearch } from './models/UserModels';

//https://api-sandbox.no-it.io/docs#

const successMessages = {
  post: '추가가 완료되었습니다.',
  patch: '편집이 완료되었습니다.',
  put: '편집이 완료되었습니다.',
  delete: '삭제가 완료되었습니다.',
};

const errorMessages = {
  post: '추가를 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
  patch: '편집을 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
  put: '편집을 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
  delete: '삭제를 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
};

const errorStatusMessages = {
  400: 'Error Code : 400\n잘못된 요청사항입니다.\n확인 후 다시 시도해 주세요.',
  404: 'Error Code : 404\n요청하신 내용을 찾을 수 없습니다.\n확인 후 다시 시도해 주세요.',
  405: 'Error Code : 405\n요청하신 내용을 확인할 수 없습니다.\n확인 후 다시 시도해 주세요.',
  415: 'Error Code : 415\n지원되지 않은 형식입니다.\n확인 후 다시 시도해 주세요.',
};

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
  _showLoading?: boolean;
  skipAlert?: boolean;
  successMessages?: string;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const customConfig = config as CustomAxiosRequestConfig;

    if (store.getState().auth.accessToken) {
      customConfig.headers['Authorization'] = `Bearer ${store.getState().auth.accessToken}`;
    }

    if (customConfig._showLoading) {
      // 로딩 시작 액션 디스패치
      store.dispatch(apiCallBegan());
    }

    logger.log('Starting Request', config);
    return customConfig;
  },
  (error) => {
    // 요청 에러를 콘솔에 출력합니다.
    logger.error('Request Error', error);
    store.dispatch(apiCallFailed(error.message));
    return Promise.reject(error);
  }
);

export const getRenewToken = async (refreshToken: string) => {
  store.dispatch(startTokenRefresh());
  try {
    const response = await api.get('/admin-account/renew-token', {
      _retry: true,
      skipAlert: true,
      headers: {
        AuthorizationR: `Bearer ${refreshToken}`,
      },
    } as AxiosRequestConfig as CustomAxiosRequestConfig);
    // 갱신된 토큰으로 상태 업데이트
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    store.dispatch(setCredentials({ accessToken: accessToken, refreshToken: newRefreshToken }));
  } catch (error) {
    logger.error('Failed to refresh token', error);
    store.dispatch(logout());
  } finally {
    store.dispatch(finishTokenRefresh());
  }
};

api.interceptors.response.use(
  (response) => {
    logger.log('Response:', response);
    const customConfig = response.config as CustomAxiosRequestConfig;

    if (customConfig._showLoading) {
      store.dispatch(apiCallSuccess());
    }

    const message = customConfig.successMessages
      ? customConfig.successMessages
      : successMessages[response.config.method as keyof typeof successMessages];
    if (!customConfig.skipAlert && message) {
      triggerGlobalAlert({ msg: message, type: AlertTypeEnum.SUCCESS });
    }

    return response;
  },
  async (error: AxiosError<NoitError>) => {
    logger.log('Response Error:', error);
    store.dispatch(apiCallFailed(error.message));
    const customConfig = error.config as CustomAxiosRequestConfig;

    if (error.response?.status === 401 && customConfig && !customConfig._retry) {
      customConfig._retry = true;
      const token = store.getState().auth.refreshToken;
      if (token) {
        await getRenewToken(token);
        customConfig.headers!['Authorization'] = `Bearer ${store.getState().auth.accessToken}`;
        return api.request(customConfig);
      }
    } else {
      if (!error.response) {
        triggerGlobalAlert({
          msg: '서버와의 통신이 원할하지 않습니다.\n확인 후 다시 시도해 주세요.',
          type: AlertTypeEnum.ERROR,
        });
        return Promise.reject(error);
      }

      // 실패 알림
      if (!customConfig.skipAlert) {
        const status = error.response.status;
        const method = error.config?.method;
        const message =
          errorMessages[method as keyof typeof errorMessages] ||
          errorStatusMessages[status as keyof typeof errorStatusMessages] ||
          'Error 요청하신 내용을 처리하지 못했습니다. 확인 후 다시 시도해 주세요.';
        triggerGlobalAlert({ msg: message, type: AlertTypeEnum.ERROR });
      }
    }

    store.dispatch(apiCallFailed(error.message));

    return Promise.reject(error);
  }
);

// Intro

export const postMemberLogin = async (loginId: string, password: string): Promise<void> => {
  try {
    const response = await api.post(
      'admin-account/login',
      {
        loginId: loginId,
        password: password,
      },
      {
        _showLoading: true,
        skipAlert: true,
      } as CustomAxiosRequestConfig
    );
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    store.dispatch(setCredentials({ accessToken: accessToken, refreshToken: newRefreshToken }));
  } catch (error) {
    logger.error('Error logging in member', error);
    throw error;
  }
};

export const postAdmin = async (loginId: string): Promise<void> => {
  try {
    await api.post(
      'admin-account',
      {
        loginId: loginId,
      },
      {
        _showLoading: true,
        skipAlert: true,
      } as CustomAxiosRequestConfig
    );
  } catch (error) {
    logger.error('Error logging in admin', error);
    throw error;
  }
};

export const postAdminJoinRequest = async (
  adminAccountId: number,
  request: AdminJoinRequest
): Promise<void> => {
  try {
    await api.post(`/admin-account/${adminAccountId}/verify`, request, {
      _showLoading: true,
      skipAlert: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    logger.error('Error joining admin', error);
    throw error;
  }
};

export const postAdminVerifyJoin = async (
  serviceId: number,
  adminId: number,
  request: AdminJoinRequest
): Promise<void> => {
  try {
    await api.post(`/services/${serviceId}/admin/${adminId}/verify-join`, request, {
      _showLoading: true,
      skipAlert: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    logger.error('Error verifying admin', error);
    throw error;
  }
};

export const postAdminVerify = async (
  serviceId: number,
  adminId: number,
  verifyToken: string
): Promise<void> => {
  try {
    const response = await api.post(
      `/services/${serviceId}/admin/${adminId}/verify`,
      {
        verifyToken: verifyToken,
      },
      {
        _showLoading: true,
        skipAlert: true,
      } as CustomAxiosRequestConfig
    );
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    store.dispatch(setCredentials({ accessToken: accessToken, refreshToken: newRefreshToken }));
  } catch (error) {
    logger.error('Error verifying admin', error);
    throw error;
  }
};

export const patchAdminImageUrl = async (
  adminAccountId: number,
  imageUrl: string
): Promise<Member> => {
  try {
    const response: AxiosResponse<Member> = await api.patch(
      `/admin-account/${adminAccountId}/image-url`,
      {
        imageUrl: imageUrl,
      },
      {
        _showLoading: true,
        skipAlert: true,
      } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    logger.error('Error updating admin image URL', error);
    throw error;
  }
};

export const deleteAdminImageUrl = async (adminAccountId: number): Promise<Member> => {
  try {
    const response: AxiosResponse<Member> = await api.delete(
      `/admin-account/${adminAccountId}/image-url`,
      {
        _showLoading: true,
        skipAlert: true,
      } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    logger.error('Error deleting image URL', error);
    throw error;
  }
};

export const getMember = async (adminAccountId: number): Promise<Member> => {
  try {
    const response: AxiosResponse<Member> = await api.get(`admin-account/${adminAccountId}`, {
      _showLoading: true,
      skipAlert: true,
    } as CustomAxiosRequestConfig);
    return response.data;
  } catch (error) {
    logger.error('Error fetching member', error);
    throw error;
  }
};

export const patchMember = async (adminAccountId: number, name: string): Promise<Member> => {
  try {
    const response: AxiosResponse<Member> = await api.patch(
      `admin-account/${adminAccountId}/change-profile`,
      {
        name: name,
      },
      { _showLoading: true, skipAlert: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    logger.error('Error updating member', error);
    throw error;
  }
};

export const patchMemberPassword = async (
  adminAccountId: number,
  password: string,
  newPassword: string
): Promise<Member> => {
  try {
    const response: AxiosResponse<Member> = await api.patch(
      `admin-account/${adminAccountId}/change-password`,
      {
        password: password,
        newPassword: newPassword,
      },
      { _showLoading: true, skipAlert: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    logger.error('Error updating member password', error);
    throw error;
  }
};

export const getAdminCount = async (serviceId: number): Promise<number> => {
  try {
    const response: AxiosResponse<number> = await api.get(`services/${serviceId}/admin-count`);
    return response.data;
  } catch (error) {
    logger.error('Error fetching admin count', error);
    throw error;
  }
};

// 공통

export const getTermsList = async (serviceId: number): Promise<Page<Terms>> => {
  try {
    const response: AxiosResponse<Page<Terms>> = await api.get(`/services/${serviceId}/terms/`, {
      params: {
        page: 1,
        pageSize: 999,
      },
      _showLoading: true,
    } as CustomAxiosRequestConfig);
    return response.data;
  } catch (error) {
    logger.error('Error fetching terms', error);
    throw error;
  }
};

export const getTerms = async (serviceId: number, termsId: number): Promise<TermsDetail> => {
  try {
    const response: AxiosResponse<TermsDetail> = await api.get(
      `/services/${serviceId}/terms/${termsId}`,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    logger.error('Error fetching term', error);
    throw error;
  }
};

export const getPresignedUrlAndUploadFile = async (
  dirPath: string,
  file: File
): Promise<string> => {
  try {
    const response: AxiosResponse<{
      url: string;
      fields: Record<string, string>;
      fileRealPath: string;
    }> = await api.get('presigned-upload-parameter', {
      params: {
        dirPath: dirPath,
        fileName: file.name,
        contentType: file.type,
      },
    });
    const { url, fields, fileRealPath } = response.data;

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);
    await axios.post(url, formData);

    return fileRealPath;
  } catch (error) {
    logger.error('Error fetching presigned URL', error);
    throw error;
  }
};

// CMS Only

export const getServices = async (): Promise<Page<Service>> => {
  try {
    const response: AxiosResponse<Page<Service>> = await api.get('services/', {
      params: {
        page: 1,
        pageSize: 1,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching services', error);
    throw error;
  }
};

export const getService = async (serviceId: number): Promise<Service> => {
  try {
    const response: AxiosResponse<Service> = await api.get(`/services/${serviceId}`, {
      skipAlert: true,
    } as CustomAxiosRequestConfig);
    return response.data;
  } catch (error) {
    console.error('Error fetching service', error);
    throw error;
  }
};

export const patchServiceImageUrl = async (
  serviceId: number,
  imageUrl: string
): Promise<Service> => {
  try {
    const response: AxiosResponse<Service> = await api.patch(
      `/services/${serviceId}/image-url`,
      {
        imageUrl: imageUrl,
      },
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error updating service image URL', error);
    throw error;
  }
};

export const deleteServiceImageUrl = async (serviceId: number): Promise<Service> => {
  try {
    const response: AxiosResponse<Service> = await api.delete(`/services/${serviceId}/image-url`, {
      _showLoading: true,
    } as CustomAxiosRequestConfig);
    return response.data;
  } catch (error) {
    console.error('Error deleting service image URL', error);
    throw error;
  }
};

export const getPlatformVersions = async (
  serviceId: number,
  platformName: string
): Promise<Page<Version>> => {
  try {
    const response: AxiosResponse<Page<Version>> = await api.get(
      `/services/${serviceId}/versions/`,
      {
        params: {
          platform: platformName,
          page: 1,
          pageSize: 999,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching platform versions', error);
    throw error;
  }
};

export const getPlatformVersion = async (
  serviceId: number,
  versionId: number
): Promise<Version> => {
  try {
    const response: AxiosResponse<Version> = await api.get(
      `/services/${serviceId}/versions/${versionId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching platform version', error);
    throw error;
  }
};

export const putPlatformVersion = async (
  serviceId: number,
  versionId: number,
  versionForm: VersionForm
): Promise<Version> => {
  try {
    const response: AxiosResponse<Version> = await api.put(
      `/services/${serviceId}/versions/${versionId}`,
      versionForm,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error updating platform version', error);
    throw error;
  }
};

export const postPlatformVersion = async (
  serviceId: number,
  versionForm: VersionForm
): Promise<Version> => {
  try {
    const response: AxiosResponse<Version> = await api.post(
      `/services/${serviceId}/versions`,
      versionForm,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error creating platform version', error);
    throw error;
  }
};

export const deletePlatformVersion = async (
  serviceId: number,
  versionId: number
): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/versions/${versionId}`, {
      _showLoading: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting platform version', error);
    throw error;
  }
};

export const deletePlatformVersions = async (
  serviceId: number,
  versionIds: number[]
): Promise<void> => {
  try {
    const queryString = versionIds.map((id) => `ids=${id}`).join('&');
    await api.delete(`/services/${serviceId}/versions?${queryString}`, {
      _showLoading: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting platform versions', error);
    throw error;
  }
};

export const putTerms = async (
  serviceId: number,
  termsId: number,
  terms: TermsForm
): Promise<TermsDetail> => {
  try {
    const response: AxiosResponse<TermsDetail> = await api.put(
      `/services/${serviceId}/terms/${termsId}`,
      terms,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error updating term', error);
    throw error;
  }
};

export const postTerms = async (serviceId: number, terms: TermsForm): Promise<TermsDetail> => {
  try {
    const response: AxiosResponse<TermsDetail> = await api.post(
      `/services/${serviceId}/terms`,
      terms,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error creating term', error);
    throw error;
  }
};

export const deleteTerms = async (serviceId: number, termsId: number): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/terms/${termsId}`, {
      _showLoading: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting term', error);
    throw error;
  }
};

export const deleteTermsList = async (serviceId: number, termsIds: number[]): Promise<void> => {
  try {
    const queryString = termsIds.map((id) => `ids=${id}`).join('&');
    await api.delete(`/services/${serviceId}/terms?${queryString}`, {
      _showLoading: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting terms', error);
    throw error;
  }
};

export const getTermsName = async (serviceId: number): Promise<TermsName[]> => {
  try {
    const response: AxiosResponse<TermsName[]> = await api.get(
      `/services/${serviceId}/terms-history/name/`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching terms names', error);
    throw error;
  }
};

export const getTermsHistoryList = async (
  serviceId: number,
  termsId: number
): Promise<Page<Terms>> => {
  try {
    const response: AxiosResponse<Page<Terms>> = await api.get(
      `/services/${serviceId}/terms-history/`,
      {
        params: {
          termsId: termsId,
          page: 1,
          pageSize: 999,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching terms history', error);
    throw error;
  }
};

export const getTermsHistotyDetail = async (
  serviceId: number,
  terms_history_id: number
): Promise<TermsDetail> => {
  try {
    const response: AxiosResponse<TermsDetail> = await api.get(
      `/services/${serviceId}/terms-history/${terms_history_id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching terms history detail', error);
    throw error;
  }
};

export const getAdminList = async (serviceId: number): Promise<Page<ServiceMember>> => {
  try {
    const response: AxiosResponse<Page<ServiceMember>> = await api.get(
      `/services/${serviceId}/admin/`,
      {
        params: {
          page: 1,
          pageSize: 999,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching admin list', error);
    throw error;
  }
};

export const getAdmin = async (serviceId: number, adminId: number): Promise<ServiceMember> => {
  try {
    const response: AxiosResponse<ServiceMember> = await api.get(
      `/services/${serviceId}/admin/${adminId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching admin', error);
    throw error;
  }
};

export const postInviteAdmin = async (
  serviceId: number,
  emailId: string
): Promise<ServiceMember> => {
  try {
    const response: AxiosResponse<ServiceMember> = await api.post(
      `/services/${serviceId}/admin/invite`,
      {
        emailId: emailId,
        loginId: emailId,
      },
      {
        _showLoading: true,
        successMessages: '초대가 완료되었습니다.',
      } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error inviting admin', error);
    throw error;
  }
};

export const postResendInviteAdmin = async (serviceId: number, adminId: number): Promise<void> => {
  try {
    await api.post(`/services/${serviceId}/admin/${adminId}/re-invite`, {
      _showLoading: true,
      successMessages: '초대가 완료되었습니다.',
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error resending invite admin', error);
    throw error;
  }
};

export const deleteAdmin = async (serviceId: number, adminId: number): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/admin/${adminId}`, {
      _showLoading: true,
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting admin', error);
    throw error;
  }
};

export const deleteInviteAdmin = async (serviceId: number, adminId: number): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/admin/${adminId}/cancel-invitation`, {
      _showLoading: true,
      successMessages: '취소가 완료되었습니다.',
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting invite admin', error);
    throw error;
  }
};

export const putAdmin = async (
  serviceId: number,
  adminId: number,
  data: MemberForm
): Promise<ServiceMember> => {
  try {
    const response: AxiosResponse<ServiceMember> = await api.put(
      `/services/${serviceId}/admin/${adminId}`,
      data,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error updating admin', error);
    throw error;
  }
};

export const patchServiceAdminImageUrl = async (
  serviceId: number,
  adminId: number,
  imageUrl: string
): Promise<ServiceMember> => {
  try {
    const response: AxiosResponse<ServiceMember> = await api.patch(
      `/services/${serviceId}/admin/${adminId}/image-url`,
      {
        imageUrl: imageUrl,
      },
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error updating admin image URL', error);
    throw error;
  }
};

export const deleteServiceAdminImageUrl = async (
  serviceId: number,
  adminId: number
): Promise<ServiceMember> => {
  try {
    const response: AxiosResponse<ServiceMember> = await api.delete(
      `/services/${serviceId}/admin/${adminId}/image-url`,
      { _showLoading: true } as CustomAxiosRequestConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting admin image URL', error);
    throw error;
  }
};

export const getUsers = async (
  serviceId: number,
  search?: string,
  joinTypes?: string[],
  status?: string[],
  joinedStartAt?: string,
  joinedEndAt?: string,
  reportCounts?: number,
  ids?: number[]
): Promise<Page<User>> => {
  try {
    const idsQueryString = ids?.map((id) => `${id}`).join('&');
    const joinTypesQueryString = joinTypes?.map((joinType) => `${joinType}`).join('&');
    const statusesQueryString = status?.map((status) => `${status}`).join('&');
    const response: AxiosResponse<Page<User>> = await api.get(`/services/${serviceId}/members/`, {
      params: {
        page: 1,
        pageSize: 999,
        search: search,
        joinedStartAt: joinedStartAt,
        joinedEndAt: joinedEndAt,
        joinTypes: joinTypesQueryString,
        status: statusesQueryString,
        reportCounts: reportCounts,
        ids: idsQueryString,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching members', error);
    throw error;
  }
};

export const getUser = async (serviceId: number, userId: number): Promise<UserDetail> => {
  try {
    const response: AxiosResponse<UserDetail> = await api.get(
      `/services/${serviceId}/members/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching member', error);
    throw error;
  }
};

export const deleteWithdrawUser = async (serviceId: number, userId: number): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/members/${userId}/withdraw`, {
      _showLoading: true,
      successMessages: '탈퇴가 완료되었습니다.',
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error deleting user', error);
    throw error;
  }
};

export const deleteResetPassword = async (serviceId: number, memberId: number): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/members/${memberId}/reset-password`, {
      _showLoading: true,
      successMessages: '비밀번호 초기화가 완료되었습니다.',
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error resetting password', error);
    throw error;
  }
};

export const deleteResetImage = async (serviceId: number, memberId: number): Promise<void> => {
  try {
    await api.delete(`/services/${serviceId}/members/${memberId}/reset-image`, {
      _showLoading: true,
      successMessages: '프로필 이미지 초기화가 완료되었습니다.',
    } as CustomAxiosRequestConfig);
  } catch (error) {
    console.error('Error resetting image', error);
    throw error;
  }
};

export const getMembersSimpleItems = async (
  serviceId: number,
  search?: string
): Promise<Page<UserSearch>> => {
  try {
    const response: AxiosResponse<Page<UserSearch>> = await api.get(
      `/services/${serviceId}/members/simple-items/`,
      {
        params: {
          page: 1,
          pageSize: 999,
          search: search,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching members', error);
    throw error;
  }
};

export const getExcel = async (
  serviceId: number,
  depth: string,
  fileName: string,
  ids: number[]
): Promise<void> => {
  try {
    const idsQueryString = ids.map((id) => `ids=${id}`).join('&');
    const url = `/services/${serviceId}/${depth}/download-excel?filename=${fileName}&${idsQueryString}`;

    const response = await api.get(url, {
      responseType: 'blob',
    });

    // 응답 데이터를 사용하여 Blob 생성
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Blob으로부터 Object URL 생성
    const downloadUrl = window.URL.createObjectURL(blob);

    // 가상의 a 태그를 만들어 파일 다운로드 처리
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName); // 파일 이름 설정
    document.body.appendChild(link);
    link.click();

    // 다운로드 후 Object URL 및 가상의 a 태그 제거
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading terms excel', error);
    throw error;
  }
};
