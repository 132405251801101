import styled from 'styled-components';
import colors from '../../components/common/colors';

export interface IntroMainLayoutProps {
  $showGNB: boolean;
  $background?: boolean;
}

export const IntroMainLayoutStyle = styled.div<IntroMainLayoutProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: ${(props) =>
    props.$background
      ? 'linear-gradient(180deg, #F8F5EB 0%, #FFF4F0 62.01%, #EBF5FF 100%)'
      : colors.background_light_100};

  > main {
    width: 100%;
    max-width: 1140px;
    padding-top: ${(props) => (props.$showGNB ? '84px' : '0px')} !important;

    @media (max-width: 768px) {
      width: 100%;
      padding-top: 64px !important;
      padding: 16px;
    }
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
  }
`;
