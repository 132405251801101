import React, { useState } from 'react';
import { GnbDropdownMenuStyle } from './GnbDropdownMenuStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import { LNB } from './LNB';

interface GnbDropdownMenuProps {
  menuTitle: string;
  menuItems?: { label: string; route: string }[];
  route_info?: string;
}

const GnbDropdownMenu: React.FC<GnbDropdownMenuProps> = ({ menuTitle, menuItems, route_info }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [lnbOpen, setLnbOpen] = useState(false);

  const isCurrentRoute = (route: string, correct: boolean = false) => {
    const pattern = new RegExp('\\/cms\\/[^\\/]+\\/([^\\/]+)(\\/|$)');
    const match = location.pathname.match(pattern);

    return match && match[1]
      ? correct || route === ''
        ? match[1] === route
        : match[1].startsWith(route)
      : !match && route === '';
  };
  const isSelected = route_info
    ? isCurrentRoute(route_info)
    : (menuItems ?? []).some((item) => isCurrentRoute(item.route));

  const handleMenuClick = () => {
    if (route_info) {
      navigate(route_info);
    } else if (menuItems && menuItems.length > 0) {
      setLnbOpen(!lnbOpen);
    }
  };

  const lnbMenuItems = menuItems?.map((item) => ({
    label: item.label,
    onClick: () => navigate(item.route),
    active: isCurrentRoute(item.route),
  }));

  return (
    <GnbDropdownMenuStyle
      className={`primarymenu`}
      icon={<i className={`righticon fa-solid fa-angle-down`} />}
      route_info={route_info}
      text={menuTitle}
      item
      selected={isSelected}
      onClick={handleMenuClick}
    >
      {lnbMenuItems && <LNB menuItems={lnbMenuItems} setOpen={setLnbOpen} open={lnbOpen} />}
    </GnbDropdownMenuStyle>
  );
};

export default GnbDropdownMenu;
