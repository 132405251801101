import React, { useEffect, useRef, useState } from 'react';
import { FilterContainer, FilterDropDownStyle } from './FilterDropDownStyle';
import { DropdownMenu } from 'semantic-ui-react';
import { CommonButton } from '../button/CommonButton';
import { ColorEnum, SizeEnum } from '../common/enums';

interface FilterRangeProps {
  name: string;
  min?: number;
  max?: number;
  onChange: (max: number) => void;
}

export const FilterRange: React.FC<FilterRangeProps> = ({
  name,
  min = 0,
  max = 1000,
  onChange,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [select, setSelect] = useState(min);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setSelect(newValue);
    onChange(newValue);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FilterContainer
      ref={ref}
      $active={select > min}
      tabIndex={0}
      onClick={() => {
        setIsOpened(true);
      }}
    >
      {name}
      <FilterDropDownStyle
        open={isOpened}
        clearable
        compact
        inline
        multiple
        onClick={() => {
          setIsOpened(true);
        }}
        closeOnChange={select > min}
        text={select > min ? `\u00A0:\u00A0${min} ~ ${select}` : ''}
      >
        <DropdownMenu className='fit-drop'>
          <input
            step={50}
            type='range'
            min={min}
            max={max}
            value={select}
            onChange={handleChange}
          />
          <div className='bottom'>
            <CommonButton
              onClick={() => {
                setSelect(min);
                onChange(min);
              }}
              disabled={select === min}
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='필터 지우기'
            />
          </div>
        </DropdownMenu>
      </FilterDropDownStyle>
      <i className='icon fas fa-caret-down' />
    </FilterContainer>
  );
};
