import styled from 'styled-components';
import { ListItem, ListHeader } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';

export const HeaderStyle = styled(ListHeader)`
  &&& {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0px;
    color: ${colors.text_active} !important;
    ${font.body_s_s_strong(true)};

    a {
      color: ${colors.text_link} !important;
    }
  }
`;

export const HeaderListItemStyle = styled(ListItem)`
  &&& {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto !important;
    padding: 0px !important;
    width: 100%;
    border-radius: 0px;
    color: ${colors.text_active} !important;
    ${font.body_s_s_regular(true)};

    .content {
      padding: 0px !important;
      margin: 0px !important;
    }

    .description {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      padding-top: 4px !important;
      .content {
        overflow: hidden;
        display: flex;
        .text {
          align-self: center;
        }
      }
      .icon {
        color: ${colors.icon_default} !important;
        margin: 0px !important;
        padding-right: 8px !important;
        font-size: 12px !important;
        font-family: 'Font Awesome 6 Free';
        width: auto !important;
        height: auto !important;
      }
      .ui.image {
        align-self: flex-start !important;
        object-fit: contain !important;
        height: auto !important;
        margin-right: 8px !important;
        display: inline-block !important;
      }
      .ui.label {
        &:nth-child(n + 1):not(:last-child) {
          margin-right: 4px !important;
        }
      }
      .ui.button {
        justify-content: flex-end !important;
        margin-left: 8px !important;
      }
    }

    .icon {
      padding: 0px 8px 0px 0px !important;
      color: ${colors.icon_default} !important;
      margin: 0px !important;
      font-size: 12px !important;
      font-family: 'Font Awesome 6 Free';
      width: auto !important;
      height: auto !important;
    }

    .link {
      cursor: pointer;
      text-decoration-line: underline;
      color: ${colors.text_link} !important;
    }
  }
`;
