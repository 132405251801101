import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CMSDetailLayout from '../../../../layouts/cms/CMSDetailLayout';
import { CommonButton } from '../../../../components/button/CommonButton';
import {
  SizeEnum,
  ColorEnum,
  PlatformEnum,
  PlatformDisplayName,
} from '../../../../components/common/enums';
import { PlatformDetailStyle } from './PlatformDetailPageStyle';
import { HeaderListItem } from '../../../../components/list/HeaderListItem';
import { getPlatformVersion } from '../../../../api/NoitApi';
import { Version } from '../../../../api/models/ServiceModels';
import { convertDateFormat } from '../../../../components/common/utils';
import { InfoView } from '../../../../components/view/InfoView';
import { Images } from '../../../../assets/images/images';
import { VersionPopup } from './VersionPopup';
import { deletePlatformVersion } from '../../../../api/NoitApi';
import { useDialog } from '../../../../contexts/DialogContext';
import { useService } from '../../../../contexts/ServiceContext';
import { useAuthorization } from '../../../../hooks/useAuthorization';

const PlatformDetailPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { service } = useService();
  const editAuth = useAuthorization('VERSION_EDIT', true);
  const deleteAuth = useAuthorization('VERSION_DELETE', true);
  const { openDialog, closeDialog, openAuthDialog } = useDialog();

  const query = new URLSearchParams(location.search);
  const title = PlatformDisplayName(query.get('title') ?? '');

  const { versionId } = useParams<{ versionId: string }>();
  const [version, setVersion] = useState<Version | null>(null);

  useEffect(() => {
    if (!service) {
      return;
    }

    const fetchVersion = async () => {
      if (!service) {
        return;
      }
      try {
        const versionIdNum = parseInt(versionId!, 10);
        const serviceId = service.id;

        const versionData = await getPlatformVersion(serviceId, versionIdNum);
        setVersion(versionData);
      } catch (error) {
        console.error('Error fetching platform version data', error);
      }
    };

    fetchVersion();
  }, [service, versionId]);

  const copyUrlToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
    } catch (err) {
      console.error('클립보드에 복사하는데 실패했습니다.', err);
    }
  };

  const getVersionHistory = () => {
    if (!version) {
      return [];
    }

    const history = [convertDateFormat(version.createdAt) + ' 추가됨'];
    if (version.updatedAt !== version.createdAt) {
      history.push(
        convertDateFormat(version.updatedAt) + ' 업데이트(' + version.updatedBy.name + ')'
      );
    }
    return history;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleDeleteVersion = async () => {
    if (!service || !version) {
      return;
    }

    try {
      await deletePlatformVersion(service.id, version.id);
      navigate(-1);
    } catch (error) {
      console.error('Error deleting terms', error);
    }
  };

  return (
    <>
      <CMSDetailLayout
        title={title}
        actions={
          <>
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='편집'
              onClick={() => {
                if (editAuth) {
                  openAuthDialog('버전 편집', editAuth);
                  return;
                }
                setIsPopupOpen(true);
              }}
            />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='삭제'
              onClick={() => {
                if (!version) {
                  return;
                }
                if (deleteAuth) {
                  openAuthDialog('버전 삭제', deleteAuth);
                  return;
                }
                openDialog({
                  title: `${title} 버전 삭제`,
                  content: `${version.name} 버전을 삭제하시겠습니까?`,
                  onConfirm: () => {
                    handleDeleteVersion();
                    closeDialog();
                  },
                });
              }}
            />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='링크 복사'
              onClick={copyUrlToClipboard}
            />
          </>
        }
      />
      <PlatformDetailStyle>
        <div className='contents'>
          <HeaderListItem header='버전명' children={version?.name ?? ''} />

          <HeaderListItem header='업데이트 내용' children={version?.note ?? ''} />
          {title.toUpperCase() !== PlatformEnum.WEB && (
            <>
              <HeaderListItem
                header='업데이트 유무'
                children={version?.forceUpdateFlag ? '업데이트 필수' : '업데이트 선택'}
              />
              <HeaderListItem
                header='업데이트 알림 일시'
                children={version?.alertAt ? convertDateFormat(version?.alertAt) : '-'}
              />
            </>
          )}
        </div>
        <InfoView history={getVersionHistory()}>
          <HeaderListItem header='플랫폼' children={title} />
          <HeaderListItem
            image={Images.icon_profile_default}
            header='작성자'
            children={version?.createdBy.name ?? ''}
          />
        </InfoView>
      </PlatformDetailStyle>
      <VersionPopup
        platform={title.toUpperCase()}
        data={version}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        onConfirm={(version) => {
          setVersion(version);
        }}
      />
    </>
  );
};

export default PlatformDetailPage;
