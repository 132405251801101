import React from 'react';
import { HeaderListItemStyle, HeaderStyle } from './HeaderListItemStyle';
import { ListContent, ListDescription, ListIcon, Image, ListItemProps } from 'semantic-ui-react';

interface HeaderListItemProps extends ListItemProps {
  image?: string;
  headericon?: string;
  header?: React.ReactNode;
  labels?: React.ReactNode;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
}

export const HeaderListItem: React.FC<HeaderListItemProps> = ({
  image,
  headericon,
  header,
  children,
  labels,
  buttons,
  ...props
}) => {
  return (
    <HeaderListItemStyle {...props}>
      {headericon && (
        <ListIcon>
          <i className={headericon} />
        </ListIcon>
      )}
      <ListContent>
        {header && <HeaderStyle>{header}</HeaderStyle>}
        <ListDescription>
          <div className='content'>
            {image && <Image src={image} />}
            {labels}
            <div className='text'>{children}</div>
          </div>
          {buttons}
        </ListDescription>
      </ListContent>
    </HeaderListItemStyle>
  );
};
