import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';

export const LNBStyle = styled(Dropdown.Menu)`
  &&& {
    display: flex !important;
    position: absolute;
    width: 200px;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    margin-top: -8px;
    border-radius: 4px;
    border: none;
    background: ${colors.background_light_100} !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2) !important;

    &&& > .item {
      cursor: pointer;
      display: flex !important;
      padding: 8px 12px !important;
      align-items: center;
      align-self: stretch;
      background: ${colors.background_light_100} !important;
      color: ${colors.text_active} !important;
      ${font.body_s_s_regular(true)};

      &:hover {
        background: ${colors.bl_opacity_5} !important;
      }
    }
    &&& > .active.item {
      display: flex;
      padding: 8px 12px !important;
      align-items: center;
      align-self: stretch;
      color: initial !important;
      background: ${colors.bl_opacity_5} !important;
      font-weight: initial !important;
    }
  }
`;
