import React from 'react';
import { SubMenuLayoutStyle, SubMenu, SubMenuList } from './SubMenuLayoutStyle';

interface SunMenu {
  title: string;
  isActivated: boolean;
  onClick?: () => void;
}

type SubMenuLayoutProps = {
  children: React.ReactNode;
  title: string;
  subMenu: SunMenu[];
};

const SubMenuLayout: React.FC<SubMenuLayoutProps> = ({ children, title, subMenu }) => {
  return (
    <SubMenuLayoutStyle>
      <SubMenu>
        <div className='sub-menu-title'>{title}</div>
        {subMenu.map((menu, index) => {
          return (
            <SubMenuList key={index} $isActivated={menu.isActivated} onClick={menu.onClick}>
              {menu.title}
            </SubMenuList>
          );
        })}
      </SubMenu>
      {children}
    </SubMenuLayoutStyle>
  );
};

export default SubMenuLayout;
