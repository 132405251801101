import styled from 'styled-components';
import { ListItem } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';

export const ListItemStyle = styled(ListItem)`
  &&& {
    display: flex;
    align-items: center;
    height: auto !important;
    padding: 8px 12px !important;
    gap: 8px;
    width: 100%;
    border-radius: 0px;
    color: ${colors.text_active} !important;
    ${font.body_s_s_regular(true)};

    &:hover {
      background: ${colors.bl_opacity_5} !important;
    }
    .icon {
      color: ${colors.icon_default} !important;
      margin: 0px !important;
      font-size: 12px !important;
      font-family: 'Font Awesome 6 Free';
      width: auto !important;
      height: auto !important;
    }
    a {
      color: ${colors.text_link} !important;
    }
  }
`;
