import { createSlice } from '@reduxjs/toolkit';

const apiSlice = createSlice({
  name: 'api',
  initialState: {
    isLoading: false,
    error: null,
  },
  reducers: {
    apiCallBegan: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    apiCallSuccess: (state) => {
      state.isLoading = false;
    },
    apiCallFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { apiCallBegan, apiCallSuccess, apiCallFailed } = apiSlice.actions;

export default apiSlice.reducer;
