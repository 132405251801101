import React, { useEffect } from 'react';
import { TabStyle } from '../../../components/tab/TabStyle';
import { getTermsName } from '../../../api/NoitApi';
import { TermsName } from '../../../api/models/ServiceModels';
import TermsHistoryList from './TermsHistoryList';
import { useService } from '../../../contexts/ServiceContext';

const TermsHistoryPage: React.FC = () => {
  let { service } = useService();
  const [termsName, setTermsName] = React.useState<TermsName[]>([]);
  useEffect(() => {
    if (service) {
      getTermsName(service.id).then((response) => {
        setTermsName(response);
      });
    }
  }, [service]);

  return (
    <TabStyle
      menu={{ secondary: true, pointing: true }}
      panes={termsName.map((terms, index) => {
        return {
          menuItem: { key: `${terms.termsId}-${index}`, content: terms.name },
          render: () => (
            <TermsHistoryList key={`${terms.termsId}-${index}`} termsId={terms.termsId} />
          ),
        };
      })}
    />
  );
};

export default TermsHistoryPage;
