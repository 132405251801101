import { parseISO, format, parse, startOfDay, formatISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ko } from 'date-fns/locale';
import { SHA512 } from 'crypto-js';
import logger from './logger';

export function convertDateFormat(
  dateString: string | null,
  outputFormat: string = 'yyyy-MM-dd a hh:mm'
): string {
  if (!dateString) {
    return '';
  }
  try {
    return format(DateFormatToDate(dateString), outputFormat, { locale: ko });
  } catch (error) {
    logger.error('날짜 변환 오류:', error);
    return '';
  }
}

export function DateFormatToDate(dateString: string): Date {
  try {
    const date = parseISO(dateString);
    const timeZone = 'Asia/Seoul';
    const zonedDate = utcToZonedTime(date, timeZone);
    return zonedDate;
  } catch (error) {
    logger.error('날짜 변환 오류:', error);
    return new Date();
  }
}

export function convertDate(date: Date, outputFormat: string = 'yyMMdd_HHmmss'): string {
  return format(date, outputFormat, { locale: ko });
}

export const extractDate = (datetimeStr: string) => {
  try {
    const datePart = datetimeStr.split(' ')[0];
    const date = parse(datePart, 'yyyy-MM-dd', new Date());
    return startOfDay(date);
  } catch (error) {
    logger.error('날짜 변환 오류:', error);
    return null;
  }
};

export const convertToISOSting = (date: Date, isEndDate: boolean = false) => {
  try {
    const timeZone = 'Asia/Seoul';
    if (isEndDate) {
      date.setHours(23, 59, 59, 999);
    }
    const zonedDate = zonedTimeToUtc(date, timeZone);
    return formatISO(zonedDate);
  } catch (error) {
    logger.error('날짜 변환 오류:', error);
    return null;
  }
};

export const convertDateToISOSting = (dateString: string) => {
  try {
    const timeZone = 'Asia/Seoul';
    const zonedDate = zonedTimeToUtc(dateString, timeZone);
    return formatISO(zonedDate);
  } catch (error) {
    logger.error('날짜 변환 오류:', error);
    return null;
  }
};

export function convertToISOString(datetimeStr: string): string | null {
  try {
    let [datePart, ampm, timePart] = datetimeStr.split(' ');
    let [hours, minutes] = timePart.split(':');

    if (ampm === '오전' && parseInt(hours) === 12) {
      hours = '00';
    }

    // 오후 시간 조정
    if (ampm === '오후' && parseInt(hours) < 12) {
      hours = (parseInt(hours) + 12).toString();
    }

    // Date 객체 생성 (한국 시간대 적용)
    const dateTime = `${datePart} ${hours}:${minutes}:00`;
    const timeZone = 'Asia/Seoul';
    const zonedDate = zonedTimeToUtc(dateTime, timeZone);

    // ISO 8601 형식으로 변환
    return formatISO(zonedDate);
  } catch (error) {
    logger.error('ISO 형식 변환 오류:', error);
    return null;
  }
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export const maskEmail = (email: string): string =>
  email.replace(/^(..)(.*)(?=@)/, (_, p1, p2) => p1 + '*'.repeat(p2.length));

export function isValidPassword(password: string): boolean {
  const minLengthRegex = /^.{8,}$/; // 최소 8글자
  const containsLetterRegex = /[A-Za-z]/; // 영문자 포함
  const containsDigitRegex = /\d/; // 숫자 포함
  const containsSpecialCharRegex = /[^A-Za-z0-9]/; // 특수기호 포함

  // 모든 조건을 충족하는지 확인
  return (
    minLengthRegex.test(password) &&
    containsLetterRegex.test(password) &&
    containsDigitRegex.test(password) &&
    containsSpecialCharRegex.test(password)
  );
}

export function encryptPassword(password: string): string {
  return SHA512(password).toString();
}
