import styled from 'styled-components';
import { font } from '../../../components/common/font';
import colors from '../../../components/common/colors';
import { wrapTextStyle } from '../../../components/common/text';

export const TermsDetailPageStyle = styled.div`
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;

  .detail-content {
    overflow: hidden;
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    ${font.body_s_s_strong(true)};
    color: ${colors.text_active} !important;

    .detail {
      overflow: hidden;
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid ${colors.bl_opacity_10};
      background: ${colors.background_light_100};
      .text {
        ${wrapTextStyle} !important;
        overflow-y: auto;
        flex: 1 0 0;
        align-self: stretch;
        ${font.body_s_s_regular(true)};
        color: ${colors.text_active} !important;
      }
    }
  }

  .item {
    .description {
      .content {
        .label {
          width: auto !important;
        }
      }
    }
  }
  .version {
    .content {
      color: ${colors.text_primary_dark} !important;
    }
  }
`;
