import React, { createContext, useState, useContext, useCallback } from 'react';
import { AlertTypeEnum } from '../components/common/enums';

interface AlertContextType {
  showAlert: boolean;
  alertMessage: string;
  alertType: AlertTypeEnum;
  triggerAlert: (message: { msg: string; type: AlertTypeEnum }) => void;
  closeAlert: () => void;
}

const AlertContext = createContext<AlertContextType>({
  showAlert: false,
  alertMessage: '',
  alertType: AlertTypeEnum.SUCCESS,
  triggerAlert: () => {},
  closeAlert: () => {},
});

export const useAlert = () => useContext(AlertContext);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alertState, setAlertState] = useState({
    showAlert: false,
    alertMessage: '',
    alertType: AlertTypeEnum.SUCCESS,
  });
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const triggerAlert = useCallback(
    ({ msg, type }: { msg: string; type: AlertTypeEnum }) => {
      if (timerId) clearTimeout(timerId);
      setAlertState({ showAlert: true, alertMessage: msg, alertType: type });

      const newTimerId = setTimeout(() => {
        setAlertState((state) => ({ ...state, showAlert: false }));
      }, 3000);
      setTimerId(newTimerId);
    },
    [timerId]
  );

  const closeAlert = useCallback(() => {
    if (timerId) clearTimeout(timerId);
    setAlertState((state) => ({ ...state, showAlert: false }));
  }, [timerId]);

  return (
    <AlertContext.Provider value={{ ...alertState, triggerAlert, closeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
