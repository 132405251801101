interface Colors {
  [key: string]: string;
}

const colors: Colors = {
  brand_dark_01: '#201600',
  brand_dark_02: '#513A0C',
  brand_dark_03: '#936B31',
  brand_dark_04: '#D09731',
  brand_dark_05: '#F1B434',
  brand_light_01: '#FFDC9B',
  brand_light_02: '#FDE7BE',
  brand_light_03: '#FEF0D7',
  brand_light_04: '#FFF6E8',
  brand_light_05: '#FEFAF0',
  brand_primary: '#FFCA5A',
  primary_opacity_10: '#FFCA5A1A',

  brand_secondary: '#9050E9',
  brand_third: '#FE9339',
  brand_fourth: '#1B96FF',

  neutral_0: '#000000',
  neutral_10: '#181818',
  neutral_20: '#2E2E2E',
  neutral_30: '#444444',
  neutral_40: '#5C5C5C',
  neutral_50: '#747474',
  neutral_60: '#939393',
  neutral_70: '#AEAEAE',
  neutral_80: '#C9C9C9',
  neutral_90: '#E5E5E5',
  neutral_95: '#F9F9F9',
  neutral_100: '#FFFFFF',

  red_10: '#300C01',
  red_20: '#640103',
  red_30: '#8E030F',
  red_40: '#BA0517',
  red_50: '#EA001E',
  red_60: '#FE5C4C',
  red_70: '#FE8F7D',
  red_80: '#FEB8AB',
  red_90: '#FEDED8',
  red_95: '#FEF1EE',

  pink_10: '#370114',
  pink_20: '#61022A',
  pink_30: '#8A033E',
  pink_40: '#B60554',
  pink_50: '#E3066A',
  pink_60: '#FF538A',
  pink_70: '#FE8AA7',
  pink_80: '#FDB6C5',
  pink_90: '#FDDDE3',
  pink_95: '#FEF0F3',

  yellow_10: '#281202',
  yellow_20: '#4F2100',
  yellow_30: '#6F3400',
  yellow_40: '#8C4B02',
  yellow_50: '#A86403',
  yellow_60: '#CA8501',
  yellow_70: '#E4A201',
  yellow_80: '#FCC003',
  yellow_90: '#F9E3B6',
  yellow_95: '#FBF3E0',

  orange_10: '#201600',
  orange_20: '#3E2B02',
  orange_30: '#5F3E02',
  orange_40: '#825101',
  orange_50: '#A96404',
  orange_60: '#DD7A01',
  orange_70: '#FE9339',
  orange_80: '#FFBA90',
  orange_90: '#FEDFD0',
  orange_95: '#FFF1EA',

  green_10: '#071B12',
  green_20: '#1C3326',
  green_30: '#194E31',
  green_40: '#396547',
  green_50: '#2E844A',
  green_60: '#3BA755',
  green_70: '#45C65A',
  green_80: '#91DB8B',
  green_90: '#CDEFC4',
  green_95: '#EBF7E6',

  teal_10: '#071B12',
  teal_20: '#023434',
  teal_30: '#024D4C',
  teal_40: '#056764',
  teal_50: '#0B827C',
  teal_60: '#06A59A',
  teal_70: '#01C3B3',
  teal_80: '#04E1CB',
  teal_90: '#ACF3E4',
  teal_95: '#DEF9F3',

  blue_10: '#001639',
  blue_20: '#032D60',
  blue_30: '#014486',
  blue_40: '#0B5CAB',
  blue_50: '#0176D3',
  blue_60: '#1B96FF',
  blue_70: '#78B0FD',
  blue_80: '#AACBFF',
  blue_90: '#D8E6FE',
  blue_95: '#EEF4FF',

  purple_10: '#240643',
  purple_20: '#401075',
  purple_30: '#5A1BA9',
  purple_40: '#7526E3',
  purple_50: '#9050E9',
  purple_60: '#AD7BEE',
  purple_70: '#C29EF1',
  purple_80: '#D7BFF2',
  purple_90: '#ECE1F9',
  purple_95: '#F6F2FB',

  bl_opacity_100: '#000000',
  bl_opacity_90: '#000000E5',
  bl_opacity_80: '#000000CC',
  bl_opacity_70: '#000000B3',
  bl_opacity_60: '#00000099',
  bl_opacity_50: '#00000080',
  bl_opacity_40: '#00000066',
  bl_opacity_30: '#0000004D',
  bl_opacity_20: '#00000033',
  bl_opacity_10: '#0000001A',
  bl_opacity_5: '#0000000D',

  w_opacity_100: '#FFFFFF',
  w_opacity_90: '#FFFFFFE5',
  w_opacity_80: '#FFFFFFCC',
  w_opacity_70: '#FFFFFFB3',
  w_opacity_60: '#FFFFFF99',
  w_opacity_50: '#FFFFFF80',
  w_opacity_40: '#FFFFFF66',
  w_opacity_30: '#FFFFFF4D',
  w_opacity_20: '#FFFFFF33',
  w_opacity_10: '#FFFFFF1A',
  w_opacity_5: '#FFFFFF0D',
};

colors.text_white = colors.w_opacity_100;
colors.text_active = colors.neutral_30;
colors.text_active_light = colors.neutral_50;
colors.text_placeholder = colors.neutral_70;
colors.text_focus = colors.neutral_60;
colors.text_disabled = colors.neutral_70;
colors.text_primary = colors.brand_primary;
colors.text_primary_dark = colors.brand_dark_04;
colors.text_secondary = colors.brand_secondary;
colors.text_error = colors.red_50;
colors.text_link = colors.blue_50;
colors.text_link_active = colors.blue_20;
colors.text_link_disabled = colors.blue_15;
colors.text_label_default = colors.neutral_30;
colors.text_label_disabled = colors.neutral_70;
colors.text_success = colors.green_70;

colors.line_black_opacity_10 = colors.bl_opacity_10;
colors.line_black_90 = colors.neutral_90;
colors.line_black_70 = colors.neutral_70;
colors.line_error = colors.red_50;
colors.line_success = colors.green_70;
colors.line_primary = colors.brand_dark_05;

colors.background_light_100 = colors.neutral_100;
colors.background_light_95 = colors.neutral_95;
colors.background_light_90 = colors.neutral_90;
colors.background_dark_40 = colors.neutral_40;
colors.background_brand_dark = colors.orange_10;

colors.icon_default = colors.neutral_20;
colors.icon_light = colors.neutral_60;
colors.icon_disabled = colors.neutral_70;
colors.icon_input_default = colors.neutral_70;
colors.icon_error = colors.red_50;
colors.icon_success = colors.green_70;
colors.icon_white = colors.neutral_100;

export default colors;
