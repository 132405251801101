import styled from 'styled-components';
import { font } from '../../../components/common/font';
import colors from '../../../components/common/colors';
import { ellipsisStyle } from '../../../components/common/text';

export const MemberPopupStyle = styled.div`
  .popup-layout-container {
    width: 1000px !important;
  }
`;

export const MemberDetailPageStyle = styled.div`
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  .member-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;

    .profile-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      ${font.body_s_s_strong()};

      .profile-top {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        > .text {
          flex: 1 0 0;
        }
      }

      .box {
        display: flex;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        align-self: stretch;

        border-radius: 8px;
        border: 1px solid ${colors.line_black_opacity_10};

        .name {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .etc-text {
          ${font.body_xs_xs_regular()};
          color: ${colors.text_active_light};
        }

        > .section {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 24px;
          align-self: stretch;

          > .item {
            flex: 1 0 0;
          }

          > .line {
            width: 1px !important;
            height: 44px;
            background: ${colors.line_black_opacity_10};
          }
        }
      }
    }

    .auth-info {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      align-self: stretch;
    }
  }
`;

export const AuthTableStyle = styled.div`
  max-width: calc(50% - 4px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${colors.line_black_opacity_10};

  .auth-title {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid ${colors.line_black_opacity_10};
    background: ${colors.background_light_95};

    color: ${colors.text_active};
    ${font.body_s_s_strong()};
  }

  .auth-type {
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    color: ${colors.text_active};
    ${font.body_s_s_regular()};

    .auth {
      max-width: calc(25% - 6px);
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
    }
  }
  .auth-text {
    ${ellipsisStyle};
  }
`;
