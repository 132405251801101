import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import colors from '../common/colors';

export const CommonButtonStyle = styled(Button)`
  &&& {
    cursor: pointer;
    margin: 0px;
    border-radius: 4px;
    color: ${(props) => props.colorinfo.color};
    width: auto;
    height: ${(props) => props.sizeinfo.height}px;
    padding: 0px ${(props) => props.sizeinfo.padding}px;
    background: ${(props) =>
      props.colorinfo.border_color ? 'none' : props.colorinfo.active_color} !important;
    border: ${(props) =>
      props.colorinfo.border_color ? '1px solid ' + props.colorinfo.border_color : 'none'};
    box-shadow: none !important;
    ${(props) => props.sizeinfo.font(true)};

    i {
      width: ${(props) => props.sizeinfo.font.fontSize} !important;
      height: ${(props) => props.sizeinfo.font.fontSize} !important;
      margin-left: 0px !important;
      margin-right: 8px !important;
      opacity: 1 !important;
      font-family: 'Font Awesome 6 Free' !important;
      color: ${(props) => props.colorinfo.color} !important;
    }
    &:hover {
      color: ${(props) => props.colorinfo.color};
      background: ${(props) =>
        props.colorinfo.border_color ? 'none' : props.colorinfo.hover_color} !important;
      border: ${(props) =>
        props.colorinfo.border_color ? '1px solid ' + props.colorinfo.hover_color : 'none'};
    }
    &:active {
      color: ${(props) => props.colorinfo.color};
      background: ${(props) =>
        props.colorinfo.border_color ? 'none' : props.colorinfo.selected_color} !important;
      border: ${(props) =>
        props.colorinfo.border_color ? '1px solid ' + props.colorinfo.selected_color : 'none'};
    }
    &:disabled {
      color: ${colors.text_disabled} !important;
      opacity: 1 !important;
      background: ${(props) =>
        props.colorinfo.border_color ? 'none' : colors.neutral_90} !important;
      border: ${(props) =>
        props.colorinfo.border_color ? '1px solid ' + colors.neutral_80 : 'none'};
    }
  }
`;
