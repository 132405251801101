import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Loader } from 'semantic-ui-react';

const LoadingContainer = styled.div`
  top: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999 !important;
  align-items: center !important;
`;

const LoadingIndicator: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.api.isLoading);

  return isLoading ? (
    <LoadingContainer>
      <Loader active />
    </LoadingContainer>
  ) : null;
};

export default LoadingIndicator;
