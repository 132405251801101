import React, { useCallback, useEffect, useState } from 'react';
import { useEvent } from '../../../contexts/AddButtonContext';
import {
  getAdminList,
  postInviteAdmin,
  deleteAdmin,
  deleteInviteAdmin,
  postResendInviteAdmin,
} from '../../../api/NoitApi';
import { ServiceMember } from '../../../api/models/MemberModels';
import { useDialog } from '../../../contexts/DialogContext';
import { BoxTableContainer, BoxTableHeader } from '../../../components/table/TableStyle';
import { MembersListCell } from './MembersListPageStyle';
import { Image } from 'semantic-ui-react';
import { Images } from '../../../assets/images/images';
import { CommonLabel } from '../../../components/label/CommonLabel';
import { ColorEnum, SizeEnum, StatusEnum } from '../../../components/common/enums';
import { MoreMenu } from '../../../components/menu/MoreMenu';
import { InputStatuses } from '../../../hooks/useInputStatusManager';
import { isValidEmail } from '../../../components/common/utils';
import { useService } from '../../../contexts/ServiceContext';
import * as ROUTES from '../../../routes/Routes';
import { useNavigate } from 'react-router-dom';
import { useAuthorization } from '../../../hooks/useAuthorization';

export const MembersPage: React.FC = () => {
  let { service } = useService();
  const [members, setMembers] = useState<ServiceMember[]>([]);

  const refreshMembers = useCallback(() => {
    if (service) {
      getAdminList(service.id).then((res) => {
        setMembers(res.items);
      });
    }
  }, [service]);

  useEffect(() => {
    if (!service) return;

    refreshMembers();
  }, [service, refreshMembers]);

  const { openDialog, closeDialog, openAuthDialog } = useDialog();
  const { eventTriggered, resetEvent, setButtonText } = useEvent();

  const initioalData = (): InputStatuses => {
    return {
      이메일: {
        required: true,
        value: '',
        status: StatusEnum.DEFAULT,
        customValidation: (value: string) => {
          if (!value) return '이메일을(를) 입력해 주세요.';
          if (!isValidEmail(value)) return '이메일 형식이 올바르지 않습니다.';
          return null;
        },
      },
    };
  };

  useEffect(() => {
    setButtonText('멤버 초대');
    return () => {
      setButtonText(null);
    };
  }, [setButtonText]);

  const [openInvite, setOpenInvite] = useState(false);
  const isInviteAuthorized = useAuthorization('ADMIN_ADD');
  const deleteAuth = useAuthorization('ADMIN_DELETE');

  const openInviteDialog = useCallback(() => {
    if (!service || openInvite) return;
    setOpenInvite(true);
    if (isInviteAuthorized) {
      openAuthDialog('멤버 초대', isInviteAuthorized, () => {
        setOpenInvite(false);
      });
    } else {
      openDialog({
        title: '멤버 초대',
        subtitle: (
          <>
            <span className='point'>{members.length}</span>/{service.numberOfAdmin}
          </>
        ),
        content:
          members.length >= service.numberOfAdmin
            ? '초대 멤버 수가 초과되어 더 이상 멤버를 추가할 수 없습니다.\n멤버 삭제 또는 NOIT 매니저에게 멤버 추가를 문의해 주세요.'
            : '초대할 멤버의 이메일 주소를 입력해 주세요.\n입력하신 메일로 초대 메일이 발송됩니다.',
        initialValue: initioalData(),
        confirmText: '초대하기',
        status: members.length >= service.numberOfAdmin ? StatusEnum.DISABLED : StatusEnum.DEFAULT,
        onConfirm(value) {
          if (value && service && value['이메일'].value) {
            postInviteAdmin(service.id, value['이메일'].value as string).then((val) => {
              setMembers([...members, val]);
              closeDialog();
              setOpenInvite(false);
            });
          }
        },
        onCancel: () => setOpenInvite(false),
      });
    }
  }, [
    openDialog,
    service,
    closeDialog,
    members,
    setOpenInvite,
    openInvite,
    isInviteAuthorized,
    openAuthDialog,
  ]);

  useEffect(() => {
    if (eventTriggered) {
      openInviteDialog();
      resetEvent();
    }
  }, [eventTriggered, openInviteDialog, resetEvent]);

  const getMoreMenuItems = (member: ServiceMember) => {
    let items = [
      {
        label: !member.joinedFlag ? '초대 취소' : '멤버 삭제',
        onClick: () => {
          if (deleteAuth) {
            openAuthDialog(!member.joinedFlag ? '초대 취소' : '멤버 삭제', deleteAuth);
            return;
          }
          openDialog({
            title: !member.joinedFlag ? '초대 취소' : '멤버 삭제',
            content: !member.joinedFlag
              ? '초대를 취소하시겠습니까?\n초대 취소 시 기존 초대 코드는 사용할 수 없으며, 취소 안내 메일이 발송됩니다.'
              : `${member.name} 멤버를 삭제하시겠습니까?`,
            onConfirm: () => {
              if (!member.joinedFlag && service) {
                deleteInviteAdmin(service.id, member.id).then(() => {
                  closeDialog();
                  setMembers(members.filter((m) => m.id !== member.id));
                });
              } else {
                if (service) {
                  deleteAdmin(service.id, member.id).then(() => {
                    closeDialog();
                    setMembers(members.filter((m) => m.id !== member.id));
                  });
                }
              }
            },
          });
        },
      },
    ];
    if (!member.joinedFlag) {
      items.push({
        label: '초대 메일 재발송',
        onClick: () => {
          if (!service) return;
          if (isInviteAuthorized) {
            openAuthDialog('멤버 초대', isInviteAuthorized, () => {
              setOpenInvite(false);
            });
            return;
          }
          postResendInviteAdmin(service.id, member.id)
            .then(() => {
              closeDialog();
            })
            .catch(() => {
              refreshMembers();
            });
        },
      });
    }
    return items;
  };

  const navigate = useNavigate();
  const goToDetailPage = (member: ServiceMember) => {
    if (!member.joinedFlag) return;
    navigate(ROUTES.MEMBER_DETAIL(member.id));
  };

  return (
    <>
      <BoxTableContainer>
        <BoxTableHeader>
          <div className='header'>
            멤버 수 :
            <span>
              <span className='point'>{members.length} </span>
              <span className='count_total'>/ {service && service.numberOfAdmin}</span>
            </span>
          </div>
        </BoxTableHeader>
        <div className='box'>
          {members.map((member) => (
            <MembersListCell
              key={member.id}
              onClick={() => {
                goToDetailPage(member);
              }}
            >
              <div className='role'>{member.roleName}</div>
              <div className='profile'>
                <Image src={member.imageUrl ? member.imageUrl : Images.icon_profile_default} />
                <span className='profile-name'>{member.name}</span>
                {!member.joinedFlag && (
                  <CommonLabel
                    sizeinfo={SizeEnum.S}
                    colorinfo={ColorEnum.GREEN}
                    children='초대중'
                  />
                )}
              </div>
              <div className='note'>{member.emailId}</div>
              {!member.managerFlag && <MoreMenu menuItems={getMoreMenuItems(member)} />}
            </MembersListCell>
          ))}
        </div>
      </BoxTableContainer>
    </>
  );
};

export default MembersPage;
