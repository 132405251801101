import React, { useState } from 'react';
import { useMember } from '../../contexts/MemberContext';
import { MemberDetailPageStyle } from './member/MemberDetailPageStyle';
import { HistoryStyle, ProfileImageHistoryStyle } from '../../components/view/InfoViewStyle';
import { HeaderListItem } from '../../components/list/HeaderListItem';
import { Image } from 'semantic-ui-react';
import { Images } from '../../assets/images/images';
import { convertDateFormat } from '../../components/common/utils';
import { ColorEnum, SizeEnum } from '../../components/common/enums';
import { CommonButton } from '../../components/button/CommonButton';
import MemberPopup from './member/MemberPopup';

const ProfilePage: React.FC = () => {
  const { serviceMember, setServiceMember } = useMember();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const getHistory = () => {
    if (!serviceMember) return [];

    const history = [];
    if (serviceMember.account.latestActiveAt) {
      history.push(`${convertDateFormat(serviceMember.account.latestActiveAt)} 로그인`);
    }

    if (serviceMember.invitedAt) {
      history.push(`${convertDateFormat(serviceMember.invitedAt)} 초대됨`);
    }

    if (serviceMember.updatedAt && serviceMember.updatedBy) {
      history.push(
        `${convertDateFormat(serviceMember.updatedAt)} 업데이트(${serviceMember.updatedBy.name})`
      );
    }

    return history;
  };

  return (
    <>
      <MemberDetailPageStyle>
        <ProfileImageHistoryStyle>
          <div className='profile-image-container'>
            <Image src={serviceMember?.imageUrl ?? Images.img_profile_default_big} />
          </div>
          <HistoryStyle>
            {getHistory().map((history, index) => (
              <div key={index}>{history}</div>
            ))}
          </HistoryStyle>
        </ProfileImageHistoryStyle>
        <div className='member-info'>
          <div className='profile-info'>
            <div className='profile-top'>
              <div className='text'>프로필</div>
              <CommonButton
                sizeinfo={SizeEnum.XS}
                colorinfo={ColorEnum.BORDERGRAY}
                content='편집'
                onClick={() => {
                  setIsPopupOpen(true);
                }}
              />
            </div>
            <div className='box'>
              <HeaderListItem header='KEY' children={serviceMember?.id ?? ''} />
              <HeaderListItem header='이름' children={serviceMember?.account.name ?? ''} />
              <HeaderListItem
                header='표시 이름'
                children={
                  <div className='name'>
                    {serviceMember?.name ?? ''}
                    <div className='etc-text'>
                      *표시 이름은 실제 이름이거나 해당 백오피스 에서 불리고 싶은 별명일 수
                      있습니다.
                    </div>
                  </div>
                }
              />
              <HeaderListItem header='역할명' children={serviceMember?.roleName ?? ''} />

              <HeaderListItem header='이메일' children={serviceMember?.emailId ?? ''} />
            </div>
          </div>
        </div>
      </MemberDetailPageStyle>
      {serviceMember && (
        <MemberPopup
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          data={serviceMember}
          onlyInfo={true}
          onConfirm={(member) => {
            setServiceMember(member);
          }}
        />
      )}
    </>
  );
};

export default ProfilePage;
