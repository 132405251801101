import React, { useMemo, useState } from 'react';
import { LoginStyle } from './LoginStyle';
import { ClickableImage } from '../../../components/EtcStyle';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { Images } from '../../../assets/images/images';
import { CommonInput } from '../../../components/input/CommonInput';
import { CommonButton } from '../../../components/button/CommonButton';
import { ColorEnum, SizeEnum, StatusEnum } from '../../../components/common/enums';
import { isValidEmail, maskEmail } from '../../../components/common/utils';
import { InputStatuses, useInputStatusManager } from '../../../hooks/useInputStatusManager';
import { postAdmin } from '../../../api/NoitApi';
import { AxiosError } from 'axios';
import { NoitError } from '../../../api/models/CommonModels';
import logger from '../../../components/common/logger';

const JoinStep1: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [subError, setSubError] = useState<string | null>(null);

  const initioalData = useMemo((): InputStatuses => {
    return {
      이메일: {
        required: true,
        value: '',
        customValidation: (value: string) => {
          if (!value) return '이메일을(를) 입력해 주세요.';
          if (!isValidEmail(value)) return '이메일 형식이 올바르지 않습니다.';
          return null;
        },
      },
    };
  }, []);

  const { inputStatuses, updateValue, setStatus, checkAllValid } =
    useInputStatusManager(initioalData);

  const errorStr = () => {
    if (inputStatuses['이메일'].status === StatusEnum.ERROR) {
      return inputStatuses['이메일'].errorMessage;
    }

    return error;
  };

  const validate = () => {
    setError(null);
    setSubError(null);

    if (!checkAllValid()) {
      return;
    }

    const email = inputStatuses['이메일'].value as string;
    postAdmin(email)
      .then(() => {
        navigate(ROUTES.JOIN_EMAIL(email));
      })
      .catch((error: AxiosError<NoitError>) => {
        logger.log('error', error);
        switch (error.response?.data.code) {
          case 'ALREADY_JOINED_ADMIN_ACCOUNT':
            setError(`${maskEmail(email)}은 이미 가입된 계정입니다.`);
            updateValue('이메일', '');
            break;
          default:
            setError('이메일 전송에 실패했습니다.');
            setSubError('잠시 후 다시 시도해 주세요.');
            break;
        }
      });
  };

  return (
    <LoginStyle>
      <ClickableImage src={Images.intro_logo_black} onClick={() => navigate(ROUTES.HOME)} />
      <div className='content'>
        <div className='title'>
          <div className='primary'>계정 생성</div>
          이메일을 입력해 주세요
        </div>
        {errorStr() && (
          <div className='error'>
            {errorStr()}
            {subError && <div className='sub'>{subError}</div>}
          </div>
        )}
        <div className='box'>
          <CommonInput
            name='이메일'
            value={inputStatuses['이메일'].value}
            status={inputStatuses['이메일'].status}
            setStatus={setStatus}
            placeholder='name@email.com'
            onDataChange={(value) => {
              updateValue('이메일', value as string);
            }}
          />

          <CommonButton
            sizeinfo={SizeEnum.M}
            colorinfo={ColorEnum.PRIMARY}
            content='다음'
            onClick={validate}
          />
        </div>

        <div className='join' onClick={() => navigate(ROUTES.LOGIN)}>
          이미 NOIT을 사용중이신가요?
          <span className='create'>로그인</span>
        </div>
      </div>
    </LoginStyle>
  );
};
export default JoinStep1;
