import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

export const CommonLabelStyle = styled(Label)`
  margin: 0px !important;
  padding: 0px ${(props) => props.sizeinfo.padding}px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center !important;
  height: ${(props) => props.sizeinfo.height}px !important;
  border: none !important;
  border-radius: 4px !important;
  ${(props) => props.sizeinfo.font(true)};
  color: ${(props) => props.colorinfo.color} !important;
  background: ${(props) => props.colorinfo.background} !important;

  .lefticon {
    cursor: pointer;
    width: ${(props) => props.sizeinfo.font.fontSize}px !important;
    height: ${(props) => props.sizeinfo.font.fontSize}px !important;
    margin-left: 0px !important;
    margin-right: 8px !important;
    opacity: 1 !important;
    font-family: 'Font Awesome 6 Free' !important;
    color: ${(props) => props.colorinfo.color} !important;
  }
  .righticon {
    cursor: pointer;
    width: ${(props) => props.sizeinfo.font.fontSize} !important;
    height: ${(props) => props.sizeinfo.font.fontSize} !important;
    margin-left: 8px !important;
    margin-right: 0px !important;
    opacity: 1 !important;
    font-family: 'Font Awesome 6 Free' !important;
    color: ${(props) => props.colorinfo.deleteColor} !important;
  }
`;
