import ic_email_checked from './ic_email_checked.png';
import img_profile_default_big from './img_profile_default_big.png';
import intro_logo_black from './intro_logo_black.png';
import icon_profile_default from './icon_profile_default.png';
import icon_alert_success from './icon_alert_success.png';
import icon_alert_error from './icon_alert_error.png';
import img_backoff_default from './img_backoff_default.png';
import icon_snb_dashboard from './icon_snb_dashboard.png';
import icon_snb_terms from './icon_snb_terms.png';
import icon_snb_user from './icon_snb_user.png';
import img_service_default from './img_service_default.png';

export const Images = {
  ic_email_checked,
  img_profile_default_big,
  intro_logo_black,
  icon_profile_default,
  icon_alert_success,
  icon_alert_error,
  img_backoff_default,
  icon_snb_dashboard,
  icon_snb_terms,
  icon_snb_user,
  img_service_default,
};
