import styled from 'styled-components';
import colors from './common/colors';
import { Checkbox, Image } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

export const CommonCheckboxStyle = styled(Checkbox)`
  &&& {
    display: block;
    min-width: 16px;
    min-height: 16px;
    input {
      display: none !important;
    }
    input:checked ~ .box:before,
    input:checked ~ label:before {
      background-color: ${(props) =>
        props.toggle ? colors.brand_primary : colors.w_opacity_100} !important;
    }
  }
`;

export const ClickableImage = styled(Image)`
  &&& {
    background-color: transparent;
    position: relative;
    cursor: pointer;
  }
`;

export const DatePickerStyle = styled(DatePicker)`
  .custom-datepicker .react-datepicker__input-container input {
    border: none; /* 테두리 제거 */
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .ui.input {
    caret-color: transparent;
  }
`;
