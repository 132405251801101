import React, { useRef } from 'react';
import { useDialog } from '../../contexts/DialogContext';
import { ImageContainer, ButtonContainer } from './ImageUploadContainerStyle';
import { Image } from 'semantic-ui-react';
import { CommonButton } from '../../components/button/CommonButton';
import { SizeEnum, ColorEnum, AlertTypeEnum } from '../../components/common/enums';
import { getPresignedUrlAndUploadFile } from '../../api/NoitApi';
import { Images } from '../../assets/images/images';
import { useAlert } from '../../contexts/AlertContext';

interface ImageUploadContainerProps {
  dirPath: string;
  imageUrl?: string | null;
  defaultImageUrl?: string;
  maxFileSize?: number;
  buttonText?: string;
  onImageUploadSuccess?: (uploadedImageUrl: string) => void;
  onImageDelete?: () => void;
}

export const ImageUploadContainer: React.FC<ImageUploadContainerProps> = ({
  dirPath,
  imageUrl,
  defaultImageUrl,
  maxFileSize = 5 * 1024 * 1024, // 5MB
  buttonText,
  onImageUploadSuccess,
  onImageDelete,
}) => {
  const { triggerAlert } = useAlert();
  const { openDialog, closeDialog } = useDialog();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? event.target.files[0] : null;

    if (newFile) {
      if (newFile.size > maxFileSize) {
        // 파일 크기가 제한보다 클 경우
        triggerAlert({
          msg: maxFileSize / 1024 / 1024 + 'MB 이하로 첨부해 주세요.',
          type: AlertTypeEnum.ERROR,
        });
        event.target.value = ''; // input의 선택을 리셋
      } else {
        // 파일 크기가 적절할 경우 upload
        try {
          if (onImageUploadSuccess) {
            const uploadedImageUrl = await getPresignedUrlAndUploadFile(dirPath, newFile);
            onImageUploadSuccess(uploadedImageUrl);
            event.target.value = '';
          }
        } catch (error) {
          // 업로드 실패 시 오류 처리
          console.error('Error uploading file:', error);
          triggerAlert({
            msg: '파일 업로드를 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
            type: AlertTypeEnum.ERROR,
          });
          event.target.value = '';
        }
      }
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <ImageContainer>
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept='image/*'
      />
      <Image src={imageUrl ?? defaultImageUrl ?? Images.img_profile_default_big} />
      <ButtonContainer>
        {imageUrl ? (
          <>
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='편집'
              icon='fa-regular fa-pen-to-square'
              onClick={handleFileInputClick}
            />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='삭제'
              icon='fa-regular fa-trash-can'
              onClick={() => {
                if (onImageDelete) {
                  openDialog({
                    title: '이미지 삭제',
                    content: '이미지를 삭제하시겠습니까?',
                    onConfirm: () => {
                      onImageDelete();
                      closeDialog();
                    },
                  });
                }
              }}
            />
          </>
        ) : (
          <CommonButton
            sizeinfo={SizeEnum.XS}
            colorinfo={ColorEnum.BORDERGRAY}
            content={buttonText ?? '이미지 추가'}
            onClick={handleFileInputClick}
          />
        )}
      </ButtonContainer>
    </ImageContainer>
  );
};
