import styled from 'styled-components';
import colors from '../common/colors';
import { font } from '../common/font';
import { Input, Button, Icon } from 'semantic-ui-react';
import { StatusEnum } from '../common/enums';

interface CommonInputStyleProps {
  status: StatusEnum;
}

export const CommonInputStyle = styled(Input)<CommonInputStyleProps>`
  &&& {
    display: inline-flex !important;
    width: auto;
    height: 40px;
    align-items: center !important;
    border: 1px solid ${(props) => props.border_color} !important;
    border-top-left-radius: ${(props) => props.leading_radius}px !important;
    border-bottom-left-radius: ${(props) => props.leading_radius}px !important;
    border-top-right-radius: ${(props) => props.trailing_radius}px !important;
    border-bottom-right-radius: ${(props) => props.trailing_radius}px !important;
    background: ${(props) =>
      props.disabled || props.status === StatusEnum.DISABLED
        ? colors.background_light_95
        : colors.background_light_100} !important;

    > input {
      flex: 1 1 auto !important;
      outline: none !important;
      border-radius: 4px !important;
      background: ${(props) =>
        props.disabled || props.status === StatusEnum.DISABLED
          ? colors.background_light_95
          : colors.background_light_100} !important;
      width: auto;
      height: 100% !important;
      padding: ${(props) =>
        props.left_icon
          ? props.icon
            ? '0px !important'
            : '0px 12px 0px 0px !important'
          : props.icon
            ? '0px 0px 0px 12px !important'
            : '0px 12px !important'};
      border: none !important;
      color: ${(props) =>
        props.disabled || props.status === StatusEnum.DISABLED
          ? colors.text_disabled
          : colors.text_active} !important;
      ${font.body_m_m_regular(true)};
    }
    &.disabled {
      opacity: 1 !important;
    }
    ::placeholder {
      color: ${(props) => props.placeholder_color} !important;
      opacity: 1;
    }
    ::-ms-input-placeholder {
      color: ${(props) => props.placeholder_color} !important;
    }
    ::-ms-input-placeholder {
      color: ${(props) => props.placeholder_color} !important;
    }
  }
`;

export const ButtonStyle = styled(Button)`
  &&& {
    border-radius: 4px !important;
    color: ${(props) => props.icon_color} !important;
    margin: 0px 8px !important;
    width: auto !important;
    height: auto !important;
    padding: 4px !important;
    border: none !important;
    box-shadow: none !important;
    icon {
      color: ${(props) => props.icon_color} !important;
    }
    &:active {
      background: ${colors.bl_opacity_10} !important;
    }
    &:hover {
      background: ${colors.bl_opacity_10} !important;
    }
  }
`;

export const IconStyle = styled(Icon)`
  &&& {
    align-items: center !important;
    text-align: center !important;
    border-radius: 4px !important;
    position: initial !important;
    opacity: 1 !important;
    color: ${(props) => props.icon_color} !important;
    width: auto !important;
    height: auto !important;
    margin: 0px 8px !important;
    padding: 4px !important;
    &:active {
      background: ${colors.bl_opacity_10} !important;
    }
    &:hover {
      background: ${colors.bl_opacity_10} !important;
    }
  }
`;
