import React, { useEffect, useState } from 'react';
import TitleDescLayout from '../../../layouts/TitleDescLayout';
import { AlertStyle, MyBackOfficeStyle } from './MyBackOfficeStyle';
import { Image } from 'semantic-ui-react';
import { useAuth } from '../../../contexts/AuthContext';
import { Member, ServiceMember } from '../../../api/models/MemberModels';
import { Images } from '../../../assets/images/images';
import { getAdminCount, getMember } from '../../../api/NoitApi';
import { CommonLabel } from '../../../components/label/CommonLabel';
import { AlertTypeEnum, ColorEnum, SizeEnum } from '../../../components/common/enums';
import * as ROUTES from '../../../routes/Routes';
import { useDialog } from '../../../contexts/DialogContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from '../../../contexts/AlertContext';

const MyBackOffice: React.FC = () => {
  const [account, setAccount] = useState<Member | null>(null);
  const { myInfo } = useAuth();
  const [cntList, setCntList] = useState<number[]>([]);
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const location = useLocation();
  const { triggerAlert } = useAlert();

  const alert = location.state?.alert;

  useEffect(() => {
    if (alert === 'serviceNotFound') {
      triggerAlert({
        msg: '가입되지 않은 서비스입니다. \n확인 후 다시 시도해 주세요.',
        type: AlertTypeEnum.ERROR,
      });
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [alert, location.pathname, navigate, triggerAlert]);

  useEffect(() => {
    if (!myInfo) return;
    getMember(myInfo.id).then((res) => {
      setAccount(res);
    });
  }, [myInfo]);

  useEffect(() => {
    if (account?.profiles) {
      const countPromises = account?.profiles.map((profile) => getAdminCount(profile.service.id));

      Promise.all(countPromises).then((results) => {
        setCntList(results);
      });
    }
  }, [account?.profiles]);

  const getInviteService = () => {
    if (account) {
      return account.profiles.find((profile) => !profile.latestActiveAt && !profile.joinedFlag);
    }
    return null;
  };

  return (
    <TitleDescLayout title='나의 백오피스' desc='아래 백 오피스를 선택하여 서비스를 관리해 보세요!'>
      {getInviteService() && (
        <AlertStyle>
          <div className='alert-icon'>🔔</div>
          <div className='alert-text'>
            {account?.name ?? ''} 님, 새로운 서비스에 초대되었어요, 이메일을 확인해 주세요.
          </div>
        </AlertStyle>
      )}
      <MyBackOfficeStyle>
        {account &&
          account.profiles.map((profile: ServiceMember, index) => (
            <div
              className='mybackoffice-item'
              key={profile.id}
              onClick={() => {
                if (!profile.joinedFlag) {
                  openDialog({
                    title: `${profile.service.name.korean} 서비스 가입`,
                    content: '가입되지 않은 서비스입니다. 이메일을 확인해 주세요.',
                    isConfirmOnly: true,
                    onConfirm: () => {
                      closeDialog();
                    },
                  });
                  return;
                }
                navigate(ROUTES.CMS(profile.service.id));
              }}
            >
              <div className='mybackoffice-info'>
                <Image src={profile.service.imageUrl ?? Images.img_backoff_default} />
                <div className='info-text'>
                  {profile.service.name.korean}
                  <div className='sub'>{`${cntList[index] ?? 0}명의 멤버`}</div>
                </div>
              </div>
              <div className='mybackoffice-icon'>
                {!profile.latestActiveAt && (
                  <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.RED} children='NEW' />
                )}
                <i className='arrow fa-solid fa-arrow-right' />
              </div>
            </div>
          ))}
      </MyBackOfficeStyle>
    </TitleDescLayout>
  );
};

export default MyBackOffice;
