import React from 'react';
import colors from '../common/colors';
import { StatusEnum } from '../common/enums';
import { CommonTextAreaStyle } from './CommonTextAreaStyle';
import { TextAreaProps } from 'semantic-ui-react';
import { useInputStatusManagerProps } from '../../hooks/useInputStatusManager';

interface CommonTextAreaProps extends TextAreaProps, useInputStatusManagerProps {}

export const CommonTextArea: React.FC<CommonTextAreaProps> = ({
  name,
  setStatus,
  status = StatusEnum.DEFAULT,
  onDataChange,
  ...props
}) => {
  const getStatusColors = () => {
    switch (status) {
      case StatusEnum.FOCUS:
        return {
          border_color: colors.line_primary,
          placeholder_color: colors.text_focus,
        };
      case StatusEnum.ERROR:
        return {
          border_color: colors.line_error,
        };
      case StatusEnum.SUCCESS:
        return {
          border_color: colors.line_success,
        };
      default:
        return {
          border_color: colors.line_black_90,
          placeholder_color: colors.text_placeholder,
        };
    }
  };

  const handleFocus = () => {
    if (status === StatusEnum.DEFAULT && setStatus != null && name != null) {
      setStatus(name, StatusEnum.FOCUS);
    }
  };

  const { border_color, placeholder_color } = getStatusColors();
  return (
    <CommonTextAreaStyle
      border_color={border_color}
      placeholder_color={placeholder_color}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onDataChange != null) {
          onDataChange(e.target.value);
        }
      }}
      onFocus={handleFocus}
      onBlur={() => {
        if (
          (status === StatusEnum.FOCUS || StatusEnum.DEFAULT) &&
          setStatus != null &&
          name != null
        )
          setStatus(name, StatusEnum.DEFAULT);
      }}
      {...props}
    />
  );
};
