import { useLocation } from 'react-router-dom';
import { match } from 'path-to-regexp';
import { ButtonContainer, BreadcrumbStyle, BreadContainer } from './BreadcrumbsStyle';
import { CMSRouteConfig } from '../../routes/RouteConfig';
import { SizeEnum, ColorEnum } from '../common/enums';
import { CommonButton } from '../button/CommonButton';
import { useEvent } from '../../contexts/AddButtonContext';
import { useService } from '../../contexts/ServiceContext';

const Breadcrumbs = () => {
  const location = useLocation();
  const pattern = new RegExp('\\/cms\\/[^\\/]+\\/([^\\/]+)(\\/|$)');
  const path = location.pathname.match(pattern);

  const { triggerEvent, buttonText } = useEvent();

  const findRoute = () => {
    for (const route of CMSRouteConfig) {
      const matchRoute = match(route.path, { decode: decodeURIComponent });
      if (path && path[1] && matchRoute(path[1])) {
        return route;
      }
    }
    return null;
  };

  const currentRoute = findRoute();
  let { service } = useService();

  return (
    <ButtonContainer>
      <BreadContainer>
        {currentRoute && currentRoute.name && (
          <BreadcrumbStyle>
            <BreadcrumbStyle.Section>{service?.name.korean ?? ''}</BreadcrumbStyle.Section>
            <BreadcrumbStyle.Divider />
            {currentRoute.section && (
              <>
                <BreadcrumbStyle.Section>{currentRoute.section}</BreadcrumbStyle.Section>
                <BreadcrumbStyle.Divider />
              </>
            )}
            <BreadcrumbStyle.Section active>{currentRoute.name}</BreadcrumbStyle.Section>
          </BreadcrumbStyle>
        )}
        {currentRoute && currentRoute.name ? currentRoute.name : (service?.name.korean ?? '')}
      </BreadContainer>
      {buttonText && (
        <CommonButton
          onClick={triggerEvent}
          sizeinfo={SizeEnum.L}
          colorinfo={ColorEnum.PRIMARY}
          content={buttonText}
        />
      )}
    </ButtonContainer>
  );
};

export default Breadcrumbs;
