import styled from 'styled-components';
import colors from '../../../components/common/colors';
import { font } from '../../../components/common/font';

export const MyInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  .my-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: ${colors.text_active};
    ${font.body_s_s_strong()};
    .profile-info {
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid ${colors.line_black_opacity_10};
      background: ${colors.background_light_100};
    }
  }
`;
