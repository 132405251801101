import styled from 'styled-components';
import { BoxTableContainer, BoxTableCell } from '../../../../components/table/TableStyle';

export const PlatformPageContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  max-height: 100%;
`;

export const PlatformContainer = styled(BoxTableContainer)`
  flex: 1 0 0;
  max-height: 100%;
  min-width: 448px;
  align-self: flex-start;
`;

interface PlatformCellProps {
  $smallWidth: boolean;
}

export const PlatformCell = styled(BoxTableCell)<PlatformCellProps>`
  .type {
    width: 158px;
  }
  ${({ $smallWidth }) =>
    $smallWidth &&
    `
        display: flex;
        padding: 12px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        .type {
            width: 100%;
        }
    `}

  .note {
    ${({ $smallWidth }) =>
      $smallWidth &&
      `
            flex: none;
            align-self: stretch;
        `}
  }
`;
