import React from 'react';
import { Image, FormButton } from 'semantic-ui-react';
import { AccordionTitleStyle, AccordionContentStyle } from './SnbFormMenuStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDialog } from '../../contexts/DialogContext';

interface MenuTitleItem {
  title: string;
  image: string;
  route?: string;
  disabled?: string | null;
}

export interface SubMenuItem {
  label: string;
  route: string;
  disabled?: string | null;
}

interface SnbFormMenuProps {
  menuTitleItem: MenuTitleItem;
  subMenuItems: SubMenuItem[];
  isOpen: boolean;
  onMenuToggle: (route: string) => void;
}

const SnbFormMenu: React.FC<SnbFormMenuProps> = ({
  menuTitleItem,
  subMenuItems,
  isOpen,
  onMenuToggle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openAuthDialog } = useDialog();

  const isCurrentRoute = (route: string, correct: boolean = false) => {
    const pattern = new RegExp('\\/cms\\/[^\\/]+\\/([^\\/]+)(\\/|$)');
    const match = location.pathname.match(pattern);

    return match && match[1]
      ? correct || route === ''
        ? match[1] === route
        : match[1].startsWith(route)
      : !match && route === '';
  };
  const isSelected = menuTitleItem
    ? isCurrentRoute(menuTitleItem.route ?? '')
    : subMenuItems.map((item) => isCurrentRoute(item.route));

  const handleMenuClick = (route: string) => {
    onMenuToggle(route);
  };

  return subMenuItems.length > 0 ? (
    <>
      <AccordionTitleStyle
        key={menuTitleItem.route}
        active={isSelected}
        onClick={() => {
          handleMenuClick(subMenuItems[0].route);
        }}
      >
        <Image src={menuTitleItem.image} />
        {menuTitleItem.title}
        <i
          className={
            !isOpen ? `righticon fa-solid fa-caret-right` : `righticon fa-solid fa-caret-down`
          }
        />
      </AccordionTitleStyle>
      <AccordionContentStyle
        active={isOpen}
        content={
          <>
            {subMenuItems.map(({ label, route, disabled }) => (
              <FormButton
                key={label}
                content={
                  <>
                    {label}
                    {disabled && <i className={`righticon fa-solid fa-lock`} />}
                  </>
                }
                active={isCurrentRoute(route, true)}
                onClick={() => {
                  if (disabled) {
                    openAuthDialog(label, disabled);
                    return;
                  }
                  navigate(route);
                }}
                labelPosition='right'
              />
            ))}
          </>
        }
      />
    </>
  ) : (
    <AccordionTitleStyle
      key={menuTitleItem.route}
      active={isSelected}
      onClick={() => {
        handleMenuClick(menuTitleItem.route ?? '');
        navigate(menuTitleItem.route ?? '');
      }}
    >
      <Image src={menuTitleItem.image} />
      {menuTitleItem.title}
      {menuTitleItem.disabled && <i className={`righticon fa-solid fa-lock`} />}
    </AccordionTitleStyle>
  );
};

export default SnbFormMenu;
