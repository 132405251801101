import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useService } from '../../../contexts/ServiceContext';
import { ServiceMember } from '../../../api/models/MemberModels';
import { deleteAdmin, getAdmin } from '../../../api/NoitApi';
import CMSDetailLayout from '../../../layouts/cms/CMSDetailLayout';
import { CommonButton } from '../../../components/button/CommonButton';
import { ColorEnum, SizeEnum } from '../../../components/common/enums';
import { useDialog } from '../../../contexts/DialogContext';
import { AuthTableStyle, MemberDetailPageStyle } from './MemberDetailPageStyle';
import { HistoryStyle, ProfileImageHistoryStyle } from '../../../components/view/InfoViewStyle';
import { Image } from 'semantic-ui-react';
import { Images } from '../../../assets/images/images';
import { convertDateFormat } from '../../../components/common/utils';
import { HeaderListItem } from '../../../components/list/HeaderListItem';
import { CommonCheckboxStyle } from '../../../components/EtcStyle';
import { groupedPermissions, useAuthorization } from '../../../hooks/useAuthorization';
import MemberPopup from './MemberPopup';

const MemberDetailPage: React.FC = () => {
  const navigate = useNavigate();
  let { service } = useService();
  const { adminId } = useParams<{ adminId: string }>();
  const [admin, setAdmin] = useState<ServiceMember | null>(null);
  const { openDialog, closeDialog, openAuthDialog } = useDialog();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const deleteAuth = useAuthorization('ADMIN_DELETE');
  const authEdit = useAuthorization('ADMIN_EDIT');

  useEffect(() => {
    if (!service || !adminId) return;
    getAdmin(service.id, parseInt(adminId, 10)).then((res) => {
      setAdmin(res);
    });
  }, [service, adminId]);

  const handleDelete = async () => {
    if (!admin || !service) {
      return;
    }

    try {
      await deleteAdmin(service.id, admin.id);
      navigate(-1);
    } catch (error) {
      console.error('Error deleting terms', error);
    }
  };

  const getHistory = () => {
    if (!admin) return [];

    const history = [];
    if (admin.account.latestActiveAt) {
      history.push(`${convertDateFormat(admin.account.latestActiveAt)} 로그인`);
    }

    if (admin.invitedAt) {
      history.push(`${convertDateFormat(admin.invitedAt)} 초대됨`);
    }

    if (admin.updatedAt && admin.updatedBy) {
      history.push(`${convertDateFormat(admin.updatedAt)} 업데이트(${admin.updatedBy.name})`);
    }

    return history;
  };

  return (
    <>
      <CMSDetailLayout
        title={admin?.name ?? ''}
        actions={
          <>
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='편집'
              onClick={() => {
                if (authEdit) {
                  openAuthDialog('멤버 편집', authEdit);
                  return;
                }
                setIsPopupOpen(true);
              }}
            />
            {!admin?.managerFlag && (
              <CommonButton
                sizeinfo={SizeEnum.XS}
                colorinfo={ColorEnum.BORDERGRAY}
                content='멤버 삭제'
                onClick={() => {
                  if (!admin || !service) {
                    return;
                  }
                  if (deleteAuth) {
                    openAuthDialog('멤버 삭제', deleteAuth);
                    return;
                  }
                  openDialog({
                    title: '멤버 삭제',
                    content: `${admin.name} 멤버를 삭제하시겠습니까?`,
                    onConfirm: () => {
                      handleDelete();
                      closeDialog();
                    },
                  });
                }}
              />
            )}
          </>
        }
      />
      <MemberDetailPageStyle>
        <ProfileImageHistoryStyle>
          <div className='profile-image-container'>
            <Image src={admin?.imageUrl ?? Images.img_profile_default_big} />
          </div>
          <HistoryStyle>
            {getHistory().map((history, index) => (
              <div key={index}>{history}</div>
            ))}
          </HistoryStyle>
        </ProfileImageHistoryStyle>
        <div className='member-info'>
          <div className='profile-info'>
            프로필
            <div className='box'>
              <HeaderListItem header='KEY' children={adminId ?? ''} />
              <HeaderListItem header='이름' children={admin?.account.name ?? ''} />
              <HeaderListItem
                header='표시 이름'
                children={
                  <div className='name'>
                    {admin?.name ?? ''}
                    <div className='etc-text'>
                      *표시 이름은 실제 이름이거나 해당 백오피스 에서 불리고 싶은 별명일 수
                      있습니다.
                    </div>
                  </div>
                }
              />
              <HeaderListItem header='역할명' children={admin?.roleName ?? ''} />

              <HeaderListItem header='이메일' children={admin?.emailId ?? ''} />
            </div>
          </div>
          <div className='profile-info'>
            권한
            {groupedPermissions.map((group, index) => (
              <div key={index} className='auth-info'>
                {group.map((permission, index) => (
                  <AuthTableStyle key={index}>
                    <div className='auth-title'>{permission.menuName}</div>
                    <div key={index} className='auth-type'>
                      {permission.permissions.map((item, index) => (
                        <div key={index} className='auth'>
                          <CommonCheckboxStyle
                            checked={admin?.authorities.includes(item.key)}
                            disabled
                          />
                          <div className='auth-text'>{item.displayName}</div>
                        </div>
                      ))}
                    </div>
                  </AuthTableStyle>
                ))}
              </div>
            ))}
          </div>
        </div>
      </MemberDetailPageStyle>
      {admin && (
        <MemberPopup
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          data={admin}
          onConfirm={(member) => {
            setAdmin(member);
          }}
        />
      )}
    </>
  );
};

export default MemberDetailPage;
