import React from 'react';
import { Container, InfoViewStyle, HistoryStyle } from './InfoViewStyle';

interface InfoViewProps {
  children?: React.ReactNode;
  history?: string[];
}

export const InfoView: React.FC<InfoViewProps> = ({ children, history }) => {
  return (
    <Container>
      {children && (
        <InfoViewStyle>
          <div className='title'>세부 정보</div>
          <div className='info'>{children}</div>
        </InfoViewStyle>
      )}
      {history && (
        <HistoryStyle>
          {history.map((item, index) => {
            return <div key={index}>{item}</div>;
          })}
        </HistoryStyle>
      )}
    </Container>
  );
};
