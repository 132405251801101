import { ComponentType } from 'react';
import * as ROUTES from './Routes';
import Home from '../pages/intro/Home';
import Login from '../pages/intro/login/Login';
import JoinStep1 from '../pages/intro/login/JoinStep1';
import JoinStep2 from '../pages/intro/login/JoinStep2';
import VerifyJoin from '../pages/intro/login/VerifyJoin';
import MyBackOffice from '../pages/intro/my/MyBackOffice';
import MyInfo from '../pages/intro/my/MyInfo';
import Password from '../pages/intro/my/Password';
import Test from '../pages/Test';
import ServicePage from '../pages/cms/setting/ServicePage';
import PlatformPage from '../pages/cms/setting/platform/PlatformPage';
import TermsListPage from '../pages/cms/terms/TermsListPage';
import TermsHistoryPage from '../pages/cms/terms/TermsHistoryPage';
import MembersPage from '../pages/cms/member/MembersListPage';
import PlatformDetailPage from '../pages/cms/setting/platform/PlatformDetailPage';
import TermsDetailPage from '../pages/cms/terms/TermsDetailPage';
import MemberDetailPage from '../pages/cms/member/MemberDetailPage';
import UserListPage from '../pages/cms/user/UserListPage';
import ProfilePage from '../pages/cms/\bProfilePage';
import WithdrawalUserPage from '../pages/cms/user/WithdrawalUserPage';
import WithdrawalReasonPage from '../pages/cms/user/WithdrawalReasonPage';
import { UserDetailPage } from '../pages/cms/user/UserDetailPage';

type RouteConfigItem = {
  path: string;
  component: ComponentType<any>;
  showGNB?: boolean;
  requiresAuth?: boolean;
  background?: boolean;
  section?: string;
  name?: string;
};

export const IntroRouteConfig: RouteConfigItem[] = [
  // main routes
  { path: ROUTES.HOME, component: Home },
  { path: ROUTES.LOGIN, component: Login, showGNB: false },
  { path: ROUTES.JOIN, component: JoinStep1, showGNB: false },
  { path: `${ROUTES.JOIN}/email`, component: JoinStep2, showGNB: false },
  { path: ROUTES.VERIFY_JOIN, component: VerifyJoin, showGNB: false },
  { path: ROUTES.MY_BACK_OFFICE, component: MyBackOffice, background: true, requiresAuth: true },
  { path: ROUTES.MY_INFO, component: MyInfo, background: true, requiresAuth: true },
  { path: ROUTES.MY_PASSWORD, component: Password, background: true, requiresAuth: true },
  { path: ROUTES.VERIFY_INVITATION, component: Home, requiresAuth: true },
];

export const CMSRouteConfig: RouteConfigItem[] = [
  // main routes
  { path: ROUTES.DASHBOARD, component: Test },
  { path: ROUTES.SERVICE, component: ServicePage, section: '설정', name: '서비스 관리' },
  { path: ROUTES.PLATFORM, component: PlatformPage, section: '설정', name: '플랫폼 타입 및 버전' },
  { path: ROUTES.TERMS, component: TermsListPage, section: '약관', name: '약관 목록' },
  {
    path: ROUTES.TERMS_HISTORY,
    component: TermsHistoryPage,
    section: '약관',
    name: '약관 히스토리',
  },
  { path: ROUTES.MEMBERS, component: MembersPage, name: '멤버' },
  { path: ROUTES.USER, component: UserListPage, section: '회원', name: '회원 목록' },
  {
    path: ROUTES.WITHDRAW_USER,
    component: WithdrawalUserPage,
    section: '회원',
    name: '탈퇴 회원 목록',
  },
  {
    path: ROUTES.WITHDRAW_REASON,
    component: WithdrawalReasonPage,
    section: '회원',
    name: '탈퇴 사유 관리',
  },
  { path: ROUTES.PROFILE, component: ProfilePage, name: '프로필 설정' },

  // detail routes
  {
    path: `${ROUTES.PLATFORM}/version/:versionId`,
    component: PlatformDetailPage,
    section: '설정',
    name: '플랫폼 타입 및 버전',
  },
  {
    path: `${ROUTES.TERMS}/:termsId`,
    component: TermsDetailPage,
    section: '약관',
    name: '약관 목록',
  },
  {
    path: `${ROUTES.TERMS_HISTORY}/:termsId`,
    component: TermsDetailPage,
    section: '약관',
    name: '약관 히스토리',
  },
  { path: `${ROUTES.MEMBERS}/:adminId`, component: MemberDetailPage, name: '멤버' },
  { path: `${ROUTES.USER}/:userId`, component: UserDetailPage, section: '회원', name: '회원 목록' },
];
