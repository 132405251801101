import styled from 'styled-components';
import colors from '../../components/common/colors';
import { font } from '../../components/common/font';
import { ellipsisStyle } from '../../components/common/text';

export const CMSDetailLayoutStyle = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${colors.line_black_opacity_10};

  .title {
    ${ellipsisStyle};
    flex: 1 0 0;
    ${font.body_l_l_strong(true)};
  }

  .button {
    width: auto !important;
  }
`;
