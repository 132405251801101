import React, { useState, useEffect } from 'react';
import GlobalNavBar from '../../components/menu/service/GlobalNavBar';
import SideNavBar from '../../components/menu/service/SideNavBar';
import Breadcrumbs from '../../components/menu/BreadCrumbs';
import { useAlert } from '../../contexts/AlertContext';
import { setGlobalAlertTrigger } from '../../global';
import { AlertMessage } from '../../components/alert/AlertMessage';
import { Outlet } from 'react-router-dom';

const CMSMainLayout: React.FC = () => {
  const [isSnbOpen, setSnbOpen] = useState(false);
  const { showAlert, alertMessage, alertType, closeAlert, triggerAlert } = useAlert();

  useEffect(() => {
    setGlobalAlertTrigger(triggerAlert);

    return () => {
      setGlobalAlertTrigger(() => {});
    };
  }, [triggerAlert]);

  return (
    <div style={{ minWidth: '1000px' }}>
      <GlobalNavBar setSnbOpen={setSnbOpen} />
      <SideNavBar isopen={isSnbOpen} />
      {showAlert && (
        <AlertMessage show={showAlert} msg={alertMessage} type={alertType} onClose={closeAlert} />
      )}
      <main
        style={{
          minHeight: '100vh',
          padding: '72px 32px 24px 32px',
          marginLeft: isSnbOpen ? '200px' : '0',
          transition: 'margin-left 0.3s',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          flex: '1 0 0',
          alignSelf: 'stretch',
        }}
      >
        <Breadcrumbs />
        <Outlet />
      </main>
    </div>
  );
};

export default CMSMainLayout;
