import styled, { keyframes } from 'styled-components';
import colors from '../common/colors';
import { font } from '../common/font';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface ContainerProps {
  $isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${colors.bl_opacity_40};
  z-index: 3000;

  animation: ${(props) => (props.$isOpen ? fadeIn : fadeOut)} 0.3s ease-out;
`;

export const Popup = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  height: auto;
`;

export const PopupTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px 8px 0 0;
  background-color: ${colors.background_light_100};

  ${font.heading_heading_xs()};
  color: ${colors.text_active};

  .subtitle {
    ${font.body_m_m_strong()};
    color: ${colors.text_active};

    .point {
      color: ${colors.text_primary_dark};
    }
  }
`;

export const PopupContent = styled.div`
  display: flex;
  width: 400px;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-wrap;
  gap: 12px;
  background-color: ${colors.background_light_100};

  ${font.body_s_s_regular()};
  color: ${colors.text_active};

  .input {
    width: 100% !important;
  }
`;

export const PopupButton = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 0 0 8px 8px;
  background-color: ${colors.background_light_100};

  .button {
    width: auto !important;
  }
`;
