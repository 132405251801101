import React from 'react';
import { TitleWithComponentStyle } from './TitleWithComponentStyle';
import { StatusEnum } from '../common/enums';

export interface TitleWithComponentProps {
  title?: string;
  children?: React.ReactNode;
  isImport?: boolean;
  hintStr?: string;
  errorStr?: string;
  status?: StatusEnum;
}

export const TitleWithComponent: React.FC<TitleWithComponentProps> = ({
  title,
  isImport,
  children,
  hintStr,
  errorStr,
  ...props
}) => {
  return (
    <TitleWithComponentStyle>
      <div className='input-titlecontainer'>
        {title && <span className='input-title'>{title}</span>}
        {isImport && <span className='input-import'>*</span>}
      </div>
      {children}
      {props.status === StatusEnum.ERROR && errorStr && (
        <span className='input-error'>{errorStr}</span>
      )}
      {props.status !== StatusEnum.ERROR && hintStr && (
        <span className='input-hint'>{hintStr}</span>
      )}
    </TitleWithComponentStyle>
  );
};
