import styled from 'styled-components';
import colors from '../../../components/common/colors';
import { font } from '../../../components/common/font';
import { ellipsisStyle } from '../../../components/common/text';

export const AlertStyle = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: ${colors.brand_primary};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  .alert-icon {
    ${font.body_s_s_medium()};
  }
  .alert-text {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    color: ${colors.text_active};
    ${font.body_s_s_regular()};
    .alert-text-bold {
      ${font.body_s_s_strong()};
    }
  }
`;

export const MyBackOfficeStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  max-height: 100%;

  .mybackoffice-item {
    cursor: pointer;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    background: ${colors.background_light_100};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    .mybackoffice-info {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      > .ui.image {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid ${colors.line_black_opacity_10};
      }
      .info-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;
        ${ellipsisStyle};
        color: ${colors.text_active};
        ${font.body_m_m_strong()};

        > .sub {
          color: ${colors.text_active_light};
          ${font.body_xs_xs_regular()};
        }
      }
    }

    .mybackoffice-icon {
      display: flex;
      align-items: center;
      gap: 4px;

      > .arrow {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 3px 4px;
        align-items: flex-start;
        gap: 10px;
      }
    }
  }
`;
