import React, { useMemo } from 'react';
import TitleDescLayout from '../../../layouts/TitleDescLayout';
import SubMenuLayout from '../../../layouts/SubMenuLayout';
import { SubMenuContent } from '../../../layouts/SubMenuLayoutStyle';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { InputStatuses, useInputStatusManager } from '../../../hooks/useInputStatusManager';
import { encryptPassword, isValidPassword } from '../../../components/common/utils';
import { AlertTypeEnum, ColorEnum, SizeEnum, StatusEnum } from '../../../components/common/enums';
import { patchMemberPassword } from '../../../api/NoitApi';
import { useAuth } from '../../../contexts/AuthContext';
import { useAlert } from '../../../contexts/AlertContext';
import { TitleWithComponent } from '../../../components/form/TitleWithComponent';
import { CommonInput } from '../../../components/input/CommonInput';
import { CommonButton } from '../../../components/button/CommonButton';

const Password: React.FC = () => {
  const navigate = useNavigate();
  const { myInfo } = useAuth();
  const { triggerAlert } = useAlert();

  const passwordValidation = (value: string, reCheck = false) => {
    if (!value) return `새 비밀번호${reCheck ? ' 재확인' : ''}을(를) 입력해 주세요.`;
    if (!isValidPassword(value))
      return '*영문, 숫자, 특수기호를 사용하여 최소 8글자 이상 입력해 주세요.';
    return null;
  };

  const initioalData = useMemo((): InputStatuses => {
    return {
      '현재 비밀번호': {
        required: true,
        value: '',
      },
      '새 비밀번호': {
        required: true,
        value: '',
        customValidation: (value: string) => {
          return passwordValidation(value);
        },
      },
      '새 비밀번호 재확인': {
        required: true,
        value: '',
      },
    };
  }, []);
  const { inputStatuses, resetInputStatus, setStatus, checkAllValid, updateValue } =
    useInputStatusManager(initioalData);

  const validate = () => {
    if (!myInfo) {
      return;
    }

    if (!checkAllValid()) {
      return;
    }

    if (inputStatuses['새 비밀번호'].value !== inputStatuses['새 비밀번호 재확인'].value) {
      setStatus('새 비밀번호 재확인', StatusEnum.ERROR, '비밀번호가 일치하지 않습니다.');
      return;
    }

    patchMemberPassword(
      myInfo.id,
      encryptPassword(inputStatuses['현재 비밀번호'].value as string),
      encryptPassword(inputStatuses['새 비밀번호'].value as string)
    )
      .then(() => {
        triggerAlert({ msg: '비밀번호 변경이 완료되었습니다. ', type: AlertTypeEnum.SUCCESS });
        resetInputStatus();
      })
      .catch(() => {
        triggerAlert({
          msg: '비밀번호 변경을 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
          type: AlertTypeEnum.ERROR,
        });
      });
  };

  return (
    <TitleDescLayout
      title='계정 관리'
      desc='개인 정보를 관리하고 다른 사용자가 볼 수 있는 정보 및 백 오피스에서 접근할 수 있는 정보를 제어합니다.'
    >
      <SubMenuLayout
        title='계정 관리'
        subMenu={[
          {
            title: '계정',
            isActivated: false,
            onClick: () => {
              navigate(ROUTES.MY_INFO);
            },
          },
          { title: '비밀번호 변경', isActivated: true },
        ]}
      >
        <SubMenuContent>
          비밀번호 변경
          <TitleWithComponent
            status={inputStatuses['현재 비밀번호'].status}
            errorStr={inputStatuses['현재 비밀번호'].errorMessage}
            title='현재 비밀번호'
            isImport
          >
            <CommonInput
              name='현재 비밀번호'
              type='password'
              value={inputStatuses['현재 비밀번호'].value}
              status={inputStatuses['현재 비밀번호'].status}
              setStatus={setStatus}
              onDataChange={(value) => {
                updateValue('현재 비밀번호', value as string);
              }}
            />
          </TitleWithComponent>
          <TitleWithComponent
            hintStr='*영문, 숫자, 특수기호를 사용하여 최소 8글자 이상 입력해 주세요.'
            status={inputStatuses['새 비밀번호'].status}
            errorStr={inputStatuses['새 비밀번호'].errorMessage}
            title='새 비밀번호'
            isImport
          >
            <CommonInput
              name='새 비밀번호'
              type='password'
              value={inputStatuses['새 비밀번호'].value}
              status={inputStatuses['새 비밀번호'].status}
              setStatus={setStatus}
              onDataChange={(value) => {
                if (isValidPassword(value as string)) {
                  updateValue('새 비밀번호', value as string, StatusEnum.FOCUS);
                } else {
                  updateValue(
                    '새 비밀번호',
                    value as string,
                    StatusEnum.ERROR,
                    passwordValidation(value as string) || ''
                  );
                }
              }}
            />
          </TitleWithComponent>
          <TitleWithComponent
            status={inputStatuses['새 비밀번호 재확인'].status}
            errorStr={inputStatuses['새 비밀번호 재확인'].errorMessage}
            title='새 비밀번호 재확인'
            isImport
          >
            <CommonInput
              name='새 비밀번호 재확인'
              type='password'
              value={inputStatuses['새 비밀번호 재확인'].value}
              status={inputStatuses['새 비밀번호 재확인'].status}
              setStatus={setStatus}
              onDataChange={(value) => {
                const check =
                  inputStatuses['새 비밀번호'].value === value && isValidPassword(value as string);
                updateValue(
                  '새 비밀번호 재확인',
                  value as string,
                  check ? StatusEnum.FOCUS : StatusEnum.ERROR,
                  check
                    ? ''
                    : isValidPassword(value as string)
                      ? '비밀번호가 일치하지 않습니다.'
                      : passwordValidation(value as string, true) || ''
                );
              }}
            />
          </TitleWithComponent>
          <CommonButton
            sizeinfo={SizeEnum.M}
            colorinfo={ColorEnum.PRIMARY}
            content='변경사항 저장'
            onClick={validate}
          />
        </SubMenuContent>
      </SubMenuLayout>
    </TitleDescLayout>
  );
};

export default Password;
