import React, { useEffect } from 'react';
import { getTermsHistoryList } from '../../../api/NoitApi';
import { Terms } from '../../../api/models/ServiceModels';
import { BoxTableContainer, BoxTableHeader } from '../../../components/table/TableStyle';
import { TermsListCell } from './TermsListPageStyle';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { CommonLabel } from '../../../components/label/CommonLabel';
import {
  ColorEnum,
  PlatformDisplayName,
  PlatformEnum,
  SizeEnum,
} from '../../../components/common/enums';
import { convertDateFormat } from '../../../components/common/utils';
import { Images } from '../../../assets/images/images';
import { Image } from 'semantic-ui-react';
import { useService } from '../../../contexts/ServiceContext';

interface TermsHistoryListProps {
  termsId: number;
}

const TermsHistoryList: React.FC<TermsHistoryListProps> = ({ termsId }) => {
  let { service } = useService();
  const [termsHistoryList, setTermsHistoryList] = React.useState<Terms[]>([]);

  useEffect(() => {
    if (service) {
      getTermsHistoryList(service.id, termsId).then((response) => {
        setTermsHistoryList(response.items);
      });
    }
  }, [service, termsId]);

  const navigate = useNavigate();
  const goToDetailPage = (termsId: number) => {
    navigate(ROUTES.TERMS_DETAIL(termsId));
  };

  const getTermsHistory = (terms: Terms) => {
    if (!terms) {
      return '';
    }

    const history = convertDateFormat(terms.termsCreatedAt) + ' 등록';
    return terms.termsRemovedAt
      ? convertDateFormat(terms.termsRemovedAt) + ' 삭제'
      : terms.termsUpdatedAt
        ? convertDateFormat(terms.termsUpdatedAt) + ' 업데이트'
        : history;
  };

  const getMember = (terms: Terms) => {
    if (!terms) {
      return null;
    }
    return terms.termsRemovedBy
      ? terms.termsRemovedBy
      : terms.termsUpdatedBy
        ? terms.termsUpdatedBy
        : terms.termsCreatedBy;
  };

  return (
    <>
      <BoxTableContainer>
        <BoxTableHeader>
          <div className='header'>
            리스트 수 :<div className='count_total'>{termsHistoryList.length}</div>
          </div>
        </BoxTableHeader>
        <div className='box'>
          {termsHistoryList.length > 0 ? (
            termsHistoryList.map((terms) => (
              <TermsListCell key={terms.id} onClick={() => goToDetailPage(terms.id)}>
                <div className='type'>
                  <div className='info'>
                    {terms.version && <span className='version'>{terms.version}</span>}
                    <div className='note'>{terms.name}</div>
                  </div>
                </div>

                <div className='more'>
                  <div className='agree'>
                    <CommonLabel
                      sizeinfo={SizeEnum.S}
                      colorinfo={terms.requiredFlag ? ColorEnum.ORANGE : ColorEnum.BLUE}
                      children={terms.requiredFlag ? '필수 동의' : '선택 동의'}
                    />
                  </div>
                  <span className='etc'>
                    {terms.applyDatetime
                      ? `${convertDateFormat(terms.applyDatetime, 'yyyy-MM-dd')} 개정 및 시행`
                      : ''}
                  </span>
                  <div className='platform'>
                    {terms.platforms.includes(PlatformEnum.ANDROID) && (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GREEN}
                        children={`${PlatformDisplayName(PlatformEnum.ANDROID)}`}
                      />
                    )}
                    {terms.platforms.includes(PlatformEnum.IOS) && (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GRAY}
                        children={`${PlatformDisplayName(PlatformEnum.IOS)}`}
                      />
                    )}
                    {terms.platforms.includes(PlatformEnum.WEB) && (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.YELLOW}
                        children={`${PlatformDisplayName(PlatformEnum.WEB)}`}
                      />
                    )}
                  </div>
                  <span className='content'>{getTermsHistory(terms)}</span>
                  <div className='profile'>
                    <Image
                      src={
                        getMember(terms)?.imageUrl
                          ? getMember(terms)?.imageUrl
                          : Images.icon_profile_default
                      }
                    />
                    <span className='profile-name'>{getMember(terms)?.name}</span>
                  </div>
                </div>
              </TermsListCell>
            ))
          ) : (
            <div className='empty'>약관 히스토리가 없습니다.</div>
          )}
        </div>
      </BoxTableContainer>
    </>
  );
};

export default TermsHistoryList;
