import { css } from 'styled-components';
import './font.css';

export const body_l_l_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 300 ${isImport ? '!important' : ''};
  line-height: 28px ${isImport ? '!important' : ''};
`;

export const body_l_l_regular = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 28px ${isImport ? '!important' : ''};
`;

export const body_l_l_medium = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 500 ${isImport ? '!important' : ''};
  line-height: 28px ${isImport ? '!important' : ''};
`;

export const body_l_l_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 28px ${isImport ? '!important' : ''};
`;

export const body_m_m_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 300 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const body_m_m_regular = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const body_m_m_medium = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 500 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const body_m_m_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const body_s_s_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 300 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const body_s_s_regular = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const body_s_s_medium = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 500 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const body_s_s_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const body_xs_xs_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 300 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const body_xs_xs_regular = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const body_xs_xs_medium = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 500 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const body_xs_xs_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const label_label_l_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const label_label_l = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 600 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const label_label_l_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 18px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 24px ${isImport ? '!important' : ''};
`;

export const label_label_m_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const label_label_m = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 600 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const label_label_m_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 16px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const label_label_s_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const label_label_s = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 600 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const label_label_s_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 14px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 20px ${isImport ? '!important' : ''};
`;

export const label_label_xs_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const label_label_xs = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 600 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const label_label_xs_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 12px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 16px ${isImport ? '!important' : ''};
`;

export const label_label_xxs_light = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 10px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 400 ${isImport ? '!important' : ''};
  line-height: 14px ${isImport ? '!important' : ''};
`;

export const label_label_xxs = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 10px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 600 ${isImport ? '!important' : ''};
  line-height: 14px ${isImport ? '!important' : ''};
`;

export const label_label_xxs_strong = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 10px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 14px ${isImport ? '!important' : ''};
`;

export const heading_heading_xxl = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 40px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 52px ${isImport ? '!important' : ''};
`;

export const heading_heading_xl = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 36px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 44px ${isImport ? '!important' : ''};
`;

export const heading_heading_l = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 32px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 40px ${isImport ? '!important' : ''};
`;

export const heading_heading_m = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 28px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 36px ${isImport ? '!important' : ''};
`;

export const heading_heading_s = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 24px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 32px ${isImport ? '!important' : ''};
`;

export const heading_heading_xs = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 20px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 28px ${isImport ? '!important' : ''};
`;

export const display_display_l = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 96px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 112px ${isImport ? '!important' : ''};
`;

export const display_display_m = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 52px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 64px ${isImport ? '!important' : ''};
`;

export const display_display_s = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 44px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 52px ${isImport ? '!important' : ''};
`;

export const display_display_xs = (isImport: boolean = false) => css`
  font-family: Pretendard ${isImport ? '!important' : ''};
  font-size: 36px ${isImport ? '!important' : ''};
  font-style: normal ${isImport ? '!important' : ''};
  font-weight: 700 ${isImport ? '!important' : ''};
  line-height: 44px ${isImport ? '!important' : ''};
`;

export const font = {
  body_l_l_light,
  body_l_l_regular,
  body_l_l_medium,
  body_l_l_strong,
  body_m_m_light,
  body_m_m_regular,
  body_m_m_medium,
  body_m_m_strong,
  body_s_s_light,
  body_s_s_regular,
  body_s_s_medium,
  body_s_s_strong,
  body_xs_xs_light,
  body_xs_xs_regular,
  body_xs_xs_medium,
  body_xs_xs_strong,
  label_label_l_light,
  label_label_l,
  label_label_l_strong,
  label_label_m_light,
  label_label_m,
  label_label_m_strong,
  label_label_s_light,
  label_label_s,
  label_label_s_strong,
  label_label_xs_light,
  label_label_xs,
  label_label_xs_strong,
  label_label_xxs_light,
  label_label_xxs,
  label_label_xxs_strong,
  heading_heading_xxl,
  heading_heading_xl,
  heading_heading_l,
  heading_heading_m,
  heading_heading_s,
  heading_heading_xs,
  display_display_l,
  display_display_m,
  display_display_s,
  display_display_xs,
};
