import React, { useEffect, useState } from 'react';
import IntroGlobalNavBar from '../../components/menu/intro/IntroGlobalNavBar';
import IntroSideNavBar from '../../components/menu/intro/IntroSideNavBar';
import { Outlet, useLocation } from 'react-router-dom';
import { IntroMainLayoutStyle } from './IntroMainLayoutStyle';
import { IntroRouteConfig } from '../../routes/RouteConfig';
import { useAlert } from '../../contexts/AlertContext';
import { setGlobalAlertTrigger } from '../../global';
import AlertMessage from '../../components/alert/AlertMessage';

const IntroMainLayout: React.FC = () => {
  const [isSnbOpen, setSnbOpen] = useState(false);
  const { showAlert, alertMessage, alertType, closeAlert, triggerAlert } = useAlert();
  useEffect(() => {
    setGlobalAlertTrigger(triggerAlert);

    return () => {
      setGlobalAlertTrigger(() => {});
    };
  }, [triggerAlert]);

  const location = useLocation();
  const currentRouteConfig = IntroRouteConfig.find((route) => route.path === location.pathname);
  const showGNB = currentRouteConfig?.showGNB !== false;
  const background = currentRouteConfig?.background === true;

  return (
    <IntroMainLayoutStyle $showGNB={showGNB} $background={background}>
      <IntroGlobalNavBar setSnbOpen={setSnbOpen} showGNB={showGNB} />
      <IntroSideNavBar isopen={isSnbOpen} setSnbOpen={setSnbOpen} />
      {showAlert && (
        <AlertMessage show={showAlert} msg={alertMessage} type={alertType} onClose={closeAlert} />
      )}
      <main>
        <Outlet />
      </main>
    </IntroMainLayoutStyle>
  );
};

export default IntroMainLayout;
