import styled from 'styled-components';
import { Breadcrumb } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

export const BreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  ${font.heading_heading_s()};
  color: ${colors.text_active};
`;

export const BreadcrumbStyle = styled(Breadcrumb)`
  &&& {
    .section {
      ${font.body_xs_xs_regular()};
      color: ${colors.text_active_light};
    }

    .active.section {
      ${font.body_xs_xs_strong()};
      color: ${colors.text_active};
    }

    .divider {
      ${font.body_xs_xs_regular()};
      color: ${colors.bl_opacity_10};
    }
  }
`;
