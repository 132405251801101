import React, { useCallback } from 'react';
import TitleDescLayout from '../../../layouts/TitleDescLayout';
import SubMenuLayout from '../../../layouts/SubMenuLayout';
import { SubMenuContent } from '../../../layouts/SubMenuLayoutStyle';
import { MyInfoStyle } from './MyInfoStyle';
import { ImageUploadContainer } from '../../../components/image/ImageUploadContainer';
import { useAuth } from '../../../contexts/AuthContext';
import { deleteAdminImageUrl, patchAdminImageUrl, patchMember } from '../../../api/NoitApi';
import { HeaderListItem } from '../../../components/list/HeaderListItem';
import { CommonButton } from '../../../components/button/CommonButton';
import { AlertTypeEnum, ColorEnum, SizeEnum } from '../../../components/common/enums';
import { useDialog } from '../../../contexts/DialogContext';
import { InputStatuses } from '../../../hooks/useInputStatusManager';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { useAlert } from '../../../contexts/AlertContext';

const MyInfo: React.FC = () => {
  const { triggerAlert } = useAlert();
  const { myInfo, refreshAuth } = useAuth();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const initioalData = (): InputStatuses => {
    return {
      이름: {
        required: true,
        value: '',
        limitLength: 50,
      },
    };
  };

  const openEditDialog = useCallback(() => {
    if (!myInfo) {
      return;
    }
    openDialog({
      title: '이름 편집',
      initialValue: initioalData(),
      confirmText: '변경사항 저장',
      onConfirm(value) {
        if (value) {
          patchMember(myInfo.id, value['이름'].value as string)
            .then(() => {
              refreshAuth();
              closeDialog();
              triggerAlert({ msg: '이름 편집이 완료되었습니다.', type: AlertTypeEnum.SUCCESS });
            })
            .catch(() => {
              triggerAlert({
                msg: '편집을 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
                type: AlertTypeEnum.ERROR,
              });
            });
        }
      },
    });
  }, [myInfo, openDialog, closeDialog, refreshAuth, triggerAlert]);

  return (
    <TitleDescLayout
      title='계정 관리'
      desc='개인 정보를 관리하고 다른 사용자가 볼 수 있는 정보 및 백 오피스에서 접근할 수 있는 정보를 제어합니다.'
    >
      <SubMenuLayout
        title='계정 관리'
        subMenu={[
          { title: '계정', isActivated: true },
          {
            title: '비밀번호 변경',
            isActivated: false,
            onClick: () => {
              navigate(ROUTES.MY_PASSWORD);
            },
          },
        ]}
      >
        <SubMenuContent>
          계정
          <MyInfoStyle>
            <ImageUploadContainer
              dirPath='member-account'
              imageUrl={myInfo?.imageUrl}
              onImageUploadSuccess={async (uploadedImageUrl) => {
                try {
                  if (myInfo) {
                    await patchAdminImageUrl(myInfo.id, uploadedImageUrl);
                    refreshAuth();
                  }
                } catch (error) {
                  console.error('Failed to update service image URL', error);
                }
              }}
              onImageDelete={async () => {
                try {
                  if (myInfo) {
                    await deleteAdminImageUrl(myInfo.id);
                    refreshAuth();
                  }
                } catch (error) {
                  console.error('Failed to delete service image URL', error);
                }
              }}
            />
            <div className='my-info'>
              프로필
              <div className='profile-info'>
                <HeaderListItem
                  header='이름'
                  children={myInfo?.name ?? ''}
                  buttons={[
                    <CommonButton
                      key='edit'
                      sizeinfo={SizeEnum.XS}
                      colorinfo={ColorEnum.BORDERGRAY}
                      content='편집'
                      onClick={() => {
                        openEditDialog();
                      }}
                    />,
                  ]}
                />
                <HeaderListItem header='이메일' children={myInfo?.loginId ?? ''} />
              </div>
            </div>
          </MyInfoStyle>
        </SubMenuContent>
      </SubMenuLayout>
    </TitleDescLayout>
  );
};

export default MyInfo;
