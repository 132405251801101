import React, { useState, useEffect, useMemo } from 'react';
import {
  AddEditPopupLayout,
  AddEditPopupLayoutProps,
} from '../../../../components/popup/layout/AddEditPopupLayout';
import { Version, VersionForm } from '../../../../api/models/ServiceModels';
import { TitleWithComponent } from '../../../../components/form/TitleWithComponent';
import { InputDatePicker } from '../../../../components/input/InputDatePicker';
import { CommonTextArea } from '../../../../components/textArea/CommonTextArea';
import { CommonInput } from '../../../../components/input/CommonInput';
import { useAlert } from '../../../../contexts/AlertContext';
import {
  AlertTypeEnum,
  StatusEnum,
  PlatformDisplayName,
} from '../../../../components/common/enums';
import { CommonDropDown } from '../../../../components/dropDown/CommonDropDown';
import {
  useInputStatusManager,
  InputStatuses,
  InputTypeEnum,
} from '../../../../hooks/useInputStatusManager';
import {
  convertDateFormat,
  convertDate,
  DateFormatToDate,
  convertToISOString,
} from '../../../../components/common/utils';
import { putPlatformVersion, postPlatformVersion } from '../../../../api/NoitApi';
import { PlatformEnum } from '../../../../components/common/enums';
import { useService } from '../../../../contexts/ServiceContext';

interface VersionPopupProps extends AddEditPopupLayoutProps {
  platform: string;
  data?: Version | null;
}

export const VersionPopup: React.FC<VersionPopupProps> = ({
  platform,
  data,
  isPopupOpen,
  setIsPopupOpen,
  onConfirm,
}) => {
  const { service } = useService();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const { triggerAlert } = useAlert();

  const IsDateDisable = useMemo((): boolean => {
    if (data && data.alertAt) {
      return DateFormatToDate(data.alertAt) < new Date();
    }
    return false;
  }, [data]);

  const initioalData = useMemo((): InputStatuses => {
    return {
      버전명: {
        type: InputTypeEnum.INPUT,
        required: true,
        maxLength: 100,
        value: data?.name ?? '',
      },
      '업데이트 내용': {
        type: InputTypeEnum.TEXTAREA,
        required: true,
        maxLength: 1000,
        value: data?.note ?? '',
      },
      '업데이트 유무': {
        type: InputTypeEnum.DROPDOWN,
        required: platform !== PlatformEnum.WEB,
        value: !data
          ? []
          : (data?.forceUpdateFlag ?? false)
            ? ['강제 업데이트']
            : ['선택 업데이트'],
      },
      '업데이트 알림 일시': {
        type: InputTypeEnum.DATEPICKER,
        hint: '*업데이트 알림 일시 미 설정 시 즉시 업데이트 알림 팝업이 노출됩니다.',
        errorMessage: '알림 일시는 현재보다 과거일 수 없습니다.',
        value: data?.alertAt ? convertDateFormat(data?.alertAt) : '',
        status: IsDateDisable ? StatusEnum.DISABLED : StatusEnum.DEFAULT,
      },
    };
  }, [data, platform, IsDateDisable]);

  const { inputStatuses, updateInputStatus, setStatus, setInputStatuses, checkAllValid } =
    useInputStatusManager(initioalData);

  useEffect(() => {
    if (isPopupOpen) {
      setInputStatuses(initioalData);
    }
  }, [isPopupOpen, setInputStatuses, initioalData]);

  const handleDateChange = (date: Date) => {
    const now = new Date();
    if (date >= now) {
      updateInputStatus({
        '업데이트 알림 일시': { value: convertDate(date, 'yyyy-MM-dd a hh:mm') },
      });
      setShowDatePicker(false);
    } else {
      triggerAlert({
        msg: inputStatuses['업데이트 알림 일시'].errorMessage!,
        type: AlertTypeEnum.ERROR,
      });
    }
  };

  const handleSubmit = () => {
    if (!service) {
      return;
    }

    if (!checkAllValid()) {
      return;
    }

    const versionForm: VersionForm = {
      name: inputStatuses['버전명'].value as string,
      note: inputStatuses['업데이트 내용'].value as string,
      useFlag: true,
      forceUpdateFlag: inputStatuses['업데이트 유무']
        ? inputStatuses['업데이트 유무'].value.includes('강제 업데이트')
        : false,
      alertAt:
        inputStatuses['업데이트 알림 일시'] && inputStatuses['업데이트 알림 일시'].value
          ? convertToISOString(inputStatuses['업데이트 알림 일시'].value as string)
          : null,
      platform: platform,
    };

    const done = (version: Version) => {
      onConfirm && onConfirm(version);
    };

    if (data) {
      setIsPopupOpen(false);
      putPlatformVersion(service.id, data.id, versionForm).then((version) => {
        done(version);
      });
    } else {
      setIsPopupOpen(false);
      postPlatformVersion(service.id, versionForm).then((version) => {
        done(version);
      });
    }
  };

  return (
    <AddEditPopupLayout
      isPopupOpen={isPopupOpen}
      setIsPopupOpen={setIsPopupOpen}
      title={PlatformDisplayName(platform)}
      data={data}
      onConfirm={handleSubmit}
    >
      {inputStatuses &&
        Object.keys(inputStatuses).map((key) => {
          const config = inputStatuses[key];
          if (config.type === InputTypeEnum.INPUT || config.type === InputTypeEnum.TEXTAREA) {
            return (
              <TitleWithComponent
                status={config.status}
                errorStr={config.errorMessage}
                hintStr={config.hint}
                title={key}
                isImport={config.required}
              >
                {config.type === InputTypeEnum.TEXTAREA ? (
                  <CommonTextArea
                    key={key}
                    name={key}
                    value={config.value as string}
                    status={config.status}
                    setStatus={setStatus}
                    onDataChange={(value) => {
                      updateInputStatus({ [key]: { value: value as string } });
                    }}
                  />
                ) : (
                  <CommonInput
                    key={key}
                    name={key}
                    value={config.value}
                    status={config.status}
                    setStatus={setStatus}
                    onDataChange={(value) => {
                      updateInputStatus({ [key]: { value: value } });
                    }}
                  />
                )}
              </TitleWithComponent>
            );
          }
          if (platform !== PlatformEnum.WEB) {
            return (
              <TitleWithComponent hintStr={config.hint} title={key} isImport={config.required}>
                {key === '업데이트 유무' && (
                  <CommonDropDown
                    key={key}
                    name={key}
                    value={config.value}
                    status={config.status}
                    setStatus={setStatus}
                    onDataChange={(value) => {
                      updateInputStatus({
                        '업데이트 알림 일시': { value: '', status: StatusEnum.DEFAULT },
                        '업데이트 유무': { value: value as string[] },
                      });
                    }}
                    placeholder='업데이트 유무를 선택해 주세요.'
                    options={[
                      { key: 'Y', text: '강제 업데이트', value: '강제 업데이트' },
                      { key: 'N', text: '선택 업데이트', value: '선택 업데이트' },
                    ]}
                  />
                )}
                {key === '업데이트 알림 일시' && (
                  <InputDatePicker
                    key={key}
                    onSelect={() => {
                      if (config.status === StatusEnum.DISABLED) {
                        triggerAlert({
                          msg: '이미 업데이트 알림이 나간 버전은 일시를 수정할 수 없습니다.',
                          type: AlertTypeEnum.ERROR,
                        });
                        return;
                      }
                    }}
                    name={key}
                    status={config.status}
                    setStatus={setStatus}
                    value={config.value as string}
                    onDateChange={handleDateChange}
                    setShowDatePicker={setShowDatePicker}
                    showDatePicker={showDatePicker}
                    placeholder='업데이트 알림 일시를 선택해 주세요.'
                  />
                )}
              </TitleWithComponent>
            );
          }
          return null;
        })}
    </AddEditPopupLayout>
  );
};
