import styled from 'styled-components';
import { font } from '../../../components/common/font';
import colors from '../../../components/common/colors';
import { wrapTextStyle } from '../../../components/common/text';

export const LoginStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
  flex-direction: column;
  align-items: center;

  > .ui.image {
    object-fit: contain;
    width: 100%;
    height: 24px;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    > .ui.image {
      margin-top: 0px;
      display: none;
    }
  }

  .content {
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    > .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      ${font.heading_heading_l(true)};
      color: ${colors.text_active} !important;

      .primary {
        ${font.body_m_m_strong(true)};
        color: ${colors.text_primary_dark} !important;
      }

      .sub_group {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        gap: 2px;
      }

      .sub {
        text-align: center;
        width: 100%;
        ${wrapTextStyle};
        ${font.body_m_m_regular(true)};
      }

      .bold {
        ${font.body_m_m_strong(true)};
      }
    }

    .terms {
      width: 354px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      > .import {
        ${font.body_xs_xs_regular()};
        color: ${colors.text_error} !important;
      }
    }

    .error {
      text-align: center;
      white-space: pre-wrap;
      display: flex;
      width: 354px;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 4px;
      background: ${colors.background_light_95};
      ${font.body_s_s_strong(true)};
      color: ${colors.text_active} !important;
      .sub {
        text-align: center;
        white-space: pre-wrap;
        ${font.body_s_s_regular(true)};
      }

      .check {
        display: flex;
        height: 28px;
        padding-left: 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: ${colors.text_active_light} !important;
        ${font.body_s_s_regular(true)};
        text-align: left;
        width: 100%;
        .link {
          cursor: pointer;
          color: ${colors.text_link} !important;
        }
        .import {
          color: ${colors.text_error} !important;
        }
      }
    }

    > .line {
      width: 354px;
      height: 1px;
      background: ${colors.bl_opacity_10};
    }

    > .box {
      width: 354px !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .ui.input {
        width: 354px !important;
      }

      .ui.button {
        margin-top: 16px;
        width: 100%;
      }
    }

    > .title-box {
      width: 354px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .verify {
        display: flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        background: ${colors.brand_light_02};
        ${font.body_s_s_strong(true)};
        color: ${colors.text_active};
        .sub {
          ${font.body_s_s_regular(true)};
        }
      }

      .ui.input {
        width: 354px !important;
      }

      .ui.button {
        margin-top: 16px;
        width: 100%;
      }
    }

    > .join {
      text-align: center;
      white-space: pre-wrap;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      ${font.body_xs_xs_regular(true)};
      color: ${colors.text_active_light} !important;

      .create {
        ${font.body_s_s_strong(true)};
        color: ${colors.text_primary_dark} !important;
      }
    }
  }
`;
