import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginStyle } from './LoginStyle';
import { ClickableImage, CommonCheckboxStyle } from '../../../components/EtcStyle';
import { Images } from '../../../assets/images/images';
import * as ROUTES from '../../../routes/Routes';
import { getTermsList, postAdminJoinRequest, postAdminVerifyJoin } from '../../../api/NoitApi';
import { Terms, TermsAgree } from '../../../api/models/ServiceModels';
import { Image } from 'semantic-ui-react';
import { InputStatuses, useInputStatusManager } from '../../../hooks/useInputStatusManager';
import { encryptPassword, isValidPassword } from '../../../components/common/utils';
import { TitleWithComponent } from '../../../components/form/TitleWithComponent';
import { CommonInput } from '../../../components/input/CommonInput';
import { CommonButton } from '../../../components/button/CommonButton';
import { ColorEnum, SizeEnum, StatusEnum } from '../../../components/common/enums';
import { AdminJoinRequest } from '../../../api/models/MemberModels';
import { useDialog } from '../../../contexts/DialogContext';
import logger from '../../../components/common/logger';

const VerifyJoin: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const id = queryParams.get('id');
  const loginId = queryParams.get('loginId');
  const serviceId = queryParams.get('serviceId');
  const [termsList, setTermsList] = useState<Terms[]>([]);
  const [termsChecked, setTermsChecked] = useState<TermsAgree[]>([]);
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    getTermsList(0).then((data) => {
      setTermsList(data.items);
    });
  }, []);

  useEffect(() => {
    if (termsList.length > 0) {
      const newTermsChecked = termsList.map((terms) => ({
        termsId: terms.id,
        agreeFlag: false,
      }));
      setTermsChecked(newTermsChecked);
    }
  }, [termsList]);

  const handleTermsChecked = (termsId: number) => {
    const newTerms = termsChecked.map((terms) => {
      if (terms.termsId === termsId) {
        return { termsId, agreeFlag: !terms.agreeFlag };
      }
      return terms;
    });
    setTermsChecked(newTerms);
    setError(null);
  };

  const validateTerms = () => {
    return termsList.every((terms) => {
      if (!terms.requiredFlag) {
        // 필수가 아니면 검사할 필요 없으므로 true를 반환
        return true;
      }
      const checked = termsChecked.find((term) => term.termsId === terms.id);
      // 필수 항목이고, 동의했으면 true, 동의하지 않았으면 false 반환
      return !!checked?.agreeFlag;
    });
  };

  const passwordValidation = (value: string, reCheck = false) => {
    if (!value) return `비밀번호${reCheck ? ' 재확인' : ''}을(를) 입력해 주세요.`;
    if (!isValidPassword(value))
      return '*영문, 숫자, 특수기호를 사용하여 최소 8글자 이상 입력해 주세요.';
    return null;
  };

  const initioalData = useMemo((): InputStatuses => {
    return {
      비밀번호: {
        required: true,
        value: '',
        customValidation: (value: string) => {
          return passwordValidation(value);
        },
      },
      '비밀번호 재확인': {
        required: true,
        value: '',
      },
      이름: { required: true, value: '', limitLength: 50 },
    };
  }, []);

  const { inputStatuses, updateValue, setStatus, checkAllValid } =
    useInputStatusManager(initioalData);

  const validate = () => {
    if (!token) {
      openDialog({
        title: 'NOIT 계정 생성',
        content: '유효하지않은 토큰입니다.\n다시 시도해 주세요.',
        isConfirmOnly: true,
        onConfirm: () => {
          navigate(ROUTES.HOME);
          closeDialog();
        },
      });
      return;
    }

    if (!validateTerms()) {
      setError('필수 약관을 동의해 주세요.');
      return;
    }

    if (!checkAllValid()) {
      return;
    }

    if (inputStatuses['비밀번호'].value !== inputStatuses['비밀번호 재확인'].value) {
      setStatus('비밀번호 재확인', StatusEnum.ERROR, '비밀번호가 일치하지 않습니다.');
      return;
    }

    const data: AdminJoinRequest = {
      verifyToken: token,
      password: encryptPassword(inputStatuses['비밀번호'].value as string),
      name: inputStatuses['이름'].value as string,
      terms: termsChecked,
      marketingTerms: {
        emailAgreeFlag: emailChecked,
        smsAgreeFlag: false,
        callAgreeFlag: false,
        postAgreeFlag: false,
      },
    };

    const done = () => {
      navigate(ROUTES.LOGIN, { state: { signUpSuccess: true } });
    };

    if (serviceId) {
      postAdminVerifyJoin(Number(serviceId), Number(id), data)
        .then(() => {
          done();
        })
        .catch((error) => {
          logger.error('Error joining admin', error);
          openDialog({
            title: 'NOIT 계정 생성',
            content: '계정 생성에 실패하였습니다.\n다시 시도해 주세요.',
            isConfirmOnly: true,
            onConfirm: () => {
              closeDialog();
            },
          });
        });
    } else {
      postAdminJoinRequest(Number(id), data)
        .then(() => {
          done();
        })
        .catch((error) => {
          logger.error('Error joining admin', error);
          openDialog({
            title: 'NOIT 계정 생성',
            content: '계정 생성에 실패하였습니다.\n다시 시도해 주세요.',
            isConfirmOnly: true,
            onConfirm: () => {
              closeDialog();
            },
          });
        });
    }
  };

  return (
    <LoginStyle>
      <ClickableImage src={Images.intro_logo_black} onClick={() => navigate(ROUTES.HOME)} />
      <div className='content'>
        <div className='title'>
          NOIT 계정 생성
          <div className='sub_group'>
            <div className='sub'>NOIT은 여러분과 한 팀이 되어 서비스를 만들고 지원합니다.</div>
            <div className='sub'>
              NOIT의 멤버가 되어 새로운 비전을 만들고 싶다면 아래 약관 동의 후 계정 생성을 완료해
              주세요.
            </div>
          </div>
        </div>

        <div className='terms'>
          <div className='error'>
            {termsList.map((terms) => {
              return (
                <div key={terms.id} className='check'>
                  <CommonCheckboxStyle
                    name={terms.name}
                    onChange={() => {
                      handleTermsChecked(terms.id);
                    }}
                  />
                  <div>
                    <span className='link'>{terms.name}</span>
                    {terms.requiredFlag && <span className='import'>*</span>}에 동의합니다.
                  </div>
                </div>
              );
            })}
            <div key='email' className='check'>
              <CommonCheckboxStyle
                name='email'
                onChange={() => {
                  setError(null);
                  setEmailChecked(!emailChecked);
                }}
              />
              이메일 마케팅 수신에 동의합니다.
            </div>
          </div>
          {error && <span className='import'>{error}</span>}
        </div>

        <div className='line' />
        <div className='title-box'>
          {loginId && (
            <div className='verify'>
              <Image src={Images.ic_email_checked} />
              {loginId}
              <div className='sub'>(으)로 인증 완료</div>
            </div>
          )}
          {inputStatuses && (
            <>
              <TitleWithComponent
                hintStr='*영문, 숫자, 특수기호를 사용하여 최소 8글자 이상 입력해 주세요.'
                status={inputStatuses['비밀번호'].status}
                errorStr={inputStatuses['비밀번호'].errorMessage}
                title='비밀번호'
                isImport
              >
                <CommonInput
                  name='비밀번호'
                  type='password'
                  value={inputStatuses['비밀번호'].value}
                  status={inputStatuses['비밀번호'].status}
                  setStatus={setStatus}
                  onDataChange={(value) => {
                    if (isValidPassword(value as string)) {
                      updateValue('비밀번호', value as string, StatusEnum.SUCCESS);
                    } else {
                      updateValue(
                        '비밀번호',
                        value as string,
                        StatusEnum.ERROR,
                        passwordValidation(value as string) || ''
                      );
                    }
                  }}
                />
              </TitleWithComponent>
              <TitleWithComponent
                status={inputStatuses['비밀번호 재확인'].status}
                errorStr={inputStatuses['비밀번호 재확인'].errorMessage}
                title='비밀번호 재확인'
                isImport
              >
                <CommonInput
                  name='비밀번호 재확인'
                  type='password'
                  value={inputStatuses['비밀번호 재확인'].value}
                  status={inputStatuses['비밀번호 재확인'].status}
                  setStatus={setStatus}
                  onDataChange={(value) => {
                    const check =
                      inputStatuses['비밀번호'].value === value && isValidPassword(value as string);
                    updateValue(
                      '비밀번호 재확인',
                      value as string,
                      check ? StatusEnum.SUCCESS : StatusEnum.ERROR,
                      check
                        ? ''
                        : isValidPassword(value as string)
                          ? '비밀번호가 일치하지 않습니다.'
                          : passwordValidation(value as string, true) || ''
                    );
                  }}
                />
              </TitleWithComponent>
              <TitleWithComponent
                status={inputStatuses['이름'].status}
                errorStr={inputStatuses['이름'].errorMessage}
                title='이름'
                isImport
              >
                <CommonInput
                  name='이름'
                  value={inputStatuses['이름'].value}
                  status={inputStatuses['이름'].status}
                  setStatus={setStatus}
                  onDataChange={(value) => {
                    updateValue('이름', value as string);
                  }}
                />
              </TitleWithComponent>

              <CommonButton
                sizeinfo={SizeEnum.M}
                colorinfo={ColorEnum.PRIMARY}
                content='계정 생성'
                onClick={validate}
              />
            </>
          )}
        </div>
      </div>
    </LoginStyle>
  );
};

export default VerifyJoin;
