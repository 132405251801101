import React from 'react';
import { Image } from 'semantic-ui-react';
import { AlertMessageStyle } from './AlertMessageStyle';
import { Images } from '../../assets/images/images';
import { AlertTypeEnum } from '../common/enums';

// Props 타입 정의
export interface AlertMessageProps {
  show: boolean;
  msg: string;
  type?: AlertTypeEnum; // type은 옵셔널(선택적) prop입니다
  onClose?: () => void; // onClose는 함수 타입입니다
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  show,
  msg,
  type = AlertTypeEnum.SUCCESS,
  onClose,
  ...props
}) => {
  return (
    <AlertMessageStyle $show={show} {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image
          src={type === AlertTypeEnum.SUCCESS ? Images.icon_alert_success : Images.icon_alert_error}
        />
        <div>{msg}</div>
      </div>
      <i className='close fa-solid fa-xmark' onClick={onClose} />
    </AlertMessageStyle>
  );
};

export default AlertMessage;
