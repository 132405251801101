import styled from 'styled-components';
import colors from '../common/colors';
import { font } from '../common/font';

export const TitleWithComponentStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  .input-titlecontainer {
    display: flex;
    align-items: flex-start;
  }

  .input-title {
    ${font.body_s_s_strong()};
    color: ${colors.text_active};
  }

  .input-import {
    ${font.body_xs_xs_regular()};
    color: ${colors.text_error};
  }

  .input-error {
    margin-top: 4px;
    color: ${colors.text_error};
    ${font.body_xs_xs_regular()};
  }

  .input-hint {
    margin-top: 4px;
    color: ${colors.text_active_light};
    ${font.body_xs_xs_regular()};
  }
`;
