import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useService } from '../../../contexts/ServiceContext';
import { UserDetail } from '../../../api/models/UserModels';
import { useDialog } from '../../../contexts/DialogContext';
import { useAuthorization } from '../../../hooks/useAuthorization';
import {
  deleteResetImage,
  deleteResetPassword,
  deleteWithdrawUser,
  getUser,
} from '../../../api/NoitApi';
import CMSDetailLayout from '../../../layouts/cms/CMSDetailLayout';
import { CommonButton } from '../../../components/button/CommonButton';
import {
  ColorEnum,
  JoinTypeDisplayName,
  PlatformDisplayName,
  PlatformEnum,
  SizeEnum,
  UserStatusDisplayName,
} from '../../../components/common/enums';
import { MemberDetailPageStyle } from '../member/MemberDetailPageStyle';
import { HistoryStyle, ProfileImageHistoryStyle } from '../../../components/view/InfoViewStyle';
import { Image } from 'semantic-ui-react';
import { Images } from '../../../assets/images/images';
import { convertDateFormat } from '../../../components/common/utils';
import { HeaderListItem } from '../../../components/list/HeaderListItem';
import { CommonLabel } from '../../../components/label/CommonLabel';
import * as ROUTES from '../../../routes/Routes';

export const UserDetailPage: React.FC = () => {
  const navigate = useNavigate();
  let { service } = useService();
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserDetail | null>(null);
  const { openDialog, closeDialog, openAuthDialog } = useDialog();
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const authWithdraw = useAuthorization('MEMBER_WITHDRAW');
  const authEdit = useAuthorization('MEMBER_EDIT');

  useEffect(() => {
    if (!service || !userId) return;
    getUser(service.id, parseInt(userId, 10)).then((res) => {
      setUser(res);
    });
  }, [service, userId]);

  const handleDelete = async () => {
    if (!user || !service) {
      return;
    }

    try {
      await deleteWithdrawUser(service.id, user.id);
      navigate(-1);
    } catch (error) {
      console.error('Error deleting terms', error);
    }
  };

  return (
    <>
      <CMSDetailLayout
        title={user?.name ?? ''}
        actions={
          <>
            <CommonButton
              style={{ display: 'none' }}
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='편집'
              onClick={() => {
                if (authEdit) {
                  openAuthDialog('회원 편집', authEdit);
                  return;
                }
                // setIsPopupOpen(true);
              }}
            />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='비밀번호 초기화'
              onClick={() => {
                if (!user || !service) {
                  return;
                }
                deleteResetPassword(service.id, user.id);
              }}
            />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='회원 탈퇴'
              onClick={() => {
                if (!user || !service) {
                  return;
                }
                if (authWithdraw) {
                  openAuthDialog('회원 탈퇴', authWithdraw);
                  return;
                }
                openDialog({
                  title: '회원 탈퇴',
                  content: `${user.name} 회원 탈퇴하시겠습니까?`,
                  onConfirm: () => {
                    handleDelete();
                    closeDialog();
                  },
                });
              }}
            />
          </>
        }
      />
      <MemberDetailPageStyle>
        <ProfileImageHistoryStyle>
          <div className='profile-image-container'>
            <Image src={user?.imageUrl ?? Images.img_profile_default_big} />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='프로필 이미지 초기화'
              onClick={() => {
                if (!user || !service) {
                  return;
                }
                openDialog({
                  title: '중요 안내',
                  content: `프로필 이미지 초기화 시 회원에게도 초기화 알림이 발송됩니다.\n프로필 이미지 초기화를 진행하시겠습니까?`,
                  confirmText: '프로필 초기화 진행',
                  onConfirm: () => {
                    closeDialog();
                    if (!user || !service) {
                      return;
                    }
                    deleteResetImage(service.id, user.id).then(() => {
                      user.imageUrl = null;
                      setUser({ ...user });
                    });
                  },
                });
              }}
            />
          </div>
          <HistoryStyle>
            {user &&
              user?.history.map((history) => (
                <div
                  key={history.id}
                >{`${convertDateFormat(history.createdAt)} ${history.description}(${history.createdBy.name})`}</div>
              ))}
          </HistoryStyle>
        </ProfileImageHistoryStyle>
        <div className='member-info'>
          <div className='profile-info'>
            프로필
            <div className='box'>
              <div className='section'>
                <HeaderListItem header='이름' children={user?.name ?? ''} />
                <span className='line' />
                <HeaderListItem header='KEY' children={userId ?? ''} />
              </div>
              <HeaderListItem header='이메일' children={user?.emailId ?? ''} />
              <div className='section'>
                <HeaderListItem
                  header='가입 플랫폼'
                  children={
                    user && user.joinPlatform === PlatformEnum.ANDROID ? (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GREEN}
                        children={`${PlatformDisplayName(PlatformEnum.ANDROID)}`}
                      />
                    ) : user?.joinPlatform === PlatformEnum.IOS ? (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GRAY}
                        children={`${PlatformDisplayName(PlatformEnum.IOS)}`}
                      />
                    ) : (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.YELLOW}
                        children={`${PlatformDisplayName(PlatformEnum.WEB)}`}
                      />
                    )
                  }
                />
                <span className='line' />
                <HeaderListItem header='플랫폼 버전' children={user?.platformVersion ?? ''} />
              </div>
              <div className='section'>
                <HeaderListItem
                  header='가입 수단'
                  children={JoinTypeDisplayName(user?.joinType ?? '')}
                />
                <span className='line' />
                <HeaderListItem
                  header='가입 일시'
                  children={convertDateFormat(user?.joinedAt ?? '')}
                />
              </div>
              <div className='section'>
                <HeaderListItem
                  header='회원 상태'
                  children={UserStatusDisplayName(user?.status ?? '')}
                />
                <span className='line' />
                <HeaderListItem
                  header='징계 수'
                  children={<div className='link'>{user?.discipline.disciplineCnt.toString()}</div>}
                  buttons={[
                    <CommonButton
                      style={{ display: 'none' }}
                      sizeinfo={SizeEnum.XS}
                      colorinfo={ColorEnum.PRIMARY}
                      content='징계 주기'
                      onClick={() => {
                        if (!user || !service) {
                          return;
                        }
                      }}
                    />,
                  ]}
                />
              </div>
              <div className='section'>
                <HeaderListItem
                  header='게시글 신고 수'
                  children={
                    <div
                      className='link'
                      onClick={() => {
                        navigate(ROUTES.USER);
                      }}
                    >
                      {user?.report.reportedBoardCnt.toString()}
                    </div>
                  }
                />
                <span className='line' />
                <HeaderListItem
                  header='회원 신고 수'
                  children={<div className='link'>{user?.report.reportedCnt.toString()}</div>}
                />
              </div>
              <div className='section'>
                <HeaderListItem
                  header='게시글 수'
                  children={
                    <div className='link'>{(user?.additionalInfo.boardCnt ?? 0).toString()}</div>
                  }
                />
                <span className='line' />
                <HeaderListItem
                  header='마지막 로그인 일시'
                  children={convertDateFormat(user?.latestActiveAt ?? '')}
                />
              </div>
            </div>
          </div>
        </div>
      </MemberDetailPageStyle>
    </>
  );
};
