import React, { createContext, useContext, useState } from 'react';
import { DialogPopupProps, CommonDialogPopup } from '../components/popup/CommonDialogPopup';

const DialogContext = createContext<
  Partial<DialogPopupProps> & {
    openDialog: (props: DialogPopupProps) => void;
    closeDialog: () => void;
    openAuthDialog: (menu: string, content: string, onConfirm?: () => void) => void;
    isOpen: boolean;
  }
>(null!);

export const useDialog = () => useContext(DialogContext);

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dialogProps, setDialogProps] = useState<DialogPopupProps | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openDialog = (props: DialogPopupProps) => {
    setIsOpen(true);
    setDialogProps(props);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setTimeout(() => {
      setDialogProps(null);
    }, 300);
  };

  const openAuthDialog = (menu: string, content: string, onConfirm?: () => void) => {
    openDialog({
      title: menu,
      content: content,
      isConfirmOnly: true,
      onConfirm: () => {
        closeDialog();
        if (onConfirm) {
          onConfirm();
        }
      },
    });
  };

  return (
    <DialogContext.Provider
      value={{ ...dialogProps, openDialog, closeDialog, openAuthDialog, isOpen }}
    >
      {dialogProps && isOpen && <CommonDialogPopup isOpen={isOpen} {...dialogProps} />}
      {children}
    </DialogContext.Provider>
  );
};
