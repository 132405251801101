import styled from 'styled-components';
import colors from '../../components/common/colors';

export const ImageContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  > .ui.image {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid ${colors.bl_opacity_10};
    box-sizing: border-box;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .ui.button {
    width: 100% !important;
  }
`;
