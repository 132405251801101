import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';
import { StatusEnum } from '../common/enums';
import { ellipsisStyle } from '../common/text';

interface CommonDropDownStyleProps {
  status?: StatusEnum;
  labelcolor?: string;
  labelbackground?: string;
  labeldeletecolor?: string;
}

export const CommonDropDownStyle = styled(Dropdown)<CommonDropDownStyleProps>`
  &&& {
    min-height: 40px !important;
    padding: 8px 12px !important;
    background: ${colors.background_light_100} !important;
    border: 1px solid
      ${(props) => (props.status === StatusEnum.ERROR ? colors.line_error : colors.line_black_90)} !important;
    border-radius: 4px !important;
    ${font.body_m_m_regular(true)};

    & > .default.text {
      ${ellipsisStyle};
      padding: 9px 32px 8px 12px !important;
      margin: 0px !important;
      height: 100% !important;
      color: ${colors.text_placeholder} !important;
    }

    &:hover {
      border: 1px solid
        ${(props) => (props.status === StatusEnum.ERROR ? colors.line_error : colors.line_primary)} !important;
    }

    &:focus {
      border: 1px solid
        ${(props) => (props.status === StatusEnum.ERROR ? colors.line_error : colors.line_primary)} !important;
    }

    &.active {
      border: 1px solid ${colors.brand_dark_04} !important;
    }

    &.disabled {
      opacity: 0.45;
    }

    input {
      ${font.body_m_m_regular(true)};
      color: ${colors.text_active};
      margin: 10px 0px 0px 0px !important;
      padding: 0px !important;
    }

    .dropdown.icon {
      color: #333;
      right: 10px;
      opacity: 1 !important;
    }

    & > .clear.icon:before {
      font-family: 'Font Awesome 6 Free';
      content: '\\f057';
    }

    .label {
      display: inline-flex;
      align-items: center;
      box-shadow: none !important;
      height: auto !important;
      border-radius: 4px;
      border: none;
      background: ${colors.yellow_90};
      color: ${colors.yellow_50};
      ${font.label_label_xs_strong()};
      margin: 7px 4px 3px 0px;
      padding: 4px 8px;

      &.active {
        background: ${colors.yellow_90};
        color: ${colors.yellow_50};
      }

      & > .delete.icon {
        margin-left: 8px;
        opacity: 1 !important;
        align-self: center;
        height: auto !important;
        font-size: 12px;
        font-family: 'Font Awesome 6 Free';
      }

      & > .delete.icon:before {
        content: '\\f057';
        color: ${colors.yellow_70};
        opacity: 1 !important;
      }
    }

    .greylabel {
      background: ${colors.neutral_90} !important;
      color: ${colors.text_label_default} !important;

      & > .delete.icon:before {
        color: ${colors.bl_opacity_30} !important;
      }
    }

    .greenlabel {
      background: ${colors.green_90} !important;
      color: ${colors.green_50} !important;

      & > .delete.icon:before {
        color: ${colors.green_70} !important;
      }
    }

    &.single-label {
      .label {
        background: transparent;
        color: ${colors.text_active};
        ${font.body_m_m_regular()};
        padding: 0px;

        & > .delete.icon {
          display: none;
        }
      }
    }

    &.ui.selection.dropdown {
      padding: 0px 32px 4px 12px !important;
      margin: 0px !important;
    }

    .menu {
      box-sizing: border-box !important;
      border: 1px solid ${colors.brand_dark_04} !important;
      border-top: none !important;
      background: ${colors.background_light_100} !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08) !important;

      .selected.item {
        border-top: 1px solid ${colors.neutral_95} !important;
        background: ${colors.neutral_95} !important;
      }

      .item {
        border-top: 1px solid ${colors.neutral_95} !important;
        display: flex !important;
        align-items: center !important;
        padding: 0px 12px !important;
        height: 40px !important;
        color: ${colors.text_active};
        ${font.body_s_s_regular()};

        &:hover {
          background: ${colors.bl_opacity_5} !important;
        }
      }
    }
  }
`;
