import React, { useEffect, useRef, useState } from 'react';
import { FilterContainer, FilterDropDownStyle, ScrollableDiv } from './FilterDropDownStyle';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { DropdownMenu } from 'semantic-ui-react';
import { CommonButton } from '../button/CommonButton';
import { ColorEnum, SizeEnum } from '../common/enums';
import DatePicker from 'react-datepicker';
import { convertDate } from '../common/utils';

interface FilterDropDownProps {
  name: string;
  onDateChange: (dates: [Date | null, Date | null]) => void;
  time?: boolean;
  selected?: [Date | null, Date | null];
}

export const FilterDropDownDatePicker: React.FC<FilterDropDownProps> = ({
  name,
  onDateChange,
  time = false,
  selected = [null, null],
}) => {
  registerLocale('ko', ko);
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [dates, setDates] = useState<[Date | null, Date | null]>(selected);
  const [startDate, endDate] = dates;

  return (
    <FilterContainer
      ref={ref}
      $active={startDate !== null}
      tabIndex={0}
      onClick={() => {
        setIsOpened(true);
      }}
    >
      {name}
      <FilterDropDownStyle
        open={isOpened}
        clearable
        compact
        inline
        multiple
        onClick={() => {
          setIsOpened(true);
        }}
        closeOnChange={dates[0] != null}
        text={
          dates[0]
            ? `\u00A0:\u00A0${convertDate(dates[0], 'yyyy-MM-dd')} ~ ${convertDate(dates[1] ?? new Date(), 'yyyy-MM-dd')}`
            : ''
        }
      >
        <DropdownMenu>
          <ScrollableDiv className='full'>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(dates) => {
                setDates(dates as [Date | null, Date | null]);
                onDateChange(dates as [Date | null, Date | null]);
              }}
              showTimeSelect={time}
              locale='ko'
              dateFormat='yyyy-MM-dd'
              timeFormat={time ? 'aa hh:mm' : undefined}
              timeIntervals={time ? 30 : undefined}
              inline
            />
          </ScrollableDiv>
          <div className='bottom'>
            <CommonButton
              onClick={() => {
                setDates([null, null]);
                onDateChange([null, null]);
              }}
              disabled={dates[0] === null}
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='필터 지우기'
            />
          </div>
        </DropdownMenu>
      </FilterDropDownStyle>
      <i className='icon fas fa-caret-down' />
    </FilterContainer>
  );
};
