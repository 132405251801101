import React from 'react';
import { CommonButtonStyle } from './CommonButtonStyle';
import { font } from '../common/font';
import colors from '../common/colors';
import { SizeEnum, ColorEnum } from '../common/enums';

interface CommonButtonProps {
  sizeinfo?: SizeEnum;
  colorinfo?: ColorEnum;
  [key: string]: any;
}

export const CommonButton: React.FC<CommonButtonProps> = ({
  sizeinfo = SizeEnum.M,
  colorinfo = ColorEnum.DEFAULT,
  content,
  icon,
  ...props
}) => {
  const getSize = () => {
    switch (sizeinfo) {
      case SizeEnum.XS:
        return {
          height: 28,
          padding: 12,
          font: font.label_label_xs_strong,
        };
      case SizeEnum.S:
        return {
          height: 32,
          padding: 12,
          font: font.label_label_s_strong,
        };
      case SizeEnum.L:
        return {
          height: 40,
          padding: 20,
          font: font.label_label_m_strong,
        };
      case SizeEnum.XL:
        return {
          height: 48,
          padding: 24,
          font: font.label_label_l_strong,
        };
      case SizeEnum.M:
      default:
        return {
          height: 36,
          padding: 20,
          font: font.label_label_s_strong,
        };
    }
  };

  const getColor = () => {
    switch (colorinfo) {
      case ColorEnum.PRIMARY:
        return {
          color: colors.text_active,
          active_color: colors.brand_primary,
          hover_color: colors.brand_dark_05,
          selected_color: colors.brand_dark_04,
        };
      case ColorEnum.GRAY:
        return {
          color: colors.text_active,
          active_color: colors.neutral_90,
          hover_color: colors.neutral_80,
          selected_color: colors.neutral_70,
        };
      case ColorEnum.BORDERGRAY:
        return {
          color: colors.text_active,
          border_color: colors.neutral_80,
          hover_color: colors.neutral_60,
          selected_color: colors.neutral_50,
        };
      case ColorEnum.BORDERPRIMARY:
        return {
          color: colors.text_primary_dark,
          border_color: colors.brand_dark_05,
          hover_color: colors.brand_dark_04,
          selected_color: colors.brand_dark_03,
        };
      case ColorEnum.DEFAULT:
      default:
        return {
          color: colors.text_active,
          active_color: 'transparent',
          hover_color: colors.bl_opacity_5,
          selected_color: colors.bl_opacity_10,
        };
    }
  };
  return (
    <CommonButtonStyle active sizeinfo={getSize()} colorinfo={getColor()} {...props}>
      {icon ? <i className={icon} /> : null}
      {content}
    </CommonButtonStyle>
  );
};
