import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postAdminVerify } from '../../api/NoitApi';
import { useDialog } from '../../contexts/DialogContext';
import * as ROUTES from '../../routes/Routes';
import logger from '../../components/common/logger';
import { useAuth } from '../../contexts/AuthContext';

const Home: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLogin } = useAuth();
  const { openDialog, closeDialog } = useDialog();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const token = queryParams.get('token');
  const id = queryParams.get('id');
  const serviceId = queryParams.get('serviceId');

  const adminVerify = useCallback(() => {
    if (!token || !id || !serviceId) {
      return;
    }
    sessionStorage.setItem(`processed-${serviceId}-${id}`, 'true');
    postAdminVerify(parseInt(serviceId), parseInt(id), token)
      .then(() => {
        navigate(ROUTES.MY_BACK_OFFICE);
      })
      .catch((error) => {
        logger.error('Error verifying admin', error);
        openDialog({
          title: 'NOIT 계정 생성',
          content: '유효하지않은 토큰입니다.\n다시 시도해 주세요.',
          isConfirmOnly: true,
          onConfirm: () => {
            closeDialog();
          },
        });
      });
  }, [token, id, serviceId, navigate, openDialog, closeDialog]);

  useEffect(() => {
    const isAlreadyProcessed = sessionStorage.getItem(`processed-${serviceId}-${id}`);
    if (!isLogin) return;

    if (isAlreadyProcessed) {
      navigate(ROUTES.MY_BACK_OFFICE);
      return;
    }
    adminVerify();
  }, [adminVerify, id, serviceId, isLogin, navigate]);

  return (
    <div>
      <h1>Home</h1>
    </div>
  );
};

export default Home;
