import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { getService } from '../api/NoitApi';
import { Service } from '../api/models/ServiceModels';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../routes/Routes';
import { useAuth } from './AuthContext';

interface ServiceContextType {
  serviceId: number | null;
  setServiceId: React.Dispatch<React.SetStateAction<number | null>>;
  service: Service | null;
  setService: React.Dispatch<React.SetStateAction<Service | null>>;
}

export const ServiceContext = createContext<ServiceContextType | null>(null);

export const ServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const [serviceId, setServiceId] = useState<number | null>(null);
  const [service, setService] = useState<Service | null>(null);
  const navigate = useNavigate();
  const { isLogin } = useAuth();

  const fetchServices = useCallback(async () => {
    if (!serviceId || !isLogin) {
      setService(null);
      return;
    }

    try {
      const service = await getService(serviceId);
      setService(service);
    } catch (e) {
      console.error(e);
      navigate(ROUTES.MY_BACK_OFFICE, { state: { alert: 'serviceNotFound' } });
      setService(null);
    }
  }, [serviceId, setService, navigate, isLogin]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const value = {
    serviceId,
    setServiceId,
    service,
    setService,
  };

  return <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>;
};

export const useService = () => {
  const context = useContext(ServiceContext);
  if (!context) {
    throw new Error('useServices must be used within a ServiceProvider');
  }
  return context;
};
