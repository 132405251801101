import React, { useEffect } from 'react';
import { Container, Popup, PopupTitle, PopupContent, PopupButton } from './CommonDialogPopupStyle';
import { CommonButton } from '../button/CommonButton';
import { SizeEnum, ColorEnum, StatusEnum } from '../common/enums';
import { useDialog } from '../../contexts/DialogContext';
import { InputStatuses, useInputStatusManager } from '../../hooks/useInputStatusManager';
import { TitleWithComponent } from '../form/TitleWithComponent';
import { CommonInput } from '../input/CommonInput';

export interface DialogPopupProps {
  title?: string;
  subtitle?: React.ReactNode;
  content?: string;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: (initialValue: InputStatuses | null) => void;
  isOpen?: boolean;
  isConfirmOnly?: boolean;
  initialValue?: InputStatuses;
  status?: StatusEnum;
}

export const CommonDialogPopup: React.FC<DialogPopupProps> = ({
  title,
  subtitle,
  content,
  cancelText = '취소',
  confirmText = '확인',
  onCancel,
  onConfirm,
  isOpen = false,
  isConfirmOnly = false,
  initialValue,
  status = StatusEnum.DEFAULT,
}) => {
  const { closeDialog } = useDialog();

  const { inputStatuses, setInputStatuses, updateInputStatus, checkAllValid } =
    useInputStatusManager(initialValue || {});

  useEffect(() => {
    if (initialValue) {
      setInputStatuses(initialValue);
    }
  }, [initialValue, setInputStatuses]);

  const renderedInput =
    inputStatuses &&
    Object.keys(inputStatuses).map((key) => {
      const config = inputStatuses[key];
      return (
        <TitleWithComponent
          status={config.status}
          errorStr={config.errorMessage}
          title={key}
          isImport={config.required}
        >
          <CommonInput
            key={key}
            name={key}
            disabled={status === StatusEnum.DISABLED}
            value={config.value}
            status={config.status}
            onDataChange={(value) => {
              updateInputStatus({ [key]: { value: value } });
            }}
          />
        </TitleWithComponent>
      );
    });

  return (
    <Container $isOpen={isOpen}>
      <Popup>
        <PopupTitle>
          {title}
          <span className='subtitle'>{subtitle}</span>
        </PopupTitle>
        <PopupContent>
          {content}
          {renderedInput}
        </PopupContent>
        <PopupButton>
          {!isConfirmOnly && (
            <CommonButton
              sizeinfo={SizeEnum.M}
              colorinfo={ColorEnum.DEFAULT}
              content={cancelText}
              onClick={() => {
                closeDialog();
                if (onCancel) {
                  onCancel();
                }
              }}
            />
          )}
          {onConfirm && (
            <CommonButton
              disabled={status === StatusEnum.DISABLED}
              sizeinfo={SizeEnum.M}
              colorinfo={ColorEnum.PRIMARY}
              content={confirmText}
              onClick={() => {
                if (checkAllValid()) {
                  onConfirm(inputStatuses ? inputStatuses : null);
                }
              }}
            />
          )}
        </PopupButton>
      </Popup>
    </Container>
  );
};
