import styled from 'styled-components';
import { AccordionTitle, AccordionContent } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';

export const AccordionTitleStyle = styled(AccordionTitle)`
  &&& {
    display: flex;
    height: 36px;
    padding: 0px 8px !important;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    border-radius: 8px;
    background: ${(props) => (props.active ? colors.brand_light_03 : 'transparent')} !important;
    color: ${(props) => (props.active ? colors.text_primary_dark : colors.text_active)} !important;
    ${font.body_s_s_regular(true)};

    &:hover {
      background: ${colors.bl_opacity_5} !important;
      color: ${colors.text_active} !important;
    }

    .righticon {
      text-align: center;
      color: ${(props) => (props.active ? colors.text_primary_dark : colors.icon_light)} !important;
      width: 12px;
      height: 12px;
      margin-left: auto;
      font-size: 12px;
    }
  }
`;

export const AccordionContentStyle = styled(AccordionContent)`
  &&& {
    display: flex;
    height: auto;
    padding: 0px 8px !important;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    width: 100% !important;
    background: transparent !important;

    .ui.button {
      align-items: center;
      height: 32px;
      padding: 0px 0px 0px 36px !important;
      margin: 0px !important;
      width: 100% !important;
      display: flex !important;
      background: transparent !important;
      box-shadow: none !important;
      color: ${colors.text_active} !important;
      ${font.body_s_s_regular(true)};

      &:active {
        color: ${colors.text_primary_dark} !important;
        .righticon {
          color: ${colors.text_primary_dark} !important;
        }
      }

      .righticon {
        text-align: center;
        color: ${colors.icon_light} !important;
        width: 12px;
        height: 12px;
        margin-left: auto;
        font-size: 12px;
      }
    }

    .ui.active.button {
      color: ${colors.text_primary_dark} !important;
      .righticon {
        color: ${colors.text_primary_dark} !important;
      }
    }
  }
`;
