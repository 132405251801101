import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IntroMainLayout from './layouts/intro/IntroMainLayout';
import { CMSRouteConfig, IntroRouteConfig } from './routes/RouteConfig';
import { DialogProvider } from './contexts/DialogContext';
import ProtectedRoute from './routes/ProtectedRoute';
import LoadingIndicator from './components/common/LoadingIndicator';
import { AlertProvider } from './contexts/AlertContext';
import CMSMainLayout from './layouts/cms/CMSMainLayout';
import { AddButtonProvider } from './contexts/AddButtonContext';
import { ServiceProvider } from './contexts/ServiceContext';
import { MemberProvider } from './contexts/MemberContext';

function App() {
  return (
    <>
      <AlertProvider>
        <DialogProvider>
          <AddButtonProvider>
            <Router>
              <ServiceProvider>
                <MemberProvider>
                  <Routes>
                    <Route path='/cms/:serviceId/*' element={<CMSMainLayout />}>
                      {CMSRouteConfig.map(({ path, component }) => (
                        <Route
                          key={path}
                          path={path}
                          element={<ProtectedRoute needsService={true} component={component} />}
                        />
                      ))}
                    </Route>
                    <Route path='/' element={<IntroMainLayout />}>
                      {IntroRouteConfig.map(({ path, component, requiresAuth }) => (
                        <Route
                          key={path}
                          path={path}
                          element={
                            requiresAuth ? (
                              <ProtectedRoute component={component} />
                            ) : (
                              React.createElement(component)
                            )
                          }
                        />
                      ))}
                    </Route>
                  </Routes>
                </MemberProvider>
              </ServiceProvider>
            </Router>
          </AddButtonProvider>
        </DialogProvider>
      </AlertProvider>
      <LoadingIndicator />
    </>
  );
}

export default App;
