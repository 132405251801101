import styled from 'styled-components';
import colors from '../common/colors';
import { font } from '../common/font';
import { wrapTextStyle } from '../common/text';

export const Container = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const InfoViewStyle = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0px;

  border-radius: 8px;
  border: 1px solid ${colors.line_black_opacity_10};

  .title {
    flex: 1 0 0;
    display: flex;
    padding: 12px;
    align-items: center;
    align-self: stretch;

    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid ${colors.line_black_opacity_10};
    background: ${colors.background_light_95};

    color: ${colors.text_active};
    ${font.body_s_s_strong(true)};
  }

  .info {
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    .item {
      .description {
        .content {
          width: 100% !important;
          .text {
            ${wrapTextStyle} !important;
          }
        }
      }
    }
  }
`;

export const HistoryStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  color: ${colors.text_active_light};
  ${font.body_xs_xs_regular(true)};
  ${wrapTextStyle} !important;
`;

export const ProfileImageHistoryStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 180px;

  .profile-image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    > .ui.image {
      width: 180px;
      height: 180px;
      border-radius: 12px;
      border: 1px solid ${colors.line_black_opacity_10};
      object-fit: cover;
    }

    > .ui.button {
      display: 'flex';
      width: 100%;
    }
  }
`;
