import React, { useState, useRef, useEffect } from 'react';
import {
  FilterContainer,
  FilterDropDownStyle,
  FilterLabel,
  FilterCheckbox,
  ScrollableDiv,
} from './FilterDropDownStyle';
import { CommonButton } from '../button/CommonButton';
import {
  DropdownMenu,
  Input,
  CheckboxProps,
  InputOnChangeData,
  DropdownItemProps,
} from 'semantic-ui-react';
import { SizeEnum, ColorEnum } from '../common/enums';

interface FilterDropDownProps {
  name: string;
  limit?: number;
  options: DropdownItemProps[];
  onSelectedChange?: (selectedItems: DropdownItemProps[]) => void;
}

export const FilterDropDown: React.FC<FilterDropDownProps> = ({
  name,
  limit = 999,
  options,
  onSelectedChange,
}) => {
  const [searchOptions, setSearchOptions] = useState<DropdownItemProps[]>(options);
  const [selectedItems, setSelectedItems] = useState<DropdownItemProps[]>([]);
  const [isOpened, setIsOpened] = useState(false);

  const handleSearchChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    if (data.value === '') {
      setSearchOptions(options);
      return;
    }
    setSearchOptions(
      options.filter((option) =>
        (option.content ?? option.text ?? '')
          .toString()
          .toLowerCase()
          .includes(data.value.toLowerCase())
      )
    );
  };

  const handleChange = (_event: React.MouseEvent<HTMLInputElement>, data: CheckboxProps) => {
    let result: DropdownItemProps[] = [];

    if (limit === 1) {
      result = data.checked ? [{ value: data.value, text: data.label as string }] : [];
    } else {
      if (data.checked && selectedItems.length < limit) {
        result = [...selectedItems, { value: data.value, text: data.label as string }];
      } else if (!data.checked) {
        result = selectedItems.filter((item) => item.value !== data.value);
      }
    }
    setSelectedItems(result);

    if (onSelectedChange) {
      onSelectedChange(result);
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FilterContainer
      ref={ref}
      $active={selectedItems.length > 0}
      tabIndex={0}
      onClick={() => {
        setIsOpened(true);
      }}
    >
      {name}
      <FilterDropDownStyle
        open={isOpened}
        clearable
        compact
        inline
        multiple
        onClick={() => {
          setIsOpened(true);
        }}
        closeOnChange={limit <= 1 ? true : false}
        text={selectedItems.length > 0 ? '\u00A0:\u00A0' + selectedItems[0].text : ''}
        noResultsMessage='결과가 없습니다.'
      >
        <DropdownMenu>
          <Input placeholder='검색' icon='search' onChange={handleSearchChange} />
          <ScrollableDiv>
            {searchOptions.length > 0 ? (
              searchOptions.map((option) => (
                <FilterCheckbox
                  key={option.value as string}
                  value={option.value}
                  label={option.text}
                  onClick={handleChange}
                  checked={selectedItems.some((item) => item.value === option.value)}
                />
              ))
            ) : (
              <div className='centered-message'>결과가 없습니다.</div>
            )}
          </ScrollableDiv>
          <div className='bottom'>
            <CommonButton
              onClick={() => {
                setSelectedItems([]);
                if (onSelectedChange) {
                  onSelectedChange([]);
                }
              }}
              disabled={selectedItems.length === 0}
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERGRAY}
              content='필터 지우기'
            />
          </div>
        </DropdownMenu>
      </FilterDropDownStyle>
      {selectedItems.length > 1 && <FilterLabel content={`+${selectedItems.length - 1}`} />}
      <i className='icon fas fa-caret-down' />
    </FilterContainer>
  );
};
