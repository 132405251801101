import React, { useEffect, useMemo, useState } from 'react';
import {
  AddEditPopupLayout,
  AddEditPopupLayoutProps,
} from '../../../components/popup/layout/AddEditPopupLayout';
import { TermsDetail, TermsForm } from '../../../api/models/ServiceModels';
import { useAlert } from '../../../contexts/AlertContext';
import {
  convertDate,
  convertDateFormat,
  convertDateToISOSting,
} from '../../../components/common/utils';
import { InputStatuses, useInputStatusManager } from '../../../hooks/useInputStatusManager';
import { AlertTypeEnum } from '../../../components/common/enums';
import { postTerms, putTerms } from '../../../api/NoitApi';
import { TitleWithComponent } from '../../../components/form/TitleWithComponent';
import { InputDatePicker } from '../../../components/input/InputDatePicker';
import { CommonTextArea } from '../../../components/textArea/CommonTextArea';
import { CommonInput } from '../../../components/input/CommonInput';
import { CommonDropDown } from '../../../components/dropDown/CommonDropDown';
import { PlatformEnum, PlatformDisplayName } from '../../../components/common/enums';
import { useDialog } from '../../../contexts/DialogContext';
import { useService } from '../../../contexts/ServiceContext';

interface TermsPopupProps extends AddEditPopupLayoutProps {
  data?: TermsDetail | null;
}

export const TermsPopup: React.FC<TermsPopupProps> = ({
  data,
  isPopupOpen,
  setIsPopupOpen,
  onConfirm,
}) => {
  const { service } = useService();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const { triggerAlert } = useAlert();
  const { openDialog, closeDialog } = useDialog();

  const initioalData = useMemo((): InputStatuses => {
    return {
      플랫폼: { required: true, value: data?.platforms ? data.platforms.sort() : [] },
      약관명: { required: true, value: data?.name ?? '' },
      '약관 내용': { required: true, value: data?.content ?? '' },
      '약관 동의 상태': {
        required: true,
        value: !data ? [] : (data?.requiredFlag ?? false) ? ['필수 동의'] : ['선택 동의'],
      },
      '개정 및 시행일': {
        errorMessage: '개정 및 시행일은 현재보다 과거일 수 없습니다.',
        value: data?.applyDatetime ? convertDateFormat(data?.applyDatetime, 'yyyy-MM-dd') : '',
      },
      '관리용 버전명': { value: data?.version ?? '' },
    };
  }, [data]);

  const { inputStatuses, updateInputStatus, setStatus, setInputStatuses, checkAllValid } =
    useInputStatusManager(initioalData);

  useEffect(() => {
    if (isPopupOpen) {
      setInputStatuses(initioalData);
    }
  }, [isPopupOpen, setInputStatuses, initioalData]);

  const handleDateChange = (date: Date) => {
    const now = new Date();
    if (date >= now) {
      updateInputStatus({ '개정 및 시행일': { value: convertDate(date, 'yyyy-MM-dd') } });
      setShowDatePicker(false);
    } else {
      triggerAlert({
        msg: inputStatuses['개정 및 시행일'].errorMessage!,
        type: AlertTypeEnum.ERROR,
      });
    }
  };

  const handleSubmit = () => {
    if (!service) {
      return;
    }

    if (!checkAllValid()) {
      return;
    }

    const termsForm: TermsForm = {
      name: inputStatuses['약관명'].value as string,
      content: inputStatuses['약관 내용'].value as string,
      requiredFlag: inputStatuses['약관 동의 상태'].value.includes('필수 동의'),
      applyDatetime:
        (inputStatuses['개정 및 시행일'].value !== ''
          ? convertDateToISOSting(inputStatuses['개정 및 시행일'].value as string)
          : convertDateToISOSting(convertDate(new Date(), 'yyyy-MM-dd'))) ?? '',
      platforms: inputStatuses['플랫폼'].value as string[],
      version:
        (inputStatuses['관리용 버전명'].value as string).trim().length > 0
          ? (inputStatuses['관리용 버전명'].value as string)
          : null,
      useFlag: true,
    };

    const done = (terms: TermsDetail) => {
      onConfirm && onConfirm(terms);
    };

    if (data) {
      setIsPopupOpen(false);
      putTerms(service.id, data.id, termsForm).then((terms) => {
        done(terms);
      });
    } else {
      setIsPopupOpen(false);
      postTerms(service.id, termsForm).then((terms) => {
        done(terms);
      });
    }
  };

  return (
    <AddEditPopupLayout
      title='약관'
      isPopupOpen={isPopupOpen}
      setIsPopupOpen={setIsPopupOpen}
      data={data}
      onConfirm={handleSubmit}
    >
      {inputStatuses && (
        <>
          <TitleWithComponent title='플랫폼' isImport>
            <CommonDropDown
              limit={3}
              name='플랫폼'
              placeholder='플랫폼을 선택해 주세요.'
              options={[
                {
                  key: PlatformEnum.IOS,
                  text: PlatformDisplayName(PlatformEnum.IOS),
                  value: PlatformEnum.IOS,
                  labelname: 'greylabel',
                },
                {
                  key: PlatformEnum.ANDROID,
                  text: PlatformDisplayName(PlatformEnum.ANDROID),
                  value: PlatformEnum.ANDROID,
                  labelname: 'greenlabel',
                },
                {
                  key: PlatformEnum.WEB,
                  text: PlatformDisplayName(PlatformEnum.WEB),
                  value: PlatformEnum.WEB,
                },
              ]}
              value={inputStatuses['플랫폼'].value}
              status={inputStatuses['플랫폼'].status}
              setStatus={setStatus}
              onDataChange={(value) => {
                updateInputStatus({ 플랫폼: { value: value as string[] } });
              }}
            />
          </TitleWithComponent>
          <TitleWithComponent
            status={inputStatuses['약관명'].status}
            errorStr={inputStatuses['약관명'].errorMessage}
            title='약관명'
            isImport
          >
            <CommonInput
              name='약관명'
              value={inputStatuses['약관명'].value}
              status={inputStatuses['약관명'].status}
              setStatus={setStatus}
              onDataChange={(value) => {
                updateInputStatus({ 약관명: { value: value } });
              }}
            />
          </TitleWithComponent>
          <TitleWithComponent
            status={inputStatuses['약관 내용'].status}
            errorStr={inputStatuses['약관 내용'].errorMessage}
            isImport
            title='약관 내용'
          >
            <CommonTextArea
              name='약관 내용'
              setStatus={setStatus}
              status={inputStatuses['약관 내용'].status}
              value={inputStatuses['약관 내용'].value as string}
              onDataChange={(value) => {
                updateInputStatus({ '약관 내용': { value: value } });
              }}
            />
          </TitleWithComponent>
          <TitleWithComponent title='약관 동의 상태' isImport>
            <CommonDropDown
              name='약관 동의 상태'
              status={inputStatuses['약관 동의 상태'].status}
              setStatus={setStatus}
              placeholder='약관 동의 상태를 선택해 주세요.'
              options={[
                { key: '필수 동의', text: '필수 동의', value: '필수 동의' },
                { key: '선택 동의', text: '선택 동의', value: '선택 동의' },
              ]}
              multiple={true}
              value={inputStatuses['약관 동의 상태'].value}
              onDataChange={(value) => {
                if (
                  data &&
                  inputStatuses['약관 동의 상태'].value !== value &&
                  value.includes('필수 동의')
                ) {
                  openDialog({
                    title: '중요 안내',
                    content:
                      '필수 동의로 변경 시 약관을 동의하지 않은 회원에게\n동의 안내 알림이 전송되며, 동의하지 않을 경우\n서비스를 이용할 수 없게 됩니다.',
                    confirmText: '확인했습니다.',
                    onConfirm: () => {
                      closeDialog();
                      updateInputStatus({
                        '약관 동의 상태': { value: value },
                      });
                    },
                  });
                } else {
                  updateInputStatus({
                    '약관 동의 상태': { value: value },
                  });
                }
              }}
            />
          </TitleWithComponent>
          <TitleWithComponent
            hintStr='*시행일의 경우 별도 날짜를 설정할 수 있으며, 시행일 이후 부터 서비스에서 해당 약관이 표시됩니다.'
            title='개정 및 시행일'
          >
            <InputDatePicker
              name='개정 및 시행일'
              time={false}
              status={inputStatuses['개정 및 시행일'].status}
              setStatus={setStatus}
              value={inputStatuses['개정 및 시행일'].value as string}
              onDateChange={handleDateChange}
              setShowDatePicker={setShowDatePicker}
              showDatePicker={showDatePicker}
              placeholder='개정 및 시행일을 선택해 주세요.'
            />
          </TitleWithComponent>
          <TitleWithComponent title='관리용 버전명'>
            <CommonInput
              name='관리용 버전명'
              value={inputStatuses['관리용 버전명'].value}
              onDataChange={(value) => {
                updateInputStatus({ '관리용 버전명': { value: value } });
              }}
            />
          </TitleWithComponent>
        </>
      )}
    </AddEditPopupLayout>
  );
};
