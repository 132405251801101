import React from 'react';
import { LoginStyle } from './LoginStyle';
import { ClickableImage } from '../../../components/EtcStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { Images } from '../../../assets/images/images';
import { postAdmin } from '../../../api/NoitApi';

export const JoinStep2: React.FC = () => {
  const navigate = useNavigate();
  const email = new URLSearchParams(useLocation().search).get('email') || '';
  const resendEmail = () => {
    if (!email) return;

    postAdmin(email)
      .then(() => {
        alert(`${email}로 가입 인증 메일을 전송했습니다.`);
      })
      .catch(() => {
        alert(`이메일 전송에 실패했습니다.\n잠시 후 다시 시도해 주세요.`);
      });
  };

  return (
    <LoginStyle>
      <ClickableImage src={Images.intro_logo_black} onClick={() => navigate(ROUTES.HOME)} />
      <div className='content'>
        <div className='title'>
          이메일을 확인해 주세요
          <div className='sub_group'>
            <div className='sub'>
              <span className='bold'>{email}</span>로 가입 인증 메일을 전송했습니다.
            </div>
            <div className='sub'>메일함을 확인하여 계정 생성을 진행해 주세요.</div>
          </div>
        </div>

        <div className='join' onClick={resendEmail}>
          가입 인증 메일을 받지 못하셨나요?
          <span className='create'>인증 메일 재전송</span>
        </div>

        <div className='join' onClick={() => navigate(-1)}>
          {`*재전송 후에도 확인이 되지 않을 경우 스팸함 확인\n및 이메일 주소가 올바르게 입력 되었는지 확인해 주세요!`}
          <span className='create'>이메일 재입력</span>
        </div>
      </div>
    </LoginStyle>
  );
};

export default JoinStep2;
