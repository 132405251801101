import React, { useState } from 'react';
import { MoreMenuStyle, MoreMenuContainer } from './MoreMenuStyle';
import { LNB } from '../dropDown/LNB';

interface MoreMenuProps {
  menuItems?: { label: string; onClick: () => void }[];
}

export const MoreMenu: React.FC<MoreMenuProps> = ({ menuItems = [] }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <MoreMenuContainer>
      <MoreMenuStyle className='fa-solid fa-ellipsis-vertical' onClick={handleOpen} />
      <LNB open={open} setOpen={setOpen} menuItems={menuItems} />
    </MoreMenuContainer>
  );
};
