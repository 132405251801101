import React from 'react';
import { StatusEnum } from '../common/enums';
import { CommonInputStyle, ButtonStyle, IconStyle } from './CommonInputStyle';
import colors from '../common/colors';
import { InputOnChangeData, InputProps } from 'semantic-ui-react';
import { useInputStatusManagerProps } from '../../hooks/useInputStatusManager';

export interface CommonInputProps extends InputProps, useInputStatusManagerProps {
  lefticon?: string;
  onLeftIconClick?: (value: string) => void;
  leadingRadius?: number;
  trailingRadius?: number;
  customFocus?: () => void;
  customBlur?: () => void;
  righticon?: boolean;
}

export const CommonInput: React.FC<CommonInputProps> = ({
  name,
  setStatus,
  status = StatusEnum.DEFAULT,
  lefticon,
  onLeftIconClick,
  leadingRadius = 4,
  trailingRadius = 4,
  customFocus,
  customBlur,
  onDataChange,
  righticon = true,
  ...props
}) => {
  const getStatusColors = () => {
    switch (status) {
      case StatusEnum.FOCUS:
        return {
          border_color: colors.line_primary,
          placeholder_color: colors.text_focus,
          icon_color: colors.icon_input_default,
        };
      case StatusEnum.ERROR:
        return {
          placeholder_color: colors.text_placeholder,
          border_color: colors.line_error,
          icon_color: colors.icon_error,
        };
      case StatusEnum.SUCCESS:
        return {
          placeholder_color: colors.text_placeholder,
          border_color: colors.line_success,
          icon_color: colors.icon_success,
        };

      default:
        return {
          border_color: colors.line_black_90,
          icon_color: colors.icon_input_default,
          placeholder_color: colors.text_placeholder,
        };
    }
  };

  const handleFocus = () => {
    if (customFocus != null) {
      customFocus();
    }

    if (status === StatusEnum.DEFAULT && setStatus != null && name != null) {
      setStatus(name, StatusEnum.FOCUS);
    }
  };

  const { border_color, placeholder_color, icon_color } = getStatusColors();
  return (
    <CommonInputStyle
      className={props.className}
      status={status}
      placeholder={name && !props.placeholder ? `${name}을(를) 입력해 주세요.` : props.placeholder}
      leading_radius={leadingRadius}
      trailing_radius={trailingRadius}
      left_icon={lefticon}
      right_icon={
        righticon && (status === StatusEnum.ERROR || status === StatusEnum.SUCCESS)
          ? 'Y'
          : undefined
      }
      border_color={border_color}
      placeholder_color={placeholder_color}
      actionPosition={lefticon ? 'left' : undefined}
      action={
        lefticon && (
          <ButtonStyle
            basic
            icon_color={icon_color}
            border_color={border_color}
            onClick={() => {
              if (onLeftIconClick != null) {
                onLeftIconClick(props.value as string);
              }
            }}
          >
            <i className={lefticon} style={{ width: '100%', color: icon_color }} />
          </ButtonStyle>
        )
      }
      icon={
        righticon &&
        (status === StatusEnum.ERROR || status === StatusEnum.SUCCESS) && (
          <IconStyle
            link={status === StatusEnum.ERROR}
            icon_color={icon_color}
            onClick={
              status === StatusEnum.ERROR
                ? () => {
                    onDataChange && onDataChange('');
                  }
                : undefined
            }
          >
            <i
              className={
                status === StatusEnum.ERROR
                  ? 'fa-regular fa-circle-xmark'
                  : 'fa-regular fa-circle-check'
              }
            />
          </IconStyle>
        )
      }
      onChange={(_: React.ChangeEvent<HTMLInputElement>, value: InputOnChangeData) => {
        if (onDataChange != null) {
          onDataChange(value.value);
        }
      }}
      onFocus={handleFocus}
      onBlur={() => {
        if (customBlur != null) {
          customBlur();
        }

        if (
          (status === StatusEnum.FOCUS || StatusEnum.DEFAULT) &&
          setStatus != null &&
          name != null
        ) {
          setStatus(name, StatusEnum.DEFAULT);
        }
      }}
      {...props}
    ></CommonInputStyle>
  );
};
