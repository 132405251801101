import React, { useEffect } from 'react';
import { Navigate, RouteProps, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import * as ROUTES from './Routes';
import { useService } from '../contexts/ServiceContext';

interface ProtectedRouteProps extends Omit<RouteProps, 'element'> {
  component: React.ElementType;
  needsService?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  needsService = false,
  ...rest
}) => {
  const location = useLocation();
  const { isLogin } = useAuth(); // 사용자 로그인 상태를 확인
  const { setServiceId } = useService();
  let { serviceId } = useParams<{ serviceId?: string }>();

  useEffect(() => {
    if (isLogin && serviceId && needsService) {
      setServiceId(parseInt(serviceId));
    } else {
      setServiceId(null);
    }
  }, [isLogin, serviceId, needsService, setServiceId]);

  // 로그인 상태가 아닌 경우
  if (!isLogin) {
    // 로그인 후 리다이렉트할 페이지 지정
    const redirectTo =
      needsService && !serviceId ? ROUTES.MY_BACK_OFFICE : `${location.pathname}${location.search}`;
    return <Navigate to={ROUTES.LOGIN} state={{ from: { pathname: redirectTo } }} replace />;
  }

  // needsService가 true이고, serviceId가 없는 경우
  if (needsService && !serviceId) {
    // 로그인이 되어 있으나 서비스 ID가 없는 경우, 마이백오피스로 리다이렉트
    return <Navigate to={ROUTES.MY_BACK_OFFICE} replace />;
  }

  // 로그인 상태면 요청한 컴포넌트를 렌더링
  return <Component {...rest} />;
};

export default ProtectedRoute;
