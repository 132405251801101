import styled, { keyframes } from 'styled-components';
import { Message } from 'semantic-ui-react';
import colors from '../common/colors';

const slideIn = keyframes`
    from {
        opacity: 0.5;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const slideOut = keyframes`
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0.5;
        transform: translateX(-110%);
    }
`;

interface AlertMessageStyleProps {
  $show: boolean;
}

export const AlertMessageStyle = styled(Message)<AlertMessageStyleProps>`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  position: fixed !important;
  width: 440px !important;
  white-space: pre-wrap;
  bottom: 40px;
  left: 40px;
  padding: 24px !important;
  z-index: 9999;
  animation: ${(props) => (props.$show ? slideIn : slideOut)} 0.5s forwards;
  border-radius: 8px !important;
  border: 1px solid ${colors.bl_opacity_10};
  background: ${colors.brand_light_05} !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12) !important;

  .ui.image {
    margin-right: 16px !important;
    display: inline-block !important;
  }

  .close {
    cursor: pointer;
    color: ${colors.icon_light};
    margin-left: 16px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
`;
