import { useCallback, useState } from 'react';
import { StatusEnum } from '../components/common/enums';

export interface useInputStatusManagerProps {
  name?: string;
  status?: StatusEnum;
  setStatus?: (inputName: string, value: StatusEnum) => void;
  onDataChange?: (value: string | string[]) => void;
}

export enum InputTypeEnum {
  INPUT,
  TEXTAREA,
  DROPDOWN,
  DATEPICKER,
}

interface InputConfig {
  type?: InputTypeEnum;
  disabled?: boolean;
  hint?: string;
  required?: boolean;
  maxLength?: number;
  limitLength?: number;
  customValidation?: (value: string) => string | null;
  errorMessage?: string;
  status?: StatusEnum;
  value: string | string[];
}

export interface InputStatuses {
  [key: string]: InputConfig;
}

export const useInputStatusManager = (initialConfig: InputStatuses) => {
  const [inputStatuses, setInputStatuses] = useState<InputStatuses>(initialConfig);

  const resetInputStatus = useCallback(() => {
    setInputStatuses(initialConfig);
  }, [initialConfig]);

  const checkAllValid = () => {
    let allValid = true;
    const newStatuses = { ...inputStatuses };

    for (const inputName in newStatuses) {
      const config = newStatuses[inputName];
      let newStatus = config.status ?? StatusEnum.DEFAULT;
      let errorMessage = config.errorMessage ?? '';
      let value = config.value;

      if (config.customValidation && config.customValidation(value as string)) {
        newStatus = StatusEnum.ERROR;
        errorMessage = config.customValidation(value as string) || '';
      } else if (config.maxLength && value.length > config.maxLength) {
        newStatus = StatusEnum.ERROR;
        errorMessage = `${config.maxLength}자 이내로 입력해 주세요.`;
      } else if (config.limitLength && value.length >= config.limitLength) {
        newStatus = StatusEnum.ERROR;
        errorMessage = `최대 ${config.limitLength}자까지 입력할 수 있습니다.`;
      } else if (
        !Array.isArray(config.value) &&
        config.required &&
        (value as string).trim().length === 0
      ) {
        newStatus = StatusEnum.ERROR;
        errorMessage = `${inputName}을(를) 입력해 주세요.`;
      } else if (Array.isArray(config.value) && config.required && value.length === 0) {
        newStatus = StatusEnum.ERROR;
        errorMessage = `${inputName}을(를) 선택해 주세요.`;
      }

      if (newStatus === StatusEnum.ERROR) {
        allValid = false;
      }
      newStatuses[inputName] = { ...config, status: newStatus, errorMessage: errorMessage };
    }

    setInputStatuses(newStatuses);
    return allValid;
  };

  const updateValue = useCallback(
    (inputName: string, value: string | string[], status?: StatusEnum, errorMessage?: string) => {
      const config = inputStatuses[inputName];
      setInputStatuses((prevStatuses) => ({
        ...prevStatuses,
        [inputName]: {
          ...config,
          value: value,
          status: status
            ? status
            : config.status === StatusEnum.ERROR
              ? StatusEnum.FOCUS
              : (config.status ?? StatusEnum.DEFAULT),
          errorMessage: errorMessage ?? config.errorMessage ?? '',
        },
      }));
    },
    [inputStatuses]
  );

  const updateInputStatus = useCallback(
    (updates: { [inputName: string]: { value: string | string[]; status?: StatusEnum } }) => {
      setInputStatuses((prevStatuses) => {
        const newStatuses = { ...prevStatuses };
        Object.entries(updates).forEach(([inputName, { value }]) => {
          const config = prevStatuses[inputName];
          let newStatus: StatusEnum =
            config.status === StatusEnum.ERROR
              ? StatusEnum.FOCUS
              : (config.status ?? StatusEnum.DEFAULT);
          let errorMessage = config.errorMessage ?? '';
          let newValue: string | string[] = Array.isArray(config.value)
            ? (config.value ?? [])
            : (config.value ?? '');

          if (config.maxLength && value.length > config.maxLength) {
            newStatus = StatusEnum.ERROR;
            errorMessage = `${config.maxLength}자 이내로 입력해 주세요.`;
          } else if (config.limitLength && value.length >= config.limitLength) {
            newStatus = StatusEnum.ERROR;
            errorMessage = `최대 ${config.limitLength}자까지 입력할 수 있습니다.`;
          } else {
            newValue = value;
          }

          if (config.customValidation && config.customValidation(value as string)) {
            newStatus = StatusEnum.ERROR;
            errorMessage = config.customValidation(value as string) || '';
          } else if (
            !Array.isArray(config.value) &&
            config.required &&
            (value as string).trim().length === 0
          ) {
            newStatus = StatusEnum.ERROR;
            errorMessage = `${inputName}을(를) 입력해 주세요.`;
          }

          if (Array.isArray(config.value)) {
            newStatus = StatusEnum.DEFAULT;

            if (config.required && value.length === 0) {
              newStatus = StatusEnum.ERROR;
              errorMessage = `${inputName}을(를) 선택해 주세요.`;
            }
          }

          newStatus = updates[inputName].status ?? newStatus;
          newStatuses[inputName] = {
            ...config,
            status: newStatus,
            errorMessage: errorMessage,
            value: newValue,
          };
        });
        return newStatuses;
      });
    },
    []
  );

  const setStatus = (inputName: string, status: StatusEnum, errorMessage?: string) => {
    const config = inputStatuses[inputName];
    setInputStatuses((prevStatuses) => ({
      ...prevStatuses,
      [inputName]: { ...config, status: status, errorMessage: errorMessage ?? config.errorMessage },
    }));
  };

  return {
    inputStatuses,
    resetInputStatus,
    updateInputStatus,
    setStatus,
    setInputStatuses,
    checkAllValid,
    updateValue,
  };
};
