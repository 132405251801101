import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '../../../contexts/DialogContext';
import { TermsDetail } from '../../../api/models/ServiceModels';
import { deleteTerms, getTerms, getTermsHistotyDetail } from '../../../api/NoitApi';
import { convertDateFormat } from '../../../components/common/utils';
import { CommonButton } from '../../../components/button/CommonButton';
import {
  ColorEnum,
  PlatformDisplayName,
  PlatformEnum,
  SizeEnum,
} from '../../../components/common/enums';
import { TermsDetailPageStyle } from './TermsDetailPageStyle';
import { InfoView } from '../../../components/view/InfoView';
import { HeaderListItem } from '../../../components/list/HeaderListItem';
import { Images } from '../../../assets/images/images';
import { CommonLabel } from '../../../components/label/CommonLabel';
import { TermsPopup } from './TermsPopup';
import { useService } from '../../../contexts/ServiceContext';
import CMSDetailLayout from '../../../layouts/cms/CMSDetailLayout';
import { useAuthorization } from '../../../hooks/useAuthorization';

const TermsDetailPage: React.FC = () => {
  let { service } = useService();
  const location = useLocation();
  const navigate = useNavigate();
  const { openDialog, closeDialog, openAuthDialog } = useDialog();

  const { termsId } = useParams<{ termsId: string }>();
  const [terms, setTerms] = useState<TermsDetail | null>(null);

  const isHistoryDetail = location.pathname.includes('history');
  const authDelete = useAuthorization('TERMS_DELETE', true);
  const authEdit = useAuthorization('TERMS_EDIT');

  useEffect(() => {
    if (!service) {
      return;
    }
    const fetchTerms = async () => {
      if (!service) {
        return;
      }
      try {
        const termsIdNum = parseInt(termsId!, 10);
        const termsData = isHistoryDetail
          ? await getTermsHistotyDetail(service.id, termsIdNum)
          : await getTerms(service.id, termsIdNum);
        setTerms(termsData);
      } catch (error) {
        console.error('Error fetching terms data', error);
      }
    };

    fetchTerms();
  }, [service, termsId, isHistoryDetail]);

  const getTermsHistory = () => {
    if (!terms) {
      return [];
    }
    const history = [
      convertDateFormat(isHistoryDetail ? terms.termsCreatedAt : terms.createdAt) + ' 추가됨',
    ];

    if (isHistoryDetail) {
      if (terms.termsRemovedBy && terms.termsRemovedAt) {
        history.push(
          convertDateFormat(terms.termsRemovedAt) + ' 삭제됨(' + terms.termsRemovedBy.name + ')'
        );
      } else if (
        terms.termsUpdatedBy &&
        terms.termsUpdatedAt &&
        terms.termsUpdatedAt !== terms.termsCreatedAt
      ) {
        history.push(
          convertDateFormat(terms.termsUpdatedAt) + ' 업데이트(' + terms.termsUpdatedBy.name + ')'
        );
      }
    } else if (terms.updatedBy && terms.updatedAt && terms.updatedAt !== terms.createdAt) {
      history.push(convertDateFormat(terms.updatedAt) + ' 업데이트(' + terms.updatedBy.name + ')');
    }
    return history;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handelDeleteTerms = async () => {
    if (!service || !terms) {
      return;
    }
    try {
      await deleteTerms(service.id, terms.id);
      navigate(-1);
    } catch (error) {
      console.error('Error deleting terms', error);
    }
  };

  return (
    <>
      <CMSDetailLayout
        title={terms?.name ?? ''}
        actions={
          isHistoryDetail ? null : (
            <>
              <CommonButton
                sizeinfo={SizeEnum.XS}
                colorinfo={ColorEnum.BORDERGRAY}
                content='편집'
                onClick={() => {
                  if (authEdit) {
                    openAuthDialog('약관 편집', authEdit);
                    return;
                  }
                  setIsPopupOpen(true);
                }}
              />
              <CommonButton
                sizeinfo={SizeEnum.XS}
                colorinfo={ColorEnum.BORDERGRAY}
                content='삭제'
                onClick={() => {
                  if (!terms) {
                    return;
                  }
                  if (authDelete) {
                    openAuthDialog('약관 삭제', authDelete);
                    return;
                  }
                  openDialog({
                    title: '약관 삭제',
                    content: `${terms.name}을/를 삭제하시겠습니까?`,
                    onConfirm: () => {
                      handelDeleteTerms();
                      closeDialog();
                    },
                  });
                }}
              />
            </>
          )
        }
      />
      <TermsDetailPageStyle>
        <div className='detail-content'>
          상세 내용
          <div className='detail'>
            <div className='text'>{terms?.content ?? ''}</div>
          </div>
        </div>
        <InfoView history={getTermsHistory()}>
          <HeaderListItem header='KEY' children={`ST-${terms?.id ?? ''}`} />
          <HeaderListItem
            header='플랫폼'
            labels={
              <>
                {terms?.platforms.includes(PlatformEnum.ANDROID) && (
                  <CommonLabel
                    sizeinfo={SizeEnum.S}
                    colorinfo={ColorEnum.GREEN}
                    children={`${PlatformDisplayName(PlatformEnum.ANDROID)}`}
                  />
                )}
                {terms?.platforms.includes(PlatformEnum.IOS) && (
                  <CommonLabel
                    sizeinfo={SizeEnum.S}
                    colorinfo={ColorEnum.GRAY}
                    children={`${PlatformDisplayName(PlatformEnum.IOS)}`}
                  />
                )}
                {terms?.platforms.includes(PlatformEnum.WEB) && (
                  <CommonLabel
                    sizeinfo={SizeEnum.S}
                    colorinfo={ColorEnum.YELLOW}
                    children={`${PlatformDisplayName(PlatformEnum.WEB)}`}
                  />
                )}
              </>
            }
          />
          <HeaderListItem header='약관 버전' className='version' children={terms?.version ?? '-'} />
          <HeaderListItem
            header='개정 및 시행일'
            children={
              terms?.applyDatetime ? convertDateFormat(terms?.applyDatetime, 'yyyy-M-d(E)') : ''
            }
          />
          <HeaderListItem
            header='약관 동의 상태'
            labels={
              <CommonLabel
                sizeinfo={SizeEnum.S}
                colorinfo={(terms?.requiredFlag ?? false) ? ColorEnum.ORANGE : ColorEnum.BLUE}
                children={(terms?.requiredFlag ?? false) ? '필수 동의' : '선택 동의'}
              />
            }
          />
          <HeaderListItem
            image={Images.icon_profile_default}
            header='멤버'
            children={isHistoryDetail ? terms?.termsCreatedBy.name : terms?.createdBy.name}
          />
        </InfoView>
      </TermsDetailPageStyle>
      <TermsPopup
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        data={terms}
        onConfirm={(terms) => {
          setTerms(terms);
        }}
      />
    </>
  );
};

export default TermsDetailPage;
