import styled, { keyframes } from 'styled-components';
import colors from '../../common/colors';
import { PopupButton } from '../CommonDialogPopupStyle';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface ContainerProps {
  $isPopupOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background: ${colors.bl_opacity_40};
  width: 100%;
  height: 100%;

  animation: ${(props) => (props.$isPopupOpen ? fadeIn : fadeOut)} 0.3s ease-out;
`;

export const PopupLayoutContainer = styled.div`
  display: flex;
  width: 800px;
  height: 800px;
  overflow-y: auto;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`;

export const PopupBodyContainer = styled.div`
  display: flex;
  padding: 8px 24px;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
  align-self: stretch;
  background: ${colors.background_light_100};
`;

export const PopupButtonContainer = styled(PopupButton)`
  border-top: 1px solid ${colors.line_black_opacity_10};
  background-color: ${colors.background_light_95};
`;
