import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
import colors from '../../common/colors';

export const MenuStyle = styled(Menu)`
  &&& {
    display: flex;
    padding: 0px 4px 0px 0px;
    z-index: 1000;
    position: fixed;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    height: 48px !important;
    background-color: ${colors.background_light_100};
    border: none;
    border-bottom: 1px solid ${colors.line_black_opacity_10};
    border-radius: 0px !important;
    box-sizing: initial;
    box-shadow: none;
    margin-bottom: 0px;
    width: 100% !important;
    min-width: 1000px;
    .item {
      background: none !important;
    }
    .item:before {
      display: none;
    }
    > .ui.image {
      height: 24px;
      margin-right: 35px;
    }
  }
`;

export const MenuIconStyle = styled(Menu.Item)`
  &&& {
    border: none;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    color: ${(props) => (props.icon_color ? props.icon_color : colors.icon_default)};
    padding: ${(props) => (props.custom_padding ? props.custom_padding : '0px')};
    font-family: 'Font Awesome 6 Free';
    text-align: center;
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    cursor: pointer;
    .icon {
      width: ${(props) => (props.custom_size ? props.custom_size : '14px')} !important;
      font-size: ${(props) => (props.custom_size ? props.custom_size : '14px')};
    }
  }
`;

export const RightContentStyle = styled.div`
  position: relative;
  .lnb {
    right: 216px;
    top: 45px;
    position: fixed;
  }
  > .ui.image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`;
