import React from 'react';
import * as ROUTES from '../../../routes/Routes';
import { SidebarContainer } from './IntroSideNavBarStyle';
import { ClickableImage } from '../../EtcStyle';
import { Images } from '../../../assets/images/images';
import { useNavigate } from 'react-router-dom';
import { CommonListItem } from '../../list/CommonListItem';
import { CommonButton } from '../../button/CommonButton';
import { ColorEnum, SizeEnum } from '../../common/enums';
import { useAuth } from '../../../contexts/AuthContext';

interface SideNavBarProps {
  isopen: boolean;
  setSnbOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MenuItem {
  label: string;
  route: string;
}

const IntroSideNavBar: React.FC<SideNavBarProps> = ({ isopen, setSnbOpen }) => {
  const navigate = useNavigate();
  const { myInfo } = useAuth();

  const menuItems: MenuItem[] = [
    {
      label: '서비스 소개',
      route: ROUTES.HOME,
    },
    {
      label: '서비스 문의',
      route: ROUTES.HOME,
    },
  ];

  return (
    <SidebarContainer $isopen={isopen}>
      <div className='header'>
        <ClickableImage src={Images.intro_logo_black} onClick={() => navigate(ROUTES.HOME)} />
        <i className='icon fa-solid fa-xmark' onClick={() => setSnbOpen(false)} />
      </div>
      <div className='body'>
        <div className='items'>
          {menuItems.map((item, index) => (
            <CommonListItem key={index} content={item.label} onClick={() => navigate(item.route)} />
          ))}
        </div>

        {myInfo ? (
          <div className='action'>
            <CommonButton
              sizeinfo={SizeEnum.M}
              colorinfo={ColorEnum.BORDERPRIMARY}
              content='계정 관리'
              onClick={() => {
                setSnbOpen(false);
                navigate(ROUTES.MY_INFO);
              }}
            />

            <CommonButton
              sizeinfo={SizeEnum.M}
              colorinfo={ColorEnum.PRIMARY}
              content='나의 백오피스'
              onClick={() => {
                setSnbOpen(false);
                navigate(ROUTES.MY_BACK_OFFICE);
              }}
            />
          </div>
        ) : (
          <div className='action'>
            <CommonButton
              sizeinfo={SizeEnum.M}
              colorinfo={ColorEnum.BORDERGRAY}
              content='계정 생성'
              onClick={() => {
                setSnbOpen(false);
                navigate(ROUTES.JOIN);
              }}
            />

            <CommonButton
              sizeinfo={SizeEnum.M}
              colorinfo={ColorEnum.BORDERGRAY}
              content='로그인'
              onClick={() => {
                setSnbOpen(false);
                navigate(ROUTES.LOGIN);
              }}
            />
          </div>
        )}
      </div>
    </SidebarContainer>
  );
};

export default IntroSideNavBar;
