//intro routes
export const HOME = '/';
export const LOGIN = '/login';
export const JOIN = '/join';
export const VERIFY_JOIN = '/verifyJoin';
export const MY_BACK_OFFICE = '/my-back-office';
export const MY_INFO = '/my-info';
export const MY_PASSWORD = '/my-password';
export const VERIFY_INVITATION = '/verifyInvitation';

export const JOIN_EMAIL = (email: string): string => `email?email=${email}`;

//cms routes
export const CMS = (serviceId: number, path: string | null = null): string =>
  `/cms/${serviceId}/${path ? `${path}` : ''}`;
export const DASHBOARD = '';
export const SERVICE = 'service';
export const PLATFORM = 'platform';
export const MEMBERS = 'members';
export const TERMS = 'terms';
export const TERMS_HISTORY = 'terms-history';
export const PROFILE = 'profile';
export const USER = 'user';
export const WITHDRAW_USER = 'withdraw-user';
export const WITHDRAW_REASON = 'withdraw-reason';

//cms detail routes
export const PLATFORM_DETAIL = (versionId: number, title: string): string =>
  `version/${versionId}?title=${encodeURIComponent(title)}`;

export const TERMS_DETAIL = (termsId: number): string => `${termsId}`;

export const MEMBER_DETAIL = (adminId: number): string => `${adminId}`;

export const USER_DETAIL = (userId: number): string => `${userId}`;
