import styled from 'styled-components';
import colors from '../common/colors';
import { font } from '../common/font';
import { TextArea } from 'semantic-ui-react';

export const CommonTextAreaStyle = styled(TextArea)`
  &&& {
    height: 120px !important;
    outline: none !important;
    border-radius: 4px !important;
    border: 1px solid ${(props) => props.border_color} !important;
    background: ${(props) =>
      props.disabled ? colors.background_light_95 : colors.background_light_100} !important;
    padding: 12px !important;
    color: ${(props) => (props.disabled ? colors.text_disabled : colors.text_active)} !important;
    ${font.body_m_m_regular(true)};
    placeholder {
      color: ${(props) => props.placeholder_color} !important;
    }
  }
`;
