import React from 'react';
import { CommonInput } from '../components/input/CommonInput';
import { CommonTextArea } from '../components/textArea/CommonTextArea';
import { CommonDropDown } from '../components/dropDown/CommonDropDown';
import { CommonButton } from '../components/button/CommonButton';
import { LabelInput } from '../components/input/LabelInput';
import { CommonLabel } from '../components/label/CommonLabel';
import { StatusEnum, AlertTypeEnum, SizeEnum, ColorEnum } from '../components/common/enums';
import { CommonCheckboxStyle } from '../components/EtcStyle';
import { CommonListItem } from '../components/list/CommonListItem';
import { HeaderListItem } from '../components/list/HeaderListItem';
import { ListStyle } from '../components/list/CommonListStyle';
import { SearchInput } from '../components/input/SearchInput';
import { TitleWithComponent } from '../components/form/TitleWithComponent';
import { Images } from '../assets/images/images';
import { useAlert } from '../contexts/AlertContext';
import colors from '../components/common/colors';

const countryOptions = [
  { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
  { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
  { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
];
const Test: React.FC = () => {
  const { triggerAlert } = useAlert();

  const handleLeftIconClick = () => {
    triggerAlert({ msg: 'SUCCESS 팝업', type: AlertTypeEnum.SUCCESS });
  };

  const handleRightIconClick = () => {
    triggerAlert({
      msg: '추가를 완료하지 못했습니다.\n잠시 후 다시 시도해 주세요.',
      type: AlertTypeEnum.ERROR,
    });
  };

  return (
    <div style={{ padding: '20px' }}>
      <TitleWithComponent
        title='제목'
        errorStr='이메일 형식이 올바르지 않습니다.'
        hintStr='*가이드 텍스트'
      >
        <CommonInput placeholder='일반 인풋' />
      </TitleWithComponent>
      <p></p>
      <TitleWithComponent
        title='제목'
        isImport
        errorStr='이메일 형식이 올바르지 않습니다.'
        hintStr='*가이드 텍스트'
        status={StatusEnum.ERROR}
      >
        <CommonInput status={StatusEnum.ERROR} placeholder='일반 인풋' />
      </TitleWithComponent>
      <p></p>
      <SearchInput placeholder='검색어를 입력하세요.' />
      <p></p>
      <ListStyle>
        <HeaderListItem
          key={1}
          headericon='fa-regular fa-calendar'
          image={Images.icon_profile_default}
          header='헤더 리스트'
          children={<a href='https://react.semantic-ui.com/elements/list/#types-icon'>semantic</a>}
        />
        <p></p>
        <HeaderListItem
          key={2}
          headericon='fa-regular fa-calendar'
          header='헤더 리스트'
          children={<a href='https://react.semantic-ui.com/elements/list/#types-icon'>semantic</a>}
        />
        <p></p>
        <HeaderListItem
          key={3}
          header='Label 헤더 리스트'
          labels={[
            <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.GRAY} children='gray 라벨' />,
            <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.RED} children='red 라벨' />,
            <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.PINK} children='pink 라벨' />,
          ]}
        />
        <HeaderListItem
          key={4}
          header='Label 헤더 리스트'
          labels={[
            <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.GRAY} children='gray 라벨' />,
          ]}
        />
        <p></p>
        <HeaderListItem
          key={5}
          header='Label 헤더 리스트'
          children={<a href='https://react.semantic-ui.com/elements/list/#types-icon'>semantic</a>}
          buttons={[
            <CommonButton sizeinfo={SizeEnum.XS} colorinfo={ColorEnum.GRAY} content='XS 버튼' />,
          ]}
        />
        <p></p>
      </ListStyle>
      <CommonListItem
        content={<a href='https://react.semantic-ui.com/elements/list/#types-icon'>semantic</a>}
      />
      <p></p>
      <CommonListItem
        icon='fa-regular fa-calendar'
        content={<a href='https://react.semantic-ui.com/elements/list/#types-icon'>semantic</a>}
      />
      <p></p>
      <CommonListItem content='디폴트 리스트' />
      <p></p>
      <CommonListItem icon='fa-regular fa-calendar' content='아이콘 리스트' />
      <p></p>
      <CommonCheckboxStyle label='Make my profile visible' />
      <p></p>
      <CommonCheckboxStyle label='Make my profile visible' radio />
      <p></p>
      <CommonCheckboxStyle label='Make my profile visible' toggle color={colors.brand_primary} />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.GRAY} children='S 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.M} colorinfo={ColorEnum.RED} children='M 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.L} colorinfo={ColorEnum.PINK} children='L 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.YELLOW} children='S 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.M} colorinfo={ColorEnum.ORANGE} children='M 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.L} colorinfo={ColorEnum.GREEN} children='L 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.TEAL} children='S 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.M} colorinfo={ColorEnum.BLUE} children='M 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.L} colorinfo={ColorEnum.PURPLE} children='L 라벨' />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.GRAY} children='S 라벨' deleteicon />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.M} colorinfo={ColorEnum.RED} children='M 라벨' deleteicon />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.L} colorinfo={ColorEnum.PINK} children='L 라벨' deleteicon />
      <p></p>
      <CommonLabel
        sizeinfo={SizeEnum.S}
        colorinfo={ColorEnum.YELLOW}
        children='S 라벨'
        deleteicon
      />
      <p></p>
      <CommonLabel
        sizeinfo={SizeEnum.M}
        colorinfo={ColorEnum.ORANGE}
        children='M 라벨'
        deleteicon
      />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.L} colorinfo={ColorEnum.GREEN} children='L 라벨' deleteicon />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.S} colorinfo={ColorEnum.TEAL} children='S 라벨' deleteicon />
      <p></p>
      <CommonLabel sizeinfo={SizeEnum.M} colorinfo={ColorEnum.BLUE} children='M 라벨' deleteicon />
      <p></p>
      <CommonLabel
        sizeinfo={SizeEnum.L}
        colorinfo={ColorEnum.PURPLE}
        children='L 라벨'
        deleteicon
        lefticon='fa-regular fa-calendar'
      />
      <p></p>
      <CommonLabel
        sizeinfo={SizeEnum.S}
        colorinfo={ColorEnum.GRAY}
        children='S 라벨'
        righticon='fa-regular fa-calendar'
        lefticon='fa-regular fa-calendar'
        onLeftIconClick={handleLeftIconClick}
        onRightIconClick={handleRightIconClick}
      />
      <p></p>

      <CommonButton sizeinfo={SizeEnum.XS} colorinfo={ColorEnum.DEFAULT} content='XS 버튼' />
      <p></p>
      <CommonButton
        sizeinfo={SizeEnum.XS}
        colorinfo={ColorEnum.DEFAULT}
        content='XS 버튼'
        icon='fa-regular fa-calendar'
        onClick={handleLeftIconClick}
      />
      <p></p>
      <CommonButton sizeinfo={SizeEnum.S} colorinfo={ColorEnum.PRIMARY} content='S 버튼' />
      <p></p>
      <CommonButton sizeinfo={SizeEnum.M} colorinfo={ColorEnum.GRAY} content='M 버튼' />
      <p></p>
      <CommonButton sizeinfo={SizeEnum.L} colorinfo={ColorEnum.BORDERGRAY} content='L 버튼' />
      <p></p>
      <CommonButton sizeinfo={SizeEnum.XL} colorinfo={ColorEnum.BORDERPRIMARY} content='XL 버튼' />
      <p></p>
      <CommonDropDown
        options={countryOptions}
        placeholder='이이Select CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect Country'
      />
      <p></p>
      <CommonDropDown
        limit={10}
        options={countryOptions}
        placeholder='이이Select CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect CountrySelect Country'
      />
      <p></p>
      <CommonTextArea placeholder='일반 텍스트 에어리어' />
      <p></p>
      <CommonTextArea placeholder='에러 텍스트 에어리어' status={StatusEnum.ERROR} />
      <p></p>
      <CommonTextArea placeholder='SUCCESS 텍스트 에어리어' status={StatusEnum.SUCCESS} />
      <p></p>
      <CommonTextArea
        placeholder='DISABLED 텍스트 에어리어'
        disabled
        status={StatusEnum.DISABLED}
      />
      <p></p>
      <CommonTextArea defaultValue='SUCCESS 텍스트 에어리어' status={StatusEnum.SUCCESS} />
      <p></p>
      <CommonInput
        placeholder='일반 인풋'
        leftIcon='fa-regular fa-calendar'
        onLeftIconClick={handleLeftIconClick}
        style={{ width: '500px' }}
      />
      <p></p>
      <CommonInput
        leftIcon='fa-regular fa-calendar'
        placeholder='에러 인풋'
        status={StatusEnum.ERROR}
      />
      <p></p>
      <CommonInput placeholder='에러 인풋' status={StatusEnum.ERROR} />
      <p></p>
      <CommonInput
        leftIcon='fa-regular fa-calendar'
        defaultValue='에러 인풋'
        status={StatusEnum.ERROR}
      />
      <p></p>
      <CommonInput
        leftIcon='fa-regular fa-calendar'
        defaultValue='SUCCESS 인풋'
        status={StatusEnum.SUCCESS}
      />
      <p></p>
      <CommonInput defaultValue='DISABLED 인풋' disabled status={StatusEnum.DISABLED} />
      <p></p>
      <CommonInput defaultValue='SUCCESS 인풋' status={StatusEnum.SUCCESS} />
      <p></p>
      <p></p>
      <LabelInput leftLabel='라벨 인풋' />
      <p></p>
      <LabelInput
        placeholder='일반 인풋'
        leftIcon='fa-regular fa-calendar'
        onLeftIconClick={handleLeftIconClick}
      />
      <p></p>
      <LabelInput
        leftLabel='라벨'
        rightLabel='라라벨'
        leftIcon='fa-regular fa-calendar'
        placeholder='에러 인풋'
        status={StatusEnum.ERROR}
      />
      <p></p>
      <LabelInput
        leftLabel='라벨'
        leftIcon='fa-regular fa-calendar'
        defaultValue='에러 인풋'
        status={StatusEnum.ERROR}
      />
      <p></p>
      <LabelInput
        rightLabel='라라벨'
        leftIcon='fa-regular fa-calendar'
        defaultValue='SUCCESS 인풋'
        status={StatusEnum.SUCCESS}
      />
      <p></p>
      <LabelInput
        leftLabel='라벨'
        rightLabel='라라벨'
        defaultValue='DISABLED 인풋'
        disabled
        status={StatusEnum.DISABLED}
      />
      <p></p>
      <LabelInput rightLabel='라라벨' defaultValue='SUCCESS 인풋' status={StatusEnum.SUCCESS} />
    </div>
  );
};

export default Test;
