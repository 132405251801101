import styled from 'styled-components';
import { font } from '../common/font';
import colors from '../common/colors';
import { Dropdown, Label } from 'semantic-ui-react';
import { CommonCheckboxStyle } from '../EtcStyle';
import { ellipsisStyle } from '../common/text';

interface FilterContainerProps {
  $active: boolean;
}

export const FilterContainer = styled.div<FilterContainerProps>`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  height: 32px;
  max-width: 270px;
  padding: 0px 8px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: ${(props) => (props.$active ? colors.brand_light_03 : colors.background_light_95)};

  color: ${(props) => (props.$active ? colors.brand_dark_03 : colors.text_active)};

  ${font.body_s_s_medium(true)};

  &:hover {
    background: ${(props) => (props.$active ? colors.brand_light_03 : colors.background_light_90)};
  }

  .icon {
    margin: 0px 0px 0px 8px !important;
    text-align: center;
    width: 16px;
    height: 16px;
  }

  .full {
    height: 258px;
  }
`;

export const ScrollableDiv = styled.div`
    overflow-y: auto;
    box-sizing: border-box;
    height: 219px;

    .centered-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        ${font.body_s_s_regular(true)};
        color: ${colors.text_disabled} !important;
      }
}`;

export const FilterDropDownStyle = styled(Dropdown)`
  &&& {
    ${ellipsisStyle};
    text-align: center;
    display: inline-flex;
    flex: 1 0 0 !important;
    position: unset !important;
    align-items: center !important;
    height: 32px !important;
    padding: 0px !important;
    background: none !important;
    border: none !important;
    ${font.body_s_s_medium(true)};

    & > .text {
      ${ellipsisStyle};
      text-align: center;
      align-items: center;
      padding: 0px !important;
      margin: 0px !important;
      color: ${colors.brand_dark_03} !important;
      ${font.body_s_s_medium(true)};
    }

    .dropdown.icon {
      display: none !important;
    }

    .label {
      ${ellipsisStyle};
      display: inline-flex;
      align-items: center;
      box-shadow: none !important;
      height: 100% !important;
      border: none;
      border-radius: 0px;
      background: none;
      color: ${colors.brand_dark_03};
      ${font.body_s_s_medium()};
      margin: 0px;
      padding: 0px;

      & > .delete.icon {
        display: none !important;
      }

      &:hover {
        background: none !important;
      }
    }

    &.ui.selection.dropdown {
      box-shadow: none !important;
    }

    .fit-drop {
      height: auto !important;
      input {
        margin: 10px !important;
        width: 280px !important;
      }
    }

    .menu {
      width: 300px;
      height: 300px;
      max-height: 300px;
      max-width: 300px;
      min-width: 300px;
      margin-top: 8px !important;
      position: absolute !important;
      box-sizing: border-box !important;
      border: 1px solid ${colors.brand_dark_04} !important;
      border-radius: 4px !important;
      background: ${colors.background_light_100} !important;

      > .input {
        margin: 0px !important;
        padding: 0px 12px !important;
        height: 40px !important;
        box-sizing: border-box !important;
        border-bottom: 1px solid ${colors.bl_opacity_10} !important;
        background: none !important;
        color: ${colors.text_active};
        ${font.body_s_s_regular()};

        > input {
          ${font.body_s_s_regular()};
          color: ${colors.text_active};
          border: none !important;
          padding: 0px 28px 0px 0px !important;
        }

        ::placeholder {
          color: ${colors.text_focus} !important;
          opacity: 1;
        }

        i.icon {
          font-family: 'Font Awesome 6 Free';
          width: 16px !important;
          height: 100% !important;
          margin: 0px 12px !important;
          padding: 0px !important;
        }
      }

      .bottom {
        box-sizing: border-box;
        display: flex;
        height: 40px;
        padding: 0px 12px;
        justify-content: flex-end;
        align-items: center;
        align-self: stretch;
        border-top: 1px solid ${colors.bl_opacity_10} !important;
      }

      .react-datepicker,
      .react-datepicker__month-container {
        width: 100% !important;
        border: none !important;
      }
      .react-datepicker__day,
      .react-datepicker__day-name {
        width: 2.5rem;
        height: 2rem;
      }
    }
  }
`;

export const FilterLabel = styled(Label)`
  &&& {
    display: inline-flex;
    align-items: center;
    box-shadow: none !important;
    height: 20px !important;
    border: none;
    border-radius: 4px;
    background: ${colors.brand_dark_03};
    color: ${colors.text_white};
    ${font.label_label_xs_strong()};
    margin: 0px 0px 0px 4px;
    padding: 0px 4px;

    & > .delete.icon {
      display: none !important;
    }

    &:hover {
      background: none !important;
    }
  }
`;

export const FilterCheckbox = styled(CommonCheckboxStyle)`
  &&& {
    height: 36px !important;
    text-align: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid ${colors.bl_opacity_10} !important;
    ${font.body_s_s_regular()};
    color: ${colors.text_active};

    label {
      ${ellipsisStyle};
    }

    &:hover {
      background: ${colors.bl_opacity_5} !important;
    }
  }
`;
