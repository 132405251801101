import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LoginStyle } from './LoginStyle';
import { ClickableImage } from '../../../components/EtcStyle';
import { Images } from '../../../assets/images/images';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { CommonInput } from '../../../components/input/CommonInput';
import { CommonButton } from '../../../components/button/CommonButton';
import { ColorEnum, SizeEnum, StatusEnum } from '../../../components/common/enums';
import { InputStatuses, useInputStatusManager } from '../../../hooks/useInputStatusManager';
import { encryptPassword, isValidEmail, maskEmail } from '../../../components/common/utils';
import { postMemberLogin } from '../../../api/NoitApi';
import { AxiosError } from 'axios';
import { NoitError } from '../../../api/models/CommonModels';
import { useDialog } from '../../../contexts/DialogContext';
import { useAuth } from '../../../contexts/AuthContext';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [subError, setSubError] = useState<string | null>(null);
  const { openDialog, closeDialog } = useDialog();
  const from = location.state?.from?.pathname || ROUTES.HOME;
  const { myInfo } = useAuth();
  const [isShowJoinDialog, setIsShowJoinDialog] = useState<boolean>(false);

  useEffect(() => {
    if (myInfo) {
      navigate(from, { replace: true });
    }
  }, [myInfo, navigate, from]);

  const showJoinDialog = useCallback(() => {
    setIsShowJoinDialog(true);
    openDialog({
      title: 'NOIT 계정 생성',
      content: 'NOIT 계정 생성이 완료되었습니다\n로그인 후 이용해 보세요.',
      isConfirmOnly: true,
      onConfirm: () => {
        closeDialog();
        navigate(location.pathname, { replace: true, state: {} });
        setIsShowJoinDialog(false);
      },
    });
  }, [openDialog, closeDialog, navigate, location.pathname]);

  useEffect(() => {
    if (!isShowJoinDialog && location.state && location.state?.signUpSuccess) {
      showJoinDialog();
    }
  }, [location.state, showJoinDialog, isShowJoinDialog]);

  const initioalData = useMemo((): InputStatuses => {
    return {
      이메일: {
        required: true,
        value: '',
        customValidation: (value: string) => {
          if (!value) return '이메일을(를) 입력해 주세요.';
          if (!isValidEmail(value)) return '이메일 형식이 올바르지 않습니다.';
          return null;
        },
      },
      Password: { required: true, value: '' },
    };
  }, []);

  const { inputStatuses, updateValue, setStatus, checkAllValid } =
    useInputStatusManager(initioalData);

  const errorStr = () => {
    if (
      (inputStatuses['이메일'].status === StatusEnum.ERROR ||
        inputStatuses['Password'].status === StatusEnum.ERROR) &&
      ((inputStatuses['이메일'].value as string).trim().length === 0 ||
        (inputStatuses['Password'].value as string).trim().length === 0)
    ) {
      return '이메일 및 패스워드를 입력해 주세요.';
    }

    if (inputStatuses['이메일'].status === StatusEnum.ERROR) {
      return inputStatuses['이메일'].errorMessage;
    }

    return error;
  };

  const validate = () => {
    setError(null);
    setSubError(null);

    if (!checkAllValid()) {
      return;
    }

    postMemberLogin(
      inputStatuses['이메일'].value as string,
      encryptPassword(inputStatuses['Password'].value as string)
    )
      .then(() => {
        navigate(from, { replace: true });
      })
      .catch((error: AxiosError<NoitError>) => {
        switch (error.response?.data.code) {
          case 'UNJOINED_ADMIN_ACCOUNT':
            setError(
              `${maskEmail(inputStatuses['이메일'].value as string)}으로\n가입된 계정이 없습니다.`
            );
            updateValue('이메일', '');
            updateValue('Password', '');
            break;
          case 'UNKNOWN_ACCOUNT':
            setError('이메일 또는 비밀번호가 일치하지 않습니다.');
            setSubError('확인 후 다시 시도해 주세요.');
            updateValue('이메일', '');
            updateValue('Password', '');
            break;
          default:
            setError('로그인에 실패했습니다.');
            setSubError('잠시 후 다시 시도해 주세요.');
            break;
        }
      });
  };

  return (
    <LoginStyle>
      <ClickableImage src={Images.intro_logo_black} onClick={() => navigate(ROUTES.HOME)} />
      <div className='content'>
        <div className='title'>
          이메일로 로그인해 보세요
          <div className='sub'>가입한 이메일 주소 또는 초대받은 이메일 주소를 입력해 주세요.</div>
        </div>
        {errorStr() && (
          <div className='error'>
            {errorStr()}
            {subError && <div className='sub'>{subError}</div>}
          </div>
        )}
        <div className='box'>
          <CommonInput
            name='이메일'
            value={inputStatuses['이메일'].value}
            status={inputStatuses['이메일'].status}
            setStatus={setStatus}
            placeholder='name@email.com'
            onDataChange={(value) => {
              updateValue('이메일', value as string);
            }}
          />

          <CommonInput
            name='Password'
            value={inputStatuses['Password'].value}
            status={inputStatuses['Password'].status}
            setStatus={setStatus}
            placeholder='Password'
            type='password'
            onDataChange={(value) => {
              updateValue('Password', value as string);
            }}
          />

          <CommonButton
            sizeinfo={SizeEnum.M}
            colorinfo={ColorEnum.PRIMARY}
            content='로그인 하기'
            onClick={validate}
          />
        </div>

        <div className='join' onClick={() => navigate(ROUTES.JOIN)}>
          NOIT을 처음 사용하시나요?
          <span className='create'>계정 생성</span>
        </div>
      </div>
    </LoginStyle>
  );
};

export default Login;
