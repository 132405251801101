import React from 'react';
import { CommonInput, CommonInputProps } from './CommonInput';
import { LabelInputContainer, LabelStyle } from './LabelInputStyle';

export interface LabelInputProps extends CommonInputProps {
  leftLabel?: string;
  rightLabel?: string;
}

export const LabelInput: React.FC<LabelInputProps> = ({ leftLabel, rightLabel, ...props }) => {
  return (
    <LabelInputContainer>
      {leftLabel && (
        <LabelStyle basic position='left'>
          {leftLabel}
        </LabelStyle>
      )}
      <CommonInput
        leadingRadius={leftLabel ? 0 : 4}
        trailingRadius={rightLabel ? 0 : 4}
        {...props}
      ></CommonInput>
      {rightLabel && (
        <LabelStyle basic position='right'>
          {rightLabel}
        </LabelStyle>
      )}
    </LabelInputContainer>
  );
};
