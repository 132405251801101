import styled from 'styled-components';
import { BoxTableCell } from '../../../components/table/TableStyle';
import colors from '../../../components/common/colors';
import { font } from '../../../components/common/font';
import { ellipsisStyle } from '../../../components/common/text';

export const MembersListCell = styled(BoxTableCell)`
  padding: 12px 24px;

  .role {
    width: 160px;
    align-items: center;
    ${ellipsisStyle};
    ${font.body_s_s_regular()};
    color: ${colors.text_active_light};
  }

  .profile {
    display: flex;
    width: 320px;
    > .ui.image {
      border-radius: 50%;
    }
  }

  .note {
    color: ${colors.text_link};
  }
`;
