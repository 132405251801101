import styled from 'styled-components';
import { TableHeaderCell, TableCell } from 'semantic-ui-react';
import colors from '../common/colors';
import { font } from '../common/font';
import { ellipsisStyle, wrapTextStyle } from '../common/text';

export const BoxTableContainer = styled.div`
  display: flex;
  padding: 12px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-height: 108px;
  align-self: stretch;
  border-radius: 8px;
  background: ${colors.background_light_95};

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-top: 8px;
    flex: 1 0 0;
    justify-content: center;
    ${font.body_s_s_regular()};
    color: ${colors.text_disabled};
  }

  .box {
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
    border: 1px solid ${colors.line_black_opacity_10};
    border-top: none;
  }
`;

export const BoxTableHeader = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  align-items: flex-start;

  ${font.body_xs_xs_strong()};
  color: ${colors.text_active};

  .header {
    display: flex;
    height: 28px;
    padding-left: 8px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
  }

  .point {
    ${font.body_xs_xs_regular()};
    color: ${colors.text_primary_dark} !important;
  }

  .count_total {
    ${font.body_xs_xs_regular()};
  }
  .count {
    ${font.body_xs_xs_strong()};
    color: ${colors.text_primary_dark} !important;
  }

  .delete {
    width: auto !important;
  }

  .add {
    margin-left: auto !important;
    width: auto !important;
  }
`;

export const BoxTableCell = styled.div`
  min-width: 400px;
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;

  border-top: 1px solid ${colors.line_black_opacity_10};
  background: ${colors.background_light_100};

  &:hover {
    background: ${colors.background_light_95};
  }

  .note {
    ${ellipsisStyle};
    flex: 1 0 0;
    ${font.body_s_s_regular()};
    color: ${colors.text_active};
  }

  .type {
    display: flex;
    align-items: center;
    gap: 12px;
    overflow: hidden;
    align-self: stretch;

    ${font.body_s_s_strong()};
    color: ${colors.text_active};

    .version {
      ${ellipsisStyle};
      ${font.body_s_s_medium()};
      color: ${colors.text_primary_dark};
    }
  }

  .more {
    overflow: hidden;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    ${font.body_xs_xs_regular()};
    color: ${colors.text_active_light};

    .content {
      ${ellipsisStyle};
      width: 174px;
    }

    .icon {
      cursor: pointer;
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;

      &:hover {
        background: ${colors.bl_opacity_10};
      }
    }
    .lnb {
      position: fixed;
    }
  }
  .profile {
    width: 100px;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    .ui.image {
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .label {
      ${wrapTextStyle};
    }

    .profile-name {
      ${ellipsisStyle};
      ${font.body_s_s_strong()};
      color: ${colors.text_active};
    }
  }
`;

export const CommonTableHeaderCell = styled(TableHeaderCell)`
  & {
    text-align: left;
    padding: 0px 8px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px solid ${colors.line_black_90};
    border-left: 0px;
    background: ${colors.background_light_100};

    ${ellipsisStyle};
    ${font.body_xs_xs_strong()};
    color: ${colors.text_active};
  }
`;

export const CommonTableCell = styled(TableCell)`
  & {
    align-items: center;
    padding: 12px 8px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid ${colors.line_black_90};
    border-left: 0px;

    ${ellipsisStyle};

    ${font.body_s_s_regular()};
    color: ${colors.text_active};
  }
`;
