import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuStyle } from './IntroGlobalNavBarStyle';
import { ClickableImage } from '../../EtcStyle';
import { Images } from '../../../assets/images/images';
import * as ROUTES from '../../../routes/Routes';
import { useAuth } from '../../../contexts/AuthContext';
import { CommonButton } from '../../button/CommonButton';
import { ColorEnum, SizeEnum } from '../../common/enums';
import { LNB } from '../../dropDown/LNB';
import { store } from '../../../app/store';
import { logout } from '../../../features/auth/authSlice';

interface GlobalNavBarProps {
  setSnbOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showGNB: boolean;
}

const IntroGlobalNavBar: React.FC<GlobalNavBarProps> = ({ setSnbOpen, showGNB }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { myInfo } = useAuth();

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <MenuStyle $showGNB={showGNB}>
      <div className='content'>
        <ClickableImage src={Images.intro_logo_black} onClick={() => navigate(ROUTES.HOME)} />
        <div className='left'>
          <span className='menu_item' onClick={() => {}}>
            서비스 소개
          </span>
          <span className='menu_item' onClick={() => {}}>
            서비스 문의
          </span>
        </div>
        {myInfo ? (
          <div className='right'>
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.BORDERPRIMARY}
              content='나의 백오피스'
              onClick={() => navigate(ROUTES.MY_BACK_OFFICE)}
            />
            <div className='lnb-container'>
              <div className='profile' onClick={handleOpen}>
                <ClickableImage
                  src={myInfo?.imageUrl ? myInfo?.imageUrl : Images.icon_profile_default}
                />
                {myInfo?.name}
              </div>
              <LNB
                open={open}
                setOpen={setOpen}
                menuItems={[
                  {
                    label: '계정 관리',
                    onClick: () => {
                      navigate(ROUTES.MY_INFO);
                    },
                  },
                  {
                    label: '로그아웃',
                    onClick: () => {
                      store.dispatch(logout());
                      navigate(ROUTES.HOME);
                    },
                  },
                ]}
              />
            </div>
          </div>
        ) : (
          <div className='right'>
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.DEFAULT}
              content='계정 생성'
              onClick={() => navigate(ROUTES.JOIN)}
            />
            <CommonButton
              sizeinfo={SizeEnum.XS}
              colorinfo={ColorEnum.DEFAULT}
              content='로그인'
              onClick={() => navigate(ROUTES.LOGIN)}
            />
          </div>
        )}
        <i
          className='icon fa-solid fa-bars'
          onClick={() => setSnbOpen((prevState) => !prevState)}
        />
      </div>
    </MenuStyle>
  );
};

export default IntroGlobalNavBar;
