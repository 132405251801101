import styled from 'styled-components';
import colors from '../../common/colors';
import { font } from '../../common/font';

interface SideNavBarContainerProps {
  $isopen: boolean;
}

export const SidebarContainer = styled.div<SideNavBarContainerProps>`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
  z-index: 2000;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${(props) => (!props.$isopen ? '200%' : '0')};
  area-hidden: ${(props) => (!props.$isopen ? 'false' : 'true')};
  width: 100%;
  height: 100%;
  position: fixed;
  transition: margin-left 0.5s ease-in-out;
  overflow: hidden;
  background: ${colors.background_light_100};

  .header {
    display: flex;
    height: 48px;
    padding: 0px 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
    .ui.image {
      width: 66px;
      height: 24px;
    }
    .icon {
      font-size: 20px;
      margin-left: auto;
      cursor: pointer;
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      color: ${colors.icon_default};
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    .items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      .item {
        padding: 12px 16px !important;
        ${font.body_s_s_strong(true)};
        color: ${colors.text_active} !important;
      }
    }

    .action {
      display: flex;
      padding: 16px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      > .button {
        width: 100%;
      }
    }
  }
`;
