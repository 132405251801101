import React, { useRef, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { CommonInput, CommonInputProps } from './CommonInput';
import { StatusEnum } from '../common/enums';
import { extractDate } from '../common/utils';
import { DatePickerContainer, DatePickerStyle } from '../EtcStyle';

interface InputDatePickerProps extends CommonInputProps {
  showDatePicker: boolean;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  onDateChange: (date: Date) => void;
  time?: boolean;
  startDate?: Date;
}

export const InputDatePicker: React.FC<InputDatePickerProps> = ({
  name,
  setStatus,
  status = StatusEnum.DEFAULT,
  onDateChange,
  showDatePicker,
  setShowDatePicker,
  onSelect,
  time = true,
  startDate,
  ...props
}) => {
  registerLocale('ko', ko);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDatePicker]);

  const handleClick = () => {
    if (onSelect) {
      onSelect();
    }

    if (status === StatusEnum.DISABLED) {
      return;
    }

    if (setStatus != null && name != null) {
      setStatus(name, StatusEnum.FOCUS);
    }
    setShowDatePicker(true);
  };
  return (
    <DatePickerContainer ref={ref}>
      <CommonInput
        onLeftIconClick={handleClick}
        lefticon='fa-regular fa-calendar'
        righticon={false}
        name={name}
        setStatus={setStatus}
        status={status}
        onSelect={handleClick}
        value={props.value}
        {...props}
      />
      {showDatePicker && (
        <DatePickerStyle
          selected={props.value ? extractDate(props.value) : undefined}
          startDate={startDate ? startDate : undefined}
          onChange={onDateChange}
          showTimeSelect={time}
          locale='ko'
          dateFormat='yyyy-MM-dd'
          timeFormat={time ? 'aa hh:mm' : undefined}
          timeIntervals={time ? 30 : undefined}
          inline
        />
      )}
    </DatePickerContainer>
  );
};
