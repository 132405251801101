import { createContext, useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, store } from '../app/store';
import { logout } from '../features/auth/authSlice';
import logger from '../components/common/logger';
import { getMember, getRenewToken } from '../api/NoitApi';
import { Member } from '../api/models/MemberModels';

interface AuthContextType {
  myInfo: JwtPayload | null;
  setMyInfo: React.Dispatch<React.SetStateAction<JwtPayload | null>>;
  myAccount: Member | null;
  refreshAccount: () => void;
  refreshAuth: () => void;
  isLogin: boolean;
  isNoitManager: boolean;
}

interface JwtPayload {
  id: number;
  imageUrl: string | null;
  loginId: string;
  managerFlag: boolean;
  name: string;
  serviceAuthorities: Authority[];
}

interface Authority {
  id: number;
  serviceId: number;
  managerFlag: boolean;
  authorities: string[];
}

export function decodeJwt(token: string): JwtPayload | null {
  try {
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      return null;
    }
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload) as JwtPayload;
  } catch (e) {
    logger.error('Error decoding JWT', e);
    return null;
  }
}
/*
accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzcsImxvZ2luSWQiOiJqaW5pQG5vLWl0LmlvIiwibmFtZSI6Ilx1YzljMFx1YjJjOCA6KSIsImltYWdlVXJsIjpudWxsLCJtYW5hZ2VyRmxhZyI6ZmFsc2UsInNlcnZpY2VBdXRob3JpdGllcyI6W3siaWQiOjM0LCJzZXJ2aWNlSWQiOjI2LCJtYW5hZ2VyRmxhZyI6dHJ1ZSwiYXV0aG9yaXRpZXMiOltdfV0sImV4cCI6MTcxMTU2MjEyN30.rH03NliqbC5hlBUcbXBKfoWdnXQ46iZiJdwXR-eqRGI"

refreshToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzAsImV4cCI6MTcxMDgyNTg4NH0.N0FtO8qPS1hbyWjuWxWCguiQVf0B-78ljvTjRwB898I"
*/

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { accessToken, refreshToken } = useSelector((state: RootState) => state.auth);
  const [myInfo, setMyInfo] = useState<JwtPayload | null>(null);
  const [isNoitManager, setIsNoitManager] = useState<boolean>(false);
  const [myAccount, setMyAccount] = useState<Member | null>(null);

  const error = useCallback(() => {
    store.dispatch(logout());
    setMyInfo(null);
  }, []);

  const refreshAccount = useCallback(() => {
    if (!myInfo) return;

    getMember(myInfo.id)
      .then((res) => {
        setMyAccount(res);
      })
      .catch((e) => {
        error();
        logger.error('Error refreshing account', e);
      });
  }, [myInfo, error]);

  useEffect(() => {
    if (myInfo) {
      refreshAccount();
    }
  }, [myInfo, refreshAccount]);

  const refreshAuth = useCallback(() => {
    if (refreshToken) {
      try {
        getRenewToken(refreshToken);
      } catch (e) {
        console.error('Error refreshing auth', e);
        error();
      }
    } else {
      error();
    }
  }, [refreshToken, error]);

  useEffect(() => {
    if (refreshToken && accessToken) {
      const decoded = decodeJwt(accessToken);
      if (!decoded) {
        error();
        return;
      }
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('accessToken', accessToken);
      logger.log('decoded', decoded);
      setIsNoitManager(decoded.managerFlag);
      setMyInfo(decoded);
    } else {
      error();
    }
  }, [refreshToken, accessToken, error]);

  const value = {
    myInfo,
    setMyInfo,
    myAccount,
    refreshAccount,
    refreshAuth,
    isLogin: !!accessToken,
    isNoitManager,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
