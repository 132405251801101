import styled from 'styled-components';
import colors from '../../components/common/colors';

export const MoreMenuContainer = styled.div`
  .menu {
    z-index: 1000;
    width: 120px !important;
    right: 55px;
    margin-top: 10px !important;
  }
`;

export const MoreMenuStyle = styled.i`
  position: relative;
  text-align: center;
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background: ${colors.bl_opacity_10};
  }
`;
