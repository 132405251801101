import React, { useEffect, useState } from 'react';
import { useEvent } from '../../../contexts/AddButtonContext';
import { BoxTableContainer, BoxTableHeader } from '../../../components/table/TableStyle';
import { getTermsList, deleteTermsList, deleteTerms, getExcel } from '../../../api/NoitApi';
import { Terms } from '../../../api/models/ServiceModels';
import { CommonCheckboxStyle } from '../../../components/EtcStyle';
import { CheckboxProps } from 'semantic-ui-react';
import { CommonButton } from '../../../components/button/CommonButton';
import {
  SizeEnum,
  ColorEnum,
  PlatformEnum,
  PlatformDisplayName,
} from '../../../components/common/enums';
import { useDialog } from '../../../contexts/DialogContext';
import { CommonLabel } from '../../../components/label/CommonLabel';
import { TermsListCell } from './TermsListPageStyle';
import { convertDateFormat } from '../../../components/common/utils';
import { MoreMenu } from '../../../components/menu/MoreMenu';
import { TermsPopup } from './TermsPopup';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../routes/Routes';
import { useService } from '../../../contexts/ServiceContext';
import { useAuthorization } from '../../../hooks/useAuthorization';

const TermsListPage: React.FC = () => {
  const { eventTriggered, resetEvent, setButtonText } = useEvent();
  const { openDialog, closeDialog, openAuthDialog } = useDialog();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const authAdd = useAuthorization('TERMS_ADD', true);
  const authDelete = useAuthorization('TERMS_DELETE', true);

  useEffect(() => {
    setButtonText('약관 추가');
    return () => {
      setButtonText(null);
    };
  }, [setButtonText]);

  useEffect(() => {
    if (eventTriggered) {
      resetEvent();
      if (authAdd) {
        openAuthDialog('약관 추가', authAdd);
        return;
      }
      setIsPopupOpen(true);
    }
  }, [eventTriggered, resetEvent, authAdd, openAuthDialog]);

  let { service } = useService();
  const [termsList, setTermsList] = useState<Terms[]>([]);

  useEffect(() => {
    if (!service) {
      return;
    }

    getTermsList(service.id).then((data) => {
      setTermsList(data.items);
    });
  }, [service]);

  const [checkedVersions, setCheckedVersions] = useState<{ [termsId: number]: boolean }>({});

  const handleSelectAll = (checked: boolean) => {
    setCheckedVersions((prev) => ({
      ...prev,
      ...termsList.reduce((acc, cur) => ({ ...acc, [cur.id]: checked }), {}),
    }));
  };

  const handleSelect = (termsId: number, checked: boolean) => {
    setCheckedVersions((prev) => ({
      ...prev,
      [termsId]: checked,
    }));
  };

  const getSelectedVersions = () => {
    return Object.entries(checkedVersions)
      .filter(([, checked]) => checked)
      .map(([termsId]) => Number(termsId));
  };

  const handleDeleteSelectedVersions = () => {
    const selectedVersions = getSelectedVersions();
    if (!service) {
      return;
    }
    deleteTermsList(service.id, selectedVersions).then(() => {
      setTermsList((prev) => prev.filter((terms) => !selectedVersions.includes(terms.id)));

      setCheckedVersions((prev) => {
        const next = { ...prev };
        selectedVersions.forEach((termsId) => {
          delete next[termsId];
        });
        return next;
      });
    });
  };

  const handleDelete = (termsId: number) => {
    if (!service) {
      return;
    }
    deleteTerms(service.id, termsId).then(() => {
      setTermsList((prev) => prev.filter((terms) => terms.id !== termsId));

      setCheckedVersions((prev) => {
        const next = { ...prev };
        delete next[termsId];
        return next;
      });
    });
  };

  const isAllSelected =
    termsList.length > 0 && termsList.every((terms) => checkedVersions[terms.id]);
  const selectedCount = Object.values(checkedVersions).filter((checked) => checked).length;

  const navigate = useNavigate();
  const goToDetailPage = (termsId: number) => {
    navigate(ROUTES.TERMS_DETAIL(termsId));
  };

  return (
    <>
      <BoxTableContainer>
        <BoxTableHeader>
          <div className='header'>
            <CommonCheckboxStyle
              checked={isAllSelected}
              onChange={(_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
                handleSelectAll(data.checked || false)
              }
            />
            약관 수 :<div className='count_total'>{termsList.length}</div>
            {selectedCount > 0 && (
              <>
                <div className='count'>({selectedCount}개 선택됨)</div>
                <CommonButton
                  className='delete'
                  sizeinfo={SizeEnum.XS}
                  colorinfo={ColorEnum.GRAY}
                  content='Excel 다운로드'
                  onClick={() => {
                    if (!service) {
                      return;
                    }
                    getExcel(
                      service.id,
                      'terms',
                      `${service.name.korean}_약관목록`,
                      getSelectedVersions()
                    );
                  }}
                />
                <CommonButton
                  className='delete'
                  sizeinfo={SizeEnum.XS}
                  colorinfo={ColorEnum.GRAY}
                  content='삭제'
                  onClick={() => {
                    if (authDelete) {
                      openAuthDialog('약관 삭제', authDelete);
                      return;
                    }
                    openDialog({
                      title: `약관 삭제`,
                      content: `선택된 ${selectedCount}개의 약관을 삭제하시겠습니까?`,
                      onConfirm: () => {
                        handleDeleteSelectedVersions();
                        closeDialog();
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
        </BoxTableHeader>
        <div className='box'>
          {termsList.length > 0 ? (
            termsList.map((terms) => (
              <TermsListCell key={terms.id} onClick={() => goToDetailPage(terms.id)}>
                <div className='type'>
                  <CommonCheckboxStyle
                    checked={checkedVersions[terms.id] || false}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      event.stopPropagation();
                      handleSelect(terms.id, data.checked || false);
                    }}
                  />
                  <div className='info'>
                    {terms.version && <span className='version'>{terms.version}</span>}
                    <div className='note'>{terms.name}</div>
                  </div>
                </div>

                <div className='more'>
                  <div className='agree'>
                    <CommonLabel
                      sizeinfo={SizeEnum.S}
                      colorinfo={terms.requiredFlag ? ColorEnum.ORANGE : ColorEnum.BLUE}
                      children={terms.requiredFlag ? '필수 동의' : '선택 동의'}
                    />
                  </div>
                  <span className='etc'>
                    {terms.applyDatetime
                      ? `${convertDateFormat(terms.applyDatetime, 'yyyy-MM-dd')} 개정 및 시행`
                      : ''}
                  </span>
                  <div className='platform'>
                    {terms.platforms.includes(PlatformEnum.ANDROID) && (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GREEN}
                        children={`${PlatformDisplayName(PlatformEnum.ANDROID)}`}
                      />
                    )}
                    {terms.platforms.includes(PlatformEnum.IOS) && (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.GRAY}
                        children={`${PlatformDisplayName(PlatformEnum.IOS)}`}
                      />
                    )}
                    {terms.platforms.includes(PlatformEnum.WEB) && (
                      <CommonLabel
                        sizeinfo={SizeEnum.S}
                        colorinfo={ColorEnum.YELLOW}
                        children={`${PlatformDisplayName(PlatformEnum.WEB)}`}
                      />
                    )}
                  </div>
                  <span className='content'>
                    {terms.createdAt === terms.updatedAt
                      ? convertDateFormat(terms.createdAt) + ' 등록'
                      : convertDateFormat(terms.updatedAt!) + ' 업데이트'}
                  </span>
                  <MoreMenu
                    menuItems={[
                      {
                        label: '삭제',
                        onClick: () => {
                          if (authDelete) {
                            openAuthDialog('약관 삭제', authDelete);
                            return;
                          }
                          openDialog({
                            title: `약관 삭제`,
                            content: `${terms.name} 약관을 삭제하시겠습니까?`,
                            onConfirm: () => {
                              handleDelete(terms.id);
                              closeDialog();
                            },
                          });
                        },
                      },
                    ]}
                  />
                </div>
              </TermsListCell>
            ))
          ) : (
            <div className='empty'>약관을 등록해 주세요.</div>
          )}
        </div>
      </BoxTableContainer>
      <TermsPopup
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        onConfirm={(terms) => {
          setTermsList((prev) => [...prev, terms]);
        }}
      />
    </>
  );
};

export default TermsListPage;
