import React, { createContext, useState, useContext } from 'react';

interface AddButtonContextType {
  eventTriggered: boolean;
  triggerEvent: () => void;
  resetEvent: () => void;
  buttonText: string | null;
  setButtonText: (text: string | null) => void;
}

const AddButtonContext = createContext<AddButtonContextType>(null!);

export const useEvent = () => useContext(AddButtonContext);

export const AddButtonProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [eventTriggered, setEventTriggered] = useState(false);
  const [buttonText, setButtonText] = useState<string | null>(null);

  const triggerEvent = () => {
    setEventTriggered(true);
  };

  const resetEvent = () => {
    setEventTriggered(false);
  };

  return (
    <AddButtonContext.Provider
      value={{ eventTriggered, triggerEvent, resetEvent, buttonText, setButtonText }}
    >
      {children}
    </AddButtonContext.Provider>
  );
};
