import React, { useEffect, useRef } from 'react';
import { LNBStyle } from '../dropDown/LNBStyle';
import { Dropdown } from 'semantic-ui-react';

interface LNBProps {
  menuItems?: { label: string; onClick: () => void; active?: boolean }[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

export const LNB: React.FC<LNBProps> = ({ menuItems = [], setOpen, open }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, setOpen]);

  return (
    <div className='lnb' ref={dropdownRef}>
      {open && (
        <LNBStyle>
          {menuItems.map(({ label, onClick, active }) => (
            <Dropdown.Item
              key={label}
              active={active}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                onClick();
                setOpen(false);
              }}
            >
              {label}
            </Dropdown.Item>
          ))}
        </LNBStyle>
      )}
    </div>
  );
};
