import styled from 'styled-components';
import { wrapTextStyle } from '../../../../components/common/text';

export const PlatformDetailStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;

  .contents {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;

    .item {
      .description {
        .content {
          width: 100% !important;
          .text {
            ${wrapTextStyle} !important;
          }
        }
      }
    }
  }
`;
