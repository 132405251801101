import styled from 'styled-components';
import colors from '../../../components/common/colors';
import { font } from '../../../components/common/font';
import { HeaderListItem } from '../../../components/list/HeaderListItem';

export const Container = styled.div`
  display: flex;
  width: 679px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .info {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${colors.bl_opacity_10};
    background: ${colors.background_light_100};
  }

  .headercolor {
    color: ${colors.text_active_light} !important;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ${font.body_s_s_strong(true)};
  color: ${colors.text_active};
`;

export const HeaderStyle = styled(HeaderListItem)`
  &&& {
    .header {
      color: ${colors.text_active_light} !important;
    }
  }
`;
